import axios from "axios";
import api from "@/api";

const products = {
  state: {
    products: [],
    currentProduct: "",
    allergens: "",
  },
  mutations: {
    updateProductStatus(state, payload) {
      const productId = payload.productId;
      const newStatus = payload.newStatus;
      const productIndex = state.products.findIndex(
        (product) => product.id === productId
      );

      if (productIndex !== -1) {
        state.products[productIndex].is_active = newStatus;
      }
    },

    setProducts(state, payload) {
      state.products = payload.data;
    },
    setCurrentProduct(state, payload) {
      state.currentProduct = payload;
    },
    setAllergens(state, payload) {
      state.allergens = payload;
    },
  },
  actions: {
    updateProductStatus({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `${api}/r/${rootState.domainName}/v1/products/${payload.productId}`,
            { is_active: payload.newStatus },
            rootState.businessAuth
          )
          .then(
            (response) => {
              commit("updateProductStatus", payload);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },

    fetchProducts({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.domainName}/v1/products`,
            rootState.businessAuth
          )
          .then(
            (response) => {
              commit("setProducts", response);
              resolve(response);
            },
            (error) => {
              resolve(error.message);
              reject(error);
            }
          );
      });
    },
    fetchProduct({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.domainName}/v1/products/${payload}`,
            rootState.businessAuth
          )
          .then(
            (response) => {
              commit("setCurrentProduct", response.data);
              resolve(response);
            },
            (error) => {
              resolve(error.message);
              reject(error);
            }
          );
      });
    },
    createProduct({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${api}/r/${rootState.domainName}/v1/products`,
            payload,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    updateProduct({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `${api}/r/${rootState.domainName}/v1/products/${payload.productId}`,
            payload.info,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    deleteProduct({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${api}/r/${rootState.domainName}/v1/products/${payload}`,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    fetchAllergen({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.domainName}/v1/allergens`,
            rootState.businessAuth
          )
          .then(
            (response) => {
              commit("setAllergens", response.data);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    putAllergen({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${api}/r/${rootState.domainName}/v1/set-allergen/${payload.productId}`,
            { allergen: payload.allergenId },
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    deleteAllergen({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${api}/r/${rootState.domainName}/v1/set-allergen/${payload.productId}`,

            {
              headers: {
                Authorization: `Bearer ${rootState.businesAccessToken}`,
              },
              data: {
                allergen: payload.allergenId,
              },
            }
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    createOption({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${api}/r/${rootState.domainName}/v1/options`,
            payload,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    updateOption({ rootState }, payload) {
      // este es el que uso para actualizar los items
      return new Promise((resolve) => {
        axios
          .put(
            `${api}/r/${rootState.domainName}/v1/options/${payload.id}`,
            payload.option,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
            }
          );
      });
    },
    deleteOption({ rootState }, payload) {
      return new Promise((resolve) => {
        axios
          .delete(
            `${api}/r/${rootState.domainName}/v1/options/${payload}`,

            {
              headers: {
                Authorization: `Bearer ${rootState.businesAccessToken}`,
              },
            }
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
            }
          );
      });
    },
    createAddition({ rootState }, payload) {
      return new Promise((resolve) => {
        axios
          .post(
            `${api}/r/${rootState.domainName}/v1/additions`,
            payload,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
            }
          );
      });
    },
    updateAddition({ rootState }, payload) {
      return new Promise((resolve) => {
        axios
          .put(
            `${api}/r/${rootState.domainName}/v1/additions/${payload.id}`,
            payload.option,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
            }
          );
      });
    },
    deleteAddition({ rootState }, payload) {
      return new Promise((resolve) => {
        axios
          .delete(
            `${api}/r/${rootState.domainName}/v1/additions/${payload}`,

            {
              headers: {
                Authorization: `Bearer ${rootState.businesAccessToken}`,
              },
            }
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
            }
          );
      });
    },
    deleteElection({ rootState }, payload) {
      return new Promise((resolve) => {
        axios
          .delete(
            `${api}/r/${rootState.domainName}/v1/delete-election/${payload}`,
            {
              headers: {
                Authorization: `Bearer ${rootState.businesAccessToken}`,
              },
            }
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
            }
          );
      });
    },
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
    getCurrentProduct(state) {
      return state.currentProduct;
    },
    getAllergens(state) {
      return state.allergens;
    },
  },
};

export default products;
