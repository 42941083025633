<template>
  <div>
    <div v-for="element in elements" :key="element.id">
      <div class="radio-check" @click="toggleRadioList(element.id)">
        <img
          :src="currentOption.includes(element.id) ? checkIcon : nocheckIcon"
          alt=""
        />
        <p v-text="element.name"></p>
      </div>
    </div>
  </div>
</template>
<script>
import NoCheckIcon from "@/assets/icons/nocheck.svg";
import CheckIcon from "@/assets/icons/check.svg";
export default {
  name: "ListRadioCheck",
  props: {
    elements: {
      type: Array,
      required: true,
      default: () => {
        return [{ text: "opcion 1", id: 0 }];
      },
    },
    action: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      nocheckIcon: NoCheckIcon,
      checkIcon: CheckIcon,
      currentOption: [],
    };
  },
  methods: {
    toggleRadioList(payload) {
      if (this.currentOption.includes(payload)) {
        const index = this.currentOption.indexOf(payload);
        if (index > -1) {
          this.currentOption.splice(index, 1);
        }
      } else {
        this.currentOption.push(payload);
      }
      this.action(payload);
    },
  },
};
</script>
<style scoped>
.radio-check {
  display: flex;
  align-items: flex-start;
  margin-top: 13px;
}
.radio-check img {
  margin-right: 9px;
  height: 18px;
  width: 18px;
  align-self: flex-start;
}
</style>
