<template>
  <div class="product-details-grid">
    <div class="photo item-a">
      <label>Foto</label>
      <button class="container-img" @click="isOpen = true">
        <div v-if="image == null" class="no-image">
          <img src="@/assets/images/upload-photo.png" alt="" />
          <p>Sube una foto de tu producto</p>
        </div>
        <img v-else :src="image" alt="product-image" class="product-img" />
      </button>
    </div>
    <div class="input item-b">
      <label>Nombre del producto</label>
      <input type="text" placeholder="Ej: Hamburguesa Triple" v-model="name" />
    </div>
    <div class="input item-c">
      <label>Descripción</label>
      <textarea
        rows="2"
        placeholder="Escribe aquí una descripción de tu producto"
        v-model="description"
      ></textarea>
    </div>
    <div class="select item-e">
      <label>Inventario</label>
      <nelkir-select :initialState="isActive" :toggle="toggleIsActive" />
    </div>
    <div class="input item-d">
      <label>Precio</label>
      <input type="number" placeholder="$30.000" v-model="price" />
    </div>
    <div class="input item-f">
      <label>Descuento</label>
      <input
        type="number"
        placeholder="$30.000"
        v-model="discountTotal"
        :disabled="!applyDiscount.value"
        @input="setDiscountTotal"
      />
    </div>
    <div class="input item-g">
      <label class="checkbox">
        <nelkir-check-box :checkbox="applyDiscount" :toggle="toggleDiscount"
      /></label>
      <input
        type="number"
        placeholder="30"
        v-model="discountRate"
        :disabled="!applyDiscount.value"
        @input="setDiscountRate"
      />
      <p
        :class="
          discountRate.length > 0
            ? 'percent-symbol'
            : 'percent-symbol transparent'
        "
      >
        %
      </p>
    </div>
    <div class="item-h">
      <p><span>Precio final:</span> &nbsp; &nbsp; ${{ totalPrice }}</p>
    </div>
    <div class="item-i">
      <label>Lugar de preparación</label>
      <nelkir-radio-check
        :elements="preparationPlaces"
        :action="setPreparationPlace"
      />
    </div>
    <div class="item-j">
      <button class="delete">Eliminar producto</button>
      <div class="buttons">
        <button class="secondary-button">Cancelar</button>
        <button
          class="primary-button"
          @click="createProduct"
          :disabled="disableCreateProduct"
        >
          Aceptar
        </button>
      </div>
    </div>
    <teleport to="body">
      <div v-if="isOpen">
        <modal-crop-img @close="isOpen = false" />
      </div>
    </teleport>
  </div>
</template>
<script>
import NelkirCheckBox from "@/components/NelkirCheckBox.vue";
import NelkirSelect from "@/components/tools/SelectInventory.vue";
import NelkirRadioCheck from "@/components/tools/NelkirRadioCheck.vue";
import ModalCropImg from "@/modals/ModalCropImg.vue";
import { mapGetters } from "vuex";
export default {
  name: "NewProductDetails",
  components: {
    NelkirCheckBox,
    NelkirSelect,
    NelkirRadioCheck,
    ModalCropImg,
  },
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
    fetchProducts: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      description: "",
      isActive: null,
      price: "",
      iva: "",
      discountRate: "",
      loading: false,
      applyDiscount: {
        text: "",
        id: 0,
        value: false,
      },
      discountTotal: "",
      imageUrl: null,
      files: null,
      preparationPlaces: [
        { name: "Cocina", id: 1 },
        { name: "Bar", id: 2 },
      ],
      preparetionPlace: null,
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      image: "getCurrentImg",
    }),
    totalPrice() {
      let total;
      if (this.applyDiscount.value) {
        const discountTotal = this.price * (this.discountRate / 100);
        const subTotal = this.price - discountTotal;
        total = subTotal * (1 + this.iva / 100);
      } else {
        total = this.price * (1 + this.iva / 100);
      }
      return Math.round(total);
    },
    disableCreateProduct() {
      return (
        this.name.length == 0 ||
        this.isActive === null ||
        this.price == 0 ||
        this.loading
      );
    },
  },
  methods: {
    setPreparationPlace(payload) {
      this.preparetionPlace = payload;
    },
    toggleDiscount() {
      this.applyDiscount.value = !this.applyDiscount.value;
    },
    setDiscountTotal() {
      this.discountRate = ((this.discountTotal * 100) / this.price).toFixed(1);
    },
    setDiscountRate() {
      this.discountTotal = (this.price * this.discountRate) / 100;
    },
    toggleIsActive(payload) {
      this.isActive = payload;
    },
    getInfo() {
      const productInfo = {
        name: this.name,
        description: this.description,
        is_active: this.isActive,
        category: this.categoryId.id,
        product_type: this.preparetionPlace,
        price: this.price,
        iva: this.iva / 100,
        discount_rate: this.discountRate / 100,
        image: this.image,
      };
      return productInfo;
    },
    createProduct() {
      this.loading = true;
      this.$store
        .dispatch("businessRequest", {
          action: "createProduct",
          data: this.getInfo(),
        })
        .then((response) => {
          this.loading = false;
          if (response.status == 201) {
            this.$store.commit("setPopUpMessage", "Nuevo producto agregado");
            this.update();
          } else {
            this.$store.commit("setPopUpMessage", "Algo salio mal :(");
          }
        });
    },
    update() {
      this.select(2);
      this.fetchProducts();
    },
  },
  created() {
    this.$store.commit("setCurrentImg", null);
  },
  unmounted() {
    this.$store.commit("setCurrentImg", null);
  },
};
</script>
<style scoped>
.product-details-grid {
  padding: 25px 30px;
  display: grid;
  grid-template-columns: 170px 220px 100px 54px;
  grid-template-rows: auto auto auto auto auto auto;
  grid-template-areas:
    "product-img product-name product-name product-name"
    "product-img product-description product-description product-description"
    "inventory price discount percent"
    "total total total total"
    "preparation-place preparation-place preparation-place preparation-place"
    "buttons buttons buttons buttons";
  gap: 20px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 5px;
  margin-top: 16px;
  width: 670px;
}
.item-a {
  grid-area: product-img;
}
.item-b {
  grid-area: product-name;
}
.item-c {
  grid-area: product-description;
}
.item-d {
  grid-area: inventory;
}
.item-e {
  grid-area: price;
}
.item-f {
  grid-area: discount;
}
.item-g {
  grid-area: percent;
}
.item-h {
  grid-area: total;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.item-i {
  grid-area: preparation-place;
}
.item-j {
  grid-area: buttons;
  display: flex;
  justify-content: space-between;
}
.input {
  display: flex;
  flex-direction: column;
}
label {
  font-weight: 700;
  margin-bottom: 8px;
}

.photo {
  display: flex;
  flex-direction: column;
  width: 170px;
}

.input textarea {
  height: 77px;
  resize: none;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 12px;
}
textarea:focus {
  outline: none;
  border: 1px solid #02c874;
}
.product-img {
  width: 100%;
}
.container-img {
  height: 170px;
  border: 1px dashed #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
}
.no-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}
.no-image p {
  width: 130px;
  font-size: 14px;
  margin-top: 30px;
}

.checkbox {
  height: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.select {
  width: 100%;
}
.percent-symbol {
  position: absolute;
  top: 44px;
  right: 15px;
}

.third {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.delete {
  color: #e94a47;
  background-color: transparent;
  border: none;
}
.buttons button {
  width: 132px;
}
.secondary-button {
  background-color: transparent;
  border: none;
}
@media screen and (max-width: 1439px) {
  .product-details-grid {
    padding: 25px 25px 30px 30px;
  }
}
@media screen and (max-width: 1359px) {
  .product-details-grid {
    padding: 25px 20px;
  }
}
@media screen and (max-width: 1023px) {
  .product-details-grid {
    padding: 16px 20px;
  }
  .container-img {
    width: 235px;
    height: 235px;
  }
  .select {
    width: auto;
    margin-bottom: 16px;
  }

  .buttons button {
    width: 117px;
  }
  .delete {
    margin-top: 20px;
  }
}
</style>
