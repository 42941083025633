<template>
  <div class="tables">
    <div class="input">
      <input type="text" placeholder="Buscar mesa" />
      <img src="@/assets/icons/search.svg" alt="search-icon" />
    </div>
    <!-- <div v-if="orders.length == 0" class="message">
          <p>
            Aún no tienes pedidos, revisa la disponibilidad de tu
            <span>inventario</span> e imprime el <span>código QR</span>
          </p>
        </div> -->
    <div v-if="false" class="success-message">
      <img src="@/assets/icons/success.svg" alt="success-icon" />
      <p>Estás al día con tus pedidos</p>
    </div>
    <div class="list-orders">
      <div v-for="order in orders" :key="order.id">
        <order-item
          :order="order"
          :current="currentOrder"
          @click="setCurrentOrder(order)"
        />
      </div>
      <div
        class="new-order"
        data-bs-toggle="modal"
        data-bs-target="#modalNewOrder"
      >
        <img src="@/assets/icons/add.svg" alt="add-icon" />
        <p>Crear pedido</p>
      </div>
    </div>
    <div class="accept-all">
      <!-- <div
            :class="acceptAll ? 'handler active' : 'handler'"
            @click="toggleAcceptAll"
          >
            <div class="dot"></div>
          </div>
          <p>Aceptar pedidos automáticamente</p> -->
      <button
        class="close-box"
        data-bs-toggle="modal"
        data-bs-target="#modalCloseBox"
      >
        Cerrar caja
      </button>
    </div>
  </div>
</template>
<script>
import OrderItem from "./OrderItem.vue";
export default {
  name: "OrderList",
  props: {
    orders: {
      type: Object,
      required: true,
    },
    setCurrentOrder: {
      type: Function,
      required: true,
    },
    currentOrder: {
      type: Object,
      required: true,
    },
  },
  components: {
    OrderItem,
  },
};
</script>
<style scoped>
.tables {
  width: 100%;
  display: grid;
  grid-template-rows: 45px calc(100vh - 319px) 35px;
  row-gap: 18px;
  grid-template-areas:
    "head"
    "body"
    "footer";
}
.input {
  position: relative;
}
.input img {
  position: absolute;
  top: 13px;
  left: 15px;
}
input {
  width: 100%;
  padding-left: 55px;
}
.list-orders {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 109px;
  max-height: calc(100vh - 319px);
  overflow: auto;
  gap: 16px;
}
.new-order {
  height: 109px;
  width: 170px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.accept-all {
  display: flex;
  margin-top: 10px;
  grid-area: footer;
}
.handler {
  width: 34px;
  height: 20px;
  background-color: gray;
  border-radius: 80px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 3px;
  margin-right: 12px;
  cursor: pointer;
}
.active {
  background-color: #02c874;
  justify-content: flex-end;
  border: none;
}
.dot {
  height: 14px;
  width: 14px;
  background: #fff;
  border-radius: 100%;
}
.close-box {
  background-color: transparent;
  border: none;
}
</style>
