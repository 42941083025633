<template>
  <div class="menu-view">
    <p class="breadcrumb">Pedidos &gt; &nbsp; <span>Crear pedido</span></p>
    <div class="search-options">
      <nav>
        <div v-for="category in productsFiltered" :key="category.id">
          <category-button
            v-if="category.products.length < 1"
            :id="category.id"
            @action="setCategory"
            :currentButton="currentButton"
          >
            {{ category.name }}
          </category-button>
        </div>
      </nav>
      <div class="input">
        <img class="search-icon" src="@/assets/icons/search.svg" alt="" />
        <input
          type="text"
          placeholder="Busca un producto"
          v-model="textForFilter"
          @input="filterByText"
        />
      </div>
    </div>
    <section class="text-start list-categories">
      <div v-for="category in productsFiltered" :key="category.id">
        <div v-if="category.products.length > 0" class="category">
          <h3>{{ category.name }}</h3>
          <div class="products-list">
            <div v-for="product in category.products" :key="product.id">
              <product :product="product" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section :class="showOrder ? 'order-list show-order' : 'order-list'">
      <h2 class="padding-grid">Pedido</h2>
      <h5 class="padding-grid">Mesa #{{ table }}</h5>
      <!-- <h5>Orden #99999</h5> -->
      <section v-if="shopCart.length > 0" class="food-list padding-grid">
        <div v-if="eatHere.length > 0" class="mt-3">
          <p class="group-food"><span>PARA COMER AQUÍ</span></p>
          <div v-for="(product, index) in eatHere" :key="product.product_id">
            <cart-product :product="product" />
            <hr v-if="index + 1 < eatHere.length" />
          </div>
        </div>
        <div v-if="takeAway.length > 0" class="mt-4">
          <p class="group-food"><span>PARA LLEVAR</span></p>
          <div v-for="(product, index) in takeAway" :key="product.product_id">
            <cart-product :product="product" />
            <hr v-if="index + 1 < takeAway.length" />
          </div>
        </div>
      </section>
      <h3 class="padding-grid" v-if="shopCart < 1">
        Agregar productos que deseas ordenar
      </h3>
      <div class="handler-order">
        <!-- <global-price /> -->
        <nelkir-button :disable="shopCart.length < 1" @click="createOrder">
          Crear Pedido
        </nelkir-button>
      </div>
    </section>
    <button class="alert-button" v-if="!showOrder" @click="toggleShowOrder">
      Ver pedido
    </button>
    <modal-product />
    <modal-cart-product />
    <warn-before-close />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import CategoryButton from "@/components/tools/buttons/CategoryButton";
// import NelkirButton from "@/components/tools/buttons/NelkirButton";
// import Product from "@/components/products/Product.vue";
// import CartProduct from "@/components/products/CartProduct.vue";
// import ModalProduct from "@/modals/menu/ModalProduct.vue";
// import ModalCartProduct from "@/modals/menu/ModalCartProduct";
// import WarnBeforeClose from "@/components/tools/WarnBeforeClose";
// import GlobalPrice from "@/components/products/GlobalPrice.vue";
export default {
  name: "MenuView",
  components: {
    // CategoryButton,
    // Product,
    // CartProduct,
    // GlobalPrice,
    // ModalProduct,
    // ModalCartProduct,
    // NelkirButton,
    // WarnBeforeClose,
  },
  data() {
    return {
      loading: false,
      currentButton: 0,
      showOrder: false,
      textForFilter: "",
      categories: "",
      productsFiltered: "",
      globalPrice: 0,
    };
  },
  computed: {
    ...mapGetters({
      // categories: "getProductsFiltered",
      shopCart: "getShopCart",
      table: "getCurrentTable",
      domainName: "getDomainName",
      currentBranch: "getCurrentBranch",
    }),
    eatHere() {
      let some = [];
      if (this.shopCart === null) {
        return some;
      }
      some = this.shopCart.filter((product) => product.order_type == 3);
      return some;
    },
    takeAway() {
      let some = [];
      if (this.shopCart === null) {
        return some;
      }
      some = this.shopCart.filter((product) => product.order_type == 1);
      return some;
    },
  },
  methods: {
    getAllProducts() {
      this.loading = true;
      this.$store
        .dispatch("businessRequest", {
          action: "fetchCategoriesWithProducts",
        })
        .then((response) => {
          if (response.status == 200) {
            this.loading = false;
            this.categories = response.data;
            this.filterByText();
            this.setCategory(this.categories[0].id);
          }
        });
    },
    setCategory(payload) {
      this.currentButton = payload;
    },
    toggleShowOrder() {
      this.showOrder = !this.showOrder;
    },
    getInfo() {
      return {
        table: this.table,
        order_type: 2,
        payment_type: 1,
        service_type: 1,
        order_products: this.getFood(),
      };
    },
    getFood() {
      const products = [];
      for (let index = 0; index < this.shopCart.length; index++) {
        const element = this.shopCart[index];
        products.push({
          product_id: element.product_id,
          order_type: 1,
          quantity: element.quantity,
          additions: this.getAdditions(element),
          options: this.getOptions(element),
        });
      }
      return products;
    },
    getOptions(product) {
      const elections = [];
      for (let index = 0; index < product.options.length; index++) {
        const option = product.options[index];
        for (let j = 0; j < option.elections.length; j++) {
          const election = option.elections[j];
          elections.push({
            election_id: election.id,
          });
        }
      }
      return elections;
    },
    getAdditions(product) {
      const elections = [];
      for (let index = 0; index < product.additions.length; index++) {
        const addition = product.additions[index];
        for (let j = 0; j < addition.elections.length; j++) {
          const election = addition.elections[j];
          elections.push({
            addition_id: election.id,
          });
        }
      }
      return elections;
    },
    createOrder() {
      this.$store
        .dispatch("businessRequest", {
          action: "createOrder",
          data: this.getInfo(),
        })
        .then(() => {
          this.$store.commit("resetShopCart");
          this.$store.commit("setToastMessage", "Orden creada");
          window.$("#toastNotification").toast("show");
          this.$router.push({
            name: "orders",
            params: {
              business: this.domainName,
              branchId: this.currentBranch.id,
            },
          });
        });
    },
    filterByText() {
      // this.$store.commit("filterByText", this.textForFilter);
      if (this.textForFilter === "") {
        // state.isFiltered = false
        this.productsFiltered = JSON.parse(JSON.stringify(this.categories));
      } else {
        // state.isFiltered = true
        this.productsFiltered = JSON.parse(JSON.stringify(this.categories));
        this.textForFilter = this.textForFilter.toLowerCase();
        for (let index = 0; index < this.productsFiltered.length; index++) {
          this.productsFiltered[index].products = this.productsFiltered[
            index
          ].products.filter((product) => {
            return product.name.toLowerCase().indexOf(this.textForFilter) > -1;
          });
        }
      }
    },
  },
  created() {
    this.getAllProducts();
  },
  mounted() {
    // this.filterByText();
  },
  unmounted() {
    this.$store.commit("resetShopCart");
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.shopCart.length > 0) {
  //     const answer = window.confirm(
  //       "¿Desea abandonar la creación de la orden?"
  //     );
  //     if (answer) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   }
  // },
};
</script>
<style scoped>
.menu-view {
  padding-right: 290px;
}
.breadcrumb {
  text-align: start;
}
.category {
  margin-top: 20px;
}
h2 {
  margin-bottom: 16px;
}
h5 {
  color: #2f2f2f;
  font-weight: 400;
  margin-bottom: 6px;
}
.group-food {
  color: #828282;
}
nav {
  display: flex;
  align-items: center;
}
.body-view {
  display: flex;
  text-align: start;
}
.products-list {
  margin-top: 21px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 21px;
}
.order-list {
  position: fixed;
  width: 290px;
  background-color: #f5f5f5;
  height: 100vh;
  top: 0;
  right: 0;
  border-left: 1px solid #e1e1e1;
  padding: 100px 0 0 0;
  text-align: start;
  transition: 0.5s;
  display: grid;
  grid-template-rows: 44px 22px calc(100vh - 296px) 130px;
}
.padding-grid {
  padding: 0 20px;
}
.food-list {
  overflow: auto;
  overflow-x: hidden;
}
.alert-button {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35px;
  background-color: #cb3734;
  border: none;
  border-radius: 0px;
}
.search-options {
  display: grid;
  grid-template-columns: 1fr 270px;
}
.search-options input {
  width: 100%;
  padding-left: 40px;
}
.search-options .input {
  position: relative;
}
.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);
}

.handler-order {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
@media screen and (max-width: 1439px) {
  .products-list {
    column-gap: 20px;
    row-gap: 20px;
  }
}
@media screen and (max-width: 1359px) {
  .products-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  nav {
    margin: 0;
  }
  .products-list {
    grid-template-columns: 1fr;
  }
  .menu-view {
    padding: 0;
  }
  .breadcrumb {
    display: none;
  }
  .order-list {
    right: -100%;
    width: 100%;
  }
  .show-order {
    right: 0;
  }
  .alert-button {
    display: block;
  }
}
</style>
