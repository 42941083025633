<template>
  <div class="kitchen-view" v-if="!loading">
    <header>
      <h3>Comandas en cocina</h3>
      <div class="handler">
        <button @click="previousPage">
          <img
            :src="currentPage > 0 ? leftArrow : leftArrowDesactive"
            alt="arrow-button"
          />
        </button>
        <p>Pagina {{ currentPage + 1 }} de {{ pages.length }}</p>
        <button @click="nextPage">
          <img
            :src="
              currentPage + 1 < pages.length ? rightArrow : rightArrowDesactive
            "
            alt="arrow-button"
          />
        </button>
      </div>
    </header>
    <div class="page">
      <div
        class="comandan-container"
        v-for="order in ordersInKitchen"
        :key="order.id"
      >
        <comanda :order="order" :fetchOrders="fetchOrders" />
      </div>
    </div>
  </div>
</template>
<script>
import ArrowLeft from "@/assets/icons/arrow-left.svg";
import ArrowRight from "@/assets/icons/arrow-right.svg";
import ArrowLeftDesactive from "@/assets/icons/arrow-left-desactive.svg";
import ArrowRightDesactive from "@/assets/icons/arrow-right-desactive.svg";
import Comanda from "@/components/branchView/orders/kitchen/Comanda.vue";
import { mapGetters } from "vuex";
export default {
  name: "kitchen-view",
  components: {
    Comanda,
  },
  data() {
    return {
      pages: [],
      currentPage: 0,
      itemPerPage: 4,
      leftArrow: ArrowLeft,
      rightArrow: ArrowRight,
      leftArrowDesactive: ArrowLeftDesactive,
      rightArrowDesactive: ArrowRightDesactive,
      loading: false,
      ordersInKitchen: "",
    };
  },
  computed: {
    ...mapGetters({
      orders: "getOrders",
    }),
  },
  methods: {
    fetchOrders() {
      this.loading = true;
      this.$store
        .dispatch("businessRequest", {
          action: "fetchOrders",
        })
        .then(() => {
          this.setPages();
          this.filterByStatus();
        });
    },
    setPages() {
      this.pages = [];
      let page = [];
      for (let index = 0; index < this.orders.length; index++) {
        const element = this.orders[index];
        page.push(element);
        if (page.length == this.itemPerPage) {
          this.pages.push(page);
          page = [];
        } else if (index + 1 == this.orders.length) {
          this.pages.push(page);
          page = [];
        }
      }
      this.loading = false;
    },
    previousPage() {
      if (this.currentPage + 1 > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage + 1 < this.pages.length) {
        this.currentPage++;
      }
    },
    filterByStatus() {
      const newOrders = this.orders.filter((order) => order.order_status == 2);
      this.ordersInKitchen = newOrders;
    },
  },
  created() {
    this.fetchOrders();
    const width = window.innerWidth;
    if (width <= 1024) {
      this.itemPerPage = 3;
    }
    if (width <= 425) {
      this.itemPerPage = 1;
    }
  },
};
</script>
<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
header p {
  color: #4f4f4f;
}
.handler {
  display: flex;
  justify-content: center;
  align-items: center;
}
.handler button {
  border: none;
  background-color: transparent;
}
.kitchen-view {
  height: 100%;
}
.page {
  height: calc(100vh - 188px);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 30px;
}
.comandan-container {
  height: inherit;
}
@media screen and (max-width: 1439px) {
  .page {
    column-gap: 24px;
  }
}
@media screen and (max-width: 1359px) {
  .page {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .page {
    grid-template-columns: 1fr;
  }
  .comandan-container {
    width: 100%;
  }
}
</style>
