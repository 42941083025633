<template>
  <div
    class="modal fade"
    id="addAditionsModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content modal-sm"></div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped></style>
