<template>
  <button
    :class="active ? 'sidebar-button active' : 'sidebar-button'"
    @click="setButton"
  >
    <img :src="active ? button.iconActive : button.icon" alt="" />
    {{ button.name }}
    <div v-if="active" class="mark"></div>
  </button>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SidebarButton",
  props: {
    button: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentButton: "getCurrentSidebarBranchButton",
    }),
    active() {
      return this.currentButton.id == this.button.id;
    },
  },
  methods: {
    setButton() {
      this.$store.commit("setCurrentSidebarBranchButton", this.button);
      this.$store.commit("setDummyInCurrentDropdownButton");
    },
  },
};
</script>
<style scoped>
.sidebar-button {
  height: 56px;
  border: none;
  background-color: transparent;
  width: 100%;
  color: #828282;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar-button img {
  margin-left: 34px;
  margin-right: 16px;
}
.active {
  background-color: rgba(2, 200, 116, 0.08);
  color: #02c874;
  font-weight: 700;
}
.mark {
  height: 100%;
  width: 3px;
  background-color: #02c874;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 1359px) {
  .sidebar-button img {
    margin-left: 16px;
  }
}
@media screen and (max-width: 960px) {
  .sidebar-button {
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .sidebar-button img {
    margin-left: 34px;
  }
}
</style>
