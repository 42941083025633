<template>
  <div
    class="modal fade"
    id="modalBillingOrder"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div v-if="render" class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Facturar pedido</h3>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="order-info">
            <h4>INFORMACIÓN DEL PEDIDO</h4>
            <div class="list-container">
              <div class="t-head">
                <p><strong>Producto</strong></p>
                <p><strong>Cant.</strong></p>
                <p class="text-end"><strong>Valor</strong></p>
              </div>
              <div
                v-for="(product, index) in order.order_products"
                :key="product.id"
              >
                <product-bill :product="product" />
                <div
                  v-if="index + 1 < order.order_products.length"
                  class="break-line"
                ></div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <strong>SUBTOTAL: </strong>
              ${{ new Intl.NumberFormat("es-CO").format(totalPrice) }}
            </div>
            <div class="d-flex justify-content-between">
              <p>Impuesto consumo 8%</p>
              ${{
                new Intl.NumberFormat("es-CO").format(
                  Math.round(totalPrice * 0.08)
                )
              }}
            </div>
            <div class="d-flex justify-content-between">
              <p>Propina</p>
              ${{ new Intl.NumberFormat("es-CO").format(perquisite) }}
            </div>
            <div class="total-price">
              <strong
                >TOTAL: ${{
                  new Intl.NumberFormat("es-CO").format(totalWithDiscount)
                }}</strong
              >
            </div>
          </div>
          <div class="billing-form">
            <!-- <section>
              <nelkir-select-v2
                :label="'Acciones'"
                :options="actions"
                :uniqueId="`actions`"
                :select="setAction"
              />
            </section> -->
            <section v-if="currentAction == 1">
              <nelkir-select-v2 :uniqueId="`actions`" />
            </section>
            <section v-if="currentAction == 1" class="inputs">
              <percent-input
                :label="'Porcentaje'"
                @customChange="setDiscountPercent"
                :value="discountPercent"
              />
              <currency-input
                :label="'Valor'"
                @customChange="setDiscountValue"
                :value="discountValue"
              />
            </section>
            <section v-if="currentAction == 2" class="inputs">
              <text-input :label="Nombre" @customChange="setCuponName" />
              <currency-input :label="'Valor'" @customChange="setCuponValue" />
            </section>
            <section>
              <!-- <currency-input
                :label="'Propina'"
                @customChange="setPerquisite"
              /> -->
              <div class="input">
                <label for=""><span>Propina</span></label>
                <input type="number" v-model="perquisite" />
                <p class="cash-symbol">$</p>
              </div>
            </section>
            <section class="pay-methods">
              <h4>Selecciona método de pago</h4>
              <div class="d-flex justify-content-between">
                <button
                  :class="payMethod == 1 ? 'pay-button active' : 'pay-button'"
                  @click="setPayMethod(1)"
                >
                  <img :src="payMethod == 1 ? iconCashWhite : iconCash" />
                  Efectivo
                </button>
                <button
                  :class="payMethod == 2 ? 'pay-button active' : 'pay-button'"
                  @click="setPayMethod(2)"
                >
                  <img
                    :src="payMethod == 2 ? iconDatafonoWhite : iconDatafono"
                  />
                  Datáfono
                </button>
              </div>
            </section>
            <section v-if="payMethod == 1" class="inputs">
              <currency-input
                :label="'Total efectivo'"
                @customChange="setTotalCash"
              />
              <div class="text-start">
                <strong>Total a devolver</strong>
                <p class="cashReturn">$ {{ totalReturn }}</p>
              </div>
            </section>
            <section v-if="payMethod == 2">
              <nelkir-radio-check
                :elements="datafono"
                :initialState="datafonoOption"
                :action="setDatafonoOption"
              />
            </section>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="transparent-button"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="primary-button"
              :disabled="disalbleButton"
              @click="facturar"
            >
              Facturar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductBill from "./ProductOfBill.vue";
import NelkirSelectV2 from "@/components/tools/NelkirSelectV2";
import Cash from "@/assets/icons/cash.svg";
import CashWhite from "@/assets/icons/cash-white.svg";
import Datafono from "@/assets/icons/datafono.svg";
import DatafonoWhite from "@/assets/icons/datafono-white.svg";
import CurrencyInput from "@/components/tools/inputs/CurrencyInput";
import PercentInput from "@/components/tools/inputs/PercentInput";
import TextInput from "@/components/tools/inputs/TextInput";
import NelkirRadioCheck from "@/components/tools/NelkirRadioCheck";
export default {
  name: "ModalBillingOrder",
  props: {
    order: {
      type: Object,
      required: true,
    },
    fetchOrders: {
      type: Function,
      required: true,
    },
  },
  components: {
    ProductBill,
    NelkirSelectV2,
    CurrencyInput,
    PercentInput,
    TextInput,
    NelkirRadioCheck,
  },
  data() {
    return {
      actions: [
        {
          name: "Ninguna",
          id: 0,
        },
        {
          name: "Descuento",
          id: 1,
        },
        {
          name: "Cupón",
          id: 2,
        },
      ],
      payMethod: 0,
      currentAction: 0,
      iconCash: Cash,
      iconCashWhite: CashWhite,
      iconDatafono: Datafono,
      iconDatafonoWhite: DatafonoWhite,
      cuponName: "",
      cuponValue: 0,
      discountPercent: "",
      discountValue: 0,
      totalCash: 0,
      totalReturn: 0,
      perquisite: 0,
      render: false,
      datafono: [
        { name: "Debito", id: 0 },
        { name: "Credito", id: 1 },
      ],
      datafonoOption: 0,
    };
  },
  computed: {
    totalPrice() {
      let orderPrice = 0;
      for (let index = 0; index < this.order.order_products.length; index++) {
        const product = this.order.order_products[index];
        let totalPrice =
          product.product_price.price * (1 + product.product_price.iva);
        for (
          let index = 0;
          index < product.order_option_election.length;
          index++
        ) {
          const element = product.order_option_election[index].price;
          totalPrice = totalPrice + element;
        }
        for (let index = 0; index < product.order_addition.length; index++) {
          const element = product.order_addition[index].price;
          totalPrice = totalPrice + element;
        }
        orderPrice = orderPrice + totalPrice * product.quantity;
      }
      return orderPrice;
    },
    totalWithDiscount() {
      const total =
        this.totalPrice +
        Number(this.perquisite) -
        this.discountValue -
        this.cuponValue +
        this.totalPrice * 0.08;
      return Math.round(total);
    },
    disalbleButton() {
      let option = true;
      if (this.payMethod == 2) {
        if (this.currentAction == 0) {
          option = this.perquisite < 0 || this.perquisite.length == 0;
        } else if (this.currentAction == 1) {
          option =
            this.perquisite < 0 ||
            this.perquisite.length == 0 ||
            this.discountPercent == 0 ||
            this.discountValue == 0;
        } else if (this.currentAction == 2) {
          option =
            this.perquisite < 0 ||
            this.perquisite.length == 0 ||
            this.cuponName.length == 0 ||
            this.cuponValue <= 0;
        }
      }
      if (this.payMethod == 1) {
        if (this.currentAction == 0) {
          option =
            this.perquisite < 0 ||
            this.perquisite.length == 0 ||
            this.totalCash <= 0 ||
            this.totalReturn < 0;
        } else if (this.currentAction == 1) {
          option =
            this.totalCash <= 0 ||
            this.totalReturn <= 0 ||
            this.perquisite < 0 ||
            this.perquisite.length == 0 ||
            this.discountPercent == 0 ||
            this.discountValue == 0;
        } else if (this.currentAction == 2) {
          option =
            this.totalCash == 0 ||
            this.totalReturn <= 0 ||
            this.perquisite < 0 ||
            this.perquisite.length == 0 ||
            this.cuponName == 0 ||
            this.cuponValue == 0;
        }
      }
      return option;
    },
  },
  methods: {
    facturar() {
      this.$store
        .dispatch("businessRequest", {
          action: "checkIn",
          data: {
            id: this.order.id,
            order: {
              order_status: 5,
              payment_type: this.currentButton,
              cash_recieved: this.totalCash,
              cash_returned: this.totalReturn,
            },
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.fetchOrders();
            this.$store.commit("setCurrentNewOrder", { id: -1 });
            window.$("#modalBillingOrder").modal("hide");
          }
        });
    },
    setDatafonoOption(payload) {
      this.datafonoOption = payload;
    },
    setPayMethod(payload) {
      this.payMethod = payload;
      if (this.payMethod == 2) {
        this.totalCash = this.totalWithDiscount;
        this.totalReturn = 0;
      } else {
        this.totalCash = 0;
      }
    },
    setAction(id) {
      this.currentAction = id;
      this.cuponValue = 0;
      this.discountValue = 0;
    },
    setCuponName(event) {
      this.cuponName = event;
    },
    setCuponValue(event) {
      this.cuponValue = event;
    },
    setDiscountPercent(event) {
      this.discountValue = Math.round((event * this.totalPrice) / 100);
      this.discountPercent = event;
    },
    setDiscountValue(event) {
      this.discountPercent = ((event * 100) / this.totalPrice).toFixed(2);
      this.discountValue = event;
    },
    setTotalCash(event) {
      this.totalCash = event;
      this.totalReturn = Math.round(event - this.totalWithDiscount);
    },
    setPerquisite(event) {
      this.perquisite = event;
    },
    renderContent() {
      this.render = true;
      this.perquisite = Math.round(this.totalPrice * 0.1);
      this.perquisite.toString();
    },
    renderOff() {
      this.render = false;
    },
  },
  mounted() {
    const render = this.renderContent;
    const renderOff = this.renderOff;
    const myModalEl = document.querySelector("#modalBillingOrder");
    myModalEl.addEventListener("show.bs.modal", () => {
      render();
    });
    myModalEl.addEventListener("hidden.bs.modal", () => {
      renderOff();
    });
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 800px;
}
.modal-content {
  padding: 26px 34px;
}
.modal-header {
  border: none;
  padding: 0;
  margin-bottom: 16px;
}
.modal-body {
  padding: 0;
  display: grid;
  grid-template-columns: 375px 310px;
  grid-template-rows: auto auto;
  grid-template-areas:
    "left-a right-a"
    "left-a right-b";
  column-gap: 40px;
}
.order-info {
  display: grid;
  grid-template-rows: 25px 520px auto;
  width: 375px;
  height: 100%;
  background-color: #f2f2f2;
  border-radius: 5px;
  grid-area: left-a;
  padding: 14px 19px;
  overflow: auto;
}
.list-container {
  overflow: auto;
}
.t-head {
  display: grid;
  grid-template-columns: 180px 50px 100px;
  margin-bottom: 16px;
}
.total-price {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-top: 1px solid #e0e0e0;
  padding-top: 12px;
}
.billing-form {
  grid-area: right-a;
}
.billing-form section {
  margin-bottom: 14px;
}
.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
}
.input {
  position: relative;
}
.cash-symbol {
  position: absolute;
  top: 44px;
  left: 10px;
}
input {
  width: 100%;
  margin-top: 8px;
  padding-left: 20px;
}
.cashReturn {
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}
.modal-footer {
  padding: 0;
  border: none;
  grid-area: right-b;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
h4 {
  color: #828282;
  text-align: start;
  margin-bottom: 12px;
}
.break-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 18px 0 14px;
}
.billing-form h4 {
  color: #484848;
}
.pay-button {
  width: 134px;
  height: 45px;
  background: #fbfbfb;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.active {
  box-shadow: none;
  border: 2px solid #e0e0e0;
  color: #fff;
  background-color: #222237;
}
.primary-button {
  width: 140px;
}
.transparent-button {
  width: 120px;
  background-color: transparent;
  border: none;
  height: 45px;
}
</style>
