<template>
  <div class="product-information">
    <div class="tabs">
      <div v-for="tab in tabs" :key="tab.id">
        <button
          :class="tab.active ? 'tab active' : 'tab'"
          v-text="tab.text"
          @click="toggle(tab.id)"
        ></button>
      </div>
    </div>
    <div class="current-view">
      <product-details
        v-if="currentTab == 0"
        :categoryId="categoryId"
        :select="select"
        :fetchProducts="fetchProducts"
      />
      <product-options v-if="currentTab == 1" />
      <product-allergen v-if="currentTab == 2" />
      <product-prescription v-if="currentTab == 3" />
    </div>
  </div>
</template>
<script>
import ProductDetails from "@/components/menu/products/ProductDetails.vue";
import ProductOptions from "@/components/menu/products/ProductOptions.vue";
import ProductAllergen from "@/components/menu/products/ProductAllergen.vue";
import ProductPrescription from "@/components/menu/products/ProductPrescription.vue";
export default {
  name: "product-information",
  components: {
    ProductDetails,
    ProductOptions,
    ProductAllergen,
    ProductPrescription,
  },
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
    fetchProducts: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      tabs: [
        { text: "DETALLES", id: 0, active: true },
        { text: "OPCIONES", id: 1, active: false },
        { text: "ALÉRGENOS", id: 2, active: false },
        // { text: "RECETA", id: 3, active: false }
      ],
      currentTab: 0,
    };
  },
  methods: {
    toggle(payload) {
      for (let index = 0; index < this.tabs.length; index++) {
        if (index == payload) {
          this.tabs[index].active = true;
          this.currentTab = index;
        } else {
          this.tabs[index].active = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.product-information {
  width: 670px;
}
.tabs {
  background-color: transparent;
  display: flex;
  height: 40px;
  border-bottom: 1px solid #e0e0e0;
}
.tab {
  height: 40px;
  padding: 0 15px;
  font-size: 14px;
  position: relative;
  border: none;
  background-color: transparent;
  font-weight: 700;
  color: #828282;
}
.active {
  border-bottom: 2px solid #02c874;
  color: #02c874;
}
.current-view {
  margin-top: 18px;
}
@media screen and (max-width: 1439px) {
  .product-information {
    width: 637px;
  }
}
@media screen and (max-width: 1359px) {
  .product-information {
    width: 468px;
  }
}
@media screen and (max-width: 1023px) {
  .product-information {
    width: 280px;
  }
  .tabs {
    border-bottom: none;
  }
  .tab {
    padding: 0 5px;
    font-size: 12px;
  }
}
</style>
