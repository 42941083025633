<template>
  <div class="product-options box">
    <h3>Grupos de opciones y adiciones</h3>
    <p>
      <span>Las opciones</span> son elecciones propias de este producto mientras
      que <span>las adiciones</span> son productos aparte que pueden acompañar a
      este producto.
    </p>
    <div class="adds">
      <div class="add">
        <h3>Opciones</h3>
        <div class="buttons">
          <button class="primary-button-white" @click="openModalAddOption">
            <img src="@/assets/icons/plus-green.svg" alt="add-icon" />Nueva
          </button>
          <button class="primary-button">
            <img src="@/assets/icons/plus-white.svg" alt="add-icon" />Existente
          </button>
        </div>
        <div v-if="options.length > 0">
          <div v-for="option in options" :key="option.id">
            <option-item
              :option="option"
              :editOption="editOption"
              :reloadListOptions="reloadList"
            />
          </div>
        </div>
        <p v-else>Este producto no tiene opciones.</p>
      </div>
      <div class="add">
        <h3>Adiciones</h3>
        <div class="buttons">
          <button class="tertiary-button-white" @click="openModalAddAddition">
            <img src="@/assets/icons/plus-red.svg" alt="add-icon" />Nueva
          </button>
          <button class="alert-button">
            <img
              src="@/assets/icons/plus-white.svg"
              alt="add-icon"
              @click="openModalEditAddition"
            />Existente
          </button>
        </div>
        <div v-if="additions.length > 0">
          <div v-for="addition in additions" :key="addition.id">
            <addition-item
              :option="addition"
              :editOption="editAddition"
              :reloadListOptions="reloadList"
            />
          </div>
        </div>
        <p v-else>Este producto no tiene adiciones.</p>
      </div>
    </div>
    <modal-new-options :reloadListOptions="reloadList" />
    <modal-edit-option :option="optionToEdit" :reloadListOptions="reloadList" />
    <modal-new-addition :reloadListOptions="reloadList" />
    <modal-edit-addition
      :option="optionToEdit"
      :reloadListOptions="reloadList"
    />
    <modal-add-aditions />
  </div>
</template>
<script>
import OptionItem from "@/components/menu/products/options/OptionItem.vue";
import AdditionItem from "@/components/menu/products/options/AdditionItem.vue";
import ModalNewOptions from "@/modals/ModalNewOptions.vue";
import ModalEditOption from "@/modals/ModalEditOption.vue";
import ModalNewAddition from "@/modals/ModalNewAddition.vue";
import ModalEditAddition from "@/modals/ModalEditAddition.vue";
import ModalAddAditions from "@/modals/ModalAddAditions.vue";
export default {
  name: "ProductOptions",
  components: {
    OptionItem,
    AdditionItem,
    ModalNewOptions,
    ModalEditOption,
    ModalNewAddition,
    ModalEditAddition,
    ModalAddAditions,
  },
  data() {
    return {
      optionToEdit: "",
      renderList: true,
    };
  },
  computed: {
    options() {
      return this.$store.getters.getCurrentProduct.options;
    },
    additions() {
      return this.$store.getters.getCurrentProduct.additions;
    },
  },
  methods: {
    editOption(payload) {
      this.optionToEdit = payload;
      window.$("#editOptionModal").modal("show");
    },
    editAddition(payload) {
      this.optionToEdit = payload;
      window.$("#editAdditionModal").modal("show");
    },
    async reloadList() {
      this.renderList = false;
      await this.$nextTick();
      this.renderList = true;
    },
    openModalAddOption() {
      window.$("#newOptionsModal").modal("show");
    },
    openModalAddAddition() {
      window.$("#newAdditionModal").modal("show");
    },
  },
};
</script>
<style scoped>
.product-options {
  width: 100%;
}
.box {
  padding: 20px;
  align-items: flex-start;
}
h3 {
  margin-bottom: 14px;
}
.adds {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.add {
  margin-top: 18px;
}
.add p {
  margin-top: 20px;
  color: #828282;
}
.buttons {
  display: flex;
}
button {
  width: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
}
button img {
  margin-right: 10px;
}
.primary-button-white,
.tertiary-button-white {
  margin-right: 15px;
}
@media screen and (max-width: 1439px) {
  button {
    width: 128px;
  }
}
@media screen and (max-width: 1359px) {
  .adds {
    flex-direction: column;
  }
  button {
    width: 204px;
  }
}
@media screen and (max-width: 1023px) {
  button {
    width: 116px;
  }
}
</style>
