<template>
  <div class="branches-view">
    <div class="business-list">
      <div v-for="branch in branches" :key="branch.id">
        <branch-item :business="branch" />
      </div>
      <add-business
        data-bs-toggle="modal"
        data-bs-target="#modalCreateBranch"
        :text="addButtonText"
      />
    </div>
    <modal-create-branch :reloadList="fetchBranches" />
  </div>
</template>
<script>
import BranchItem from "@/components/BranchItem.vue";
import AddBusiness from "@/components/businesses/AddBusiness.vue";
import ModalCreateBranch from "@/modals/ModalCreateBranch.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    BranchItem,
    AddBusiness,
    ModalCreateBranch,
  },
  data() {
    return {
      addButtonText: "Crear sucursal",
    };
  },
  computed: {
    branches() {
      return this.$store.getters.getBranches;
    },
    ...mapGetters({
      isAdmin: "getAdmin",
    }),
  },
  methods: {
    fetchBranches() {
      this.$store.dispatch("businessRequest", {
        action: "fetchBranches",
      });
    },
    fetchUserBranches() {
      this.$store.dispatch("businessRequest", {
        action: "fetchUserBranches",
      });
    },
  },
  created() {
    if (this.isAdmin) {
      this.fetchBranches();
    } else {
      this.fetchUserBranches();
    }
  },
};
</script>
<style scoped>
.business-list {
  display: flex;
  flex-wrap: wrap;
}
.business {
  margin-right: 30px;
  margin-top: 16px;
}
</style>
