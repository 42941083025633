<template>
  <div class="qr-tables-layout">
    <h2 class="">Código QR</h2>
    <p class="description">
      El código QR debe ser ubicado en cada una de las mesas del establecimiento
      para controlar correctamente los pedidos de tus comensales.
    </p>
    <!-- <instructions class="instructions" /> -->
    <config class="config" />
    <!-- <preview class="preview" /> -->
    <h2>Total mesas: {{ tables.length }}</h2>
    <div class="table-list">
      <div v-for="table in tables" :key="table.id">
        <qr-table :table="table"></qr-table>
      </div>
    </div>
  </div>
</template>
<script>
// import Instructions from "@/components/qrTables/Instructions.vue";
// import Config from "@/components/qrTables/Config.vue";
// import QrTable from "@/components/qrTables/QrTable.vue";
import { mapGetters } from "vuex";
// import Preview from "@/components/qrTables/Preview.vue";
export default {
  name: "QrTablesView",
  components: {
    // Instructions,
    // Config,
    // QrTable,
    // Preview,
  },
  computed: {
    ...mapGetters({
      tables: "getTables",
    }),
  },
};
</script>
<style scoped>
.qr-tables-layout {
  text-align: start;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
h2 {
  font-size: 20px;
}
.description {
  max-width: 623px;
}
.table-list {
  height: calc(100vh - 490px);
  overflow-y: auto;
  display: grid;
  grid-template-columns: 260px 260px 260px 260px;
  grid-template-rows: 140px;
  gap: 20px;
}
.dummy {
  width: 260px;
  height: 140px;
  background-color: red;
}
.config {
  width: 500px;
}
@media screen and (max-width: 1359px) {
}
@media screen and (max-width: 1023px) {
}
</style>
