<template>
  <div
    class="modal fade"
    id="modalCloseBox"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Cerrar caja</h3>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <h2>$23,000,000</h2>
            <small>Total ventas</small>
          </div>
          <div>
            <h2>$12,130,000</h2>
            <small>Total efectivo</small>
          </div>
          <div>
            <h2>$640,000</h2>
            <small>Total propinas</small>
          </div>
        </div>
        <div class="modal-footer">
          <button class="cancel-btn" data-bs-dismiss="modal">Cancelar</button>
          <n-button> Cerrar caja </n-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NButton from "@/components/tools/buttons/NelkirButton";
export default {
  name: "ModalCloseBox",
  components: {
    NButton,
  },
};
</script>
<style scoped>
.modal-header,
.modal-body,
.modal-footer {
  border: none;
  padding: 30px;
}
.modal-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
  row-gap: 30px;
}
.primary-button {
  width: 150px;
}
.cancel-btn {
  border: none;
  background-color: transparent;
  width: 80px;
  margin-right: 20px;
}
</style>
