import axios from "axios";
import store from "./store";

let baseURL = "";
// const businessUrl = localStorage.getItem('vuex') ? JSON.parse(localStorage.getItem('vuex')) : null
const authTokens = localStorage.getItem("businessTokens")
  ? JSON.parse(localStorage.getItem("businessTokens"))
  : null;

if (process.env.NODE_ENV === "development") {
  baseURL = "https://api.payfud.com";
} else {
  baseURL = "https://api.payfud.com";
}

const axiosInstance = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${authTokens?.access}` },
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    // Cualquier código de estado que este fuera del rango de 2xx causa la ejecución de esta función
    // Haz algo con el error
    if (error.response.status === 401) {
      await store.dispatch("refreshBusinessToken");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
