import api from "@/api";
import axios from "@/businessApi";

export const fetchOrders = ({ commit, rootState }) => {
  commit("setUpdateComandas", true);
  return new Promise((resolve) => {
    axios
      .get(`${api}/r/${rootState.domainName}/v1/orders`, rootState.businessAuth)
      .then(
        (response) => {
          commit("setOrders", response);
          resolve(response);
          commit("setUpdateComandas", false);
        },
        (error) => {
          resolve(error.response);
          commit("setUpdateComandas", false);
        }
      );
  });
};
export const updateOrder = ({ rootState }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${api}/r/${rootState.domainName}/v1/orders/${payload.id}`,
        {
          order_status: payload.order_status,
          order_products: payload.order_products,
        },
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
          reject(error);
        }
      );
  });
};
export const deleteProduct = ({ rootState }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${api}/r/${rootState.domainName}/v1/orders/${payload.orderId}`,
        {
          order_products: payload.order_products,
        },
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
          reject(error);
        }
      );
  });
};
export const setPayTypeOrder = ({ rootState }, payload) => {
  const data = {
    payment_type_arr: payload.payment_type_arr,
    tip_percentage: payload.tip_percentage,
    order_products: [],
  };
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${api}/r/${rootState.domainName}/v1/orders/${payload.id}`,
        data,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
          reject(error);
        }
      );
  });
};
export const updateProductsStatus = ({ rootState }, payload) => {
  return new Promise((resolve) => {
    axios
      .put(
        `${api}/r/${rootState.domainName}/v1/orders/${payload.id}`,
        {
          order_products: payload.order_products,
        },
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const closeOrder = ({ rootState }, payload) => {
  return new Promise((resolve) => {
    axios
      .put(
        `${api}/r/${rootState.domainName}/v1/orders/${payload.id}`,
        {
          order_status: payload.order_status,
          card_received: payload.card_received,
          cash_received: payload.cash_received,
          cash_returned: payload.cash_returned,
          transfer_received: payload.transfer_received,
          tip_percentage: payload.tip_percentage,
          // payment_type: payload.payment_type,
          order_products: payload.order_products,
          payment_type_arr: payload.payment_type_arr,
        },
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
// export const finishOrder = ({ rootState }, payload) => {
//   return new Promise((resolve) => {
//     axios
//       .put(
//         `${api}/r/${rootState.domainName}/v1/orders/${payload.id}`,
//         {
//           order_products: payload.order_products,
//         },
//         rootState.businessAuth
//       )
//       .then(
//         (response) => {
//           resolve(response);
//         },
//         (error) => {
//           resolve(error.response);
//         }
//       );
//   });
// };
