export const setOrders = (state, payload) => {
  state.orders = payload.data;
};

export const setCurrentOrder = (state, payload) => {
  state.currentOrder = payload;
};

export const setRequestedOrder = (state, payload) => {
  state.requestedOrders.push(payload);
};
export const setUpdateComandas = (state, payload) => {
  state.updateComandas = payload;
};
