export const setOrder = (state, payload) => {
  state.table = payload.table;
  state.orderType = payload.orderType;
};

export const addProduct = (state, payload) => {
  state.products.push(payload);
};
export const resetOrder = (state) => {
  state.products = [];
};
export const removeProduct = (state, productId) => {
  for (let index = 0; index < state.products.length; index++) {
    const product = state.products[index];
    if (product.product.id === productId) {
      state.products.splice(index, 1);
    }
  }
};
export const updateProduct = (state, updatedProduct) => {
  for (let index = 0; index < state.products.length; index++) {
    const product = state.products[index];
    if (product.product.id === updatedProduct.product.id) {
      state.products[index] = updatedProduct;
    }
  }
};
