<template>
  <div id="Dashboard">
    <div v-if="!isLogued">
      <default-spinner />
    </div>
    <div v-else class="dashboard">
      <header-client
        class="header"
        :businessInfo="true"
        :shortcutBranches="isAdmin"
        :showSidebar="showSidebar"
      />
      <Sidebar
        :class="sidebar ? 'sidebar show-sidebar' : 'sidebar'"
        :hideSidebar="hideSidebar"
      />
      <div class="view">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import HeaderClient from "@/components/HeaderClient.vue";
import DefaultSpinner from "@/components/DefaultSpinner.vue";
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";
export default {
  name: "dashboard-view",
  components: {
    HeaderClient,
    Sidebar: defineAsyncComponent(() =>
      import("@/modules/business/components/Sidebar")
    ),
    DefaultSpinner,
  },
  data() {
    return {
      sidebar: false,
    };
  },
  computed: {
    showPopUp() {
      return this.$store.getters.getPopUpMessage.length > 0;
    },
    ...mapGetters({
      isAdmin: "getAdmin",
      isLogued: "getLogued",
    }),
  },
  methods: {
    showSidebar() {
      this.sidebar = true;
    },
    hideSidebar() {
      this.sidebar = false;
    },
    checkToken() {
      this.$store.dispatch("checkToken").then((response) => {
        if (response.data.code == "token_not_valid") {
          this.refreshToken();
        }
      });
    },
    refreshToken() {
      this.$store.dispatch("refreshToken").then((response) => {
        if (response.data.code == "token_not_valid") {
          this.$store.commit("logout");
          this.$router.push("/");
        }
      });
    },
  },
  created() {
    if (!this.isLogued) {
      this.$router.push("/");
    }
  },
};
</script>
<style scoped>
.dashboard {
  padding: 76px 0 0 227px;
  height: 100vh;
  overflow: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
}
.sidebar {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  transition: 0.5s;
}
.show-sidebar {
  left: 0;
}
.view {
  padding: 30px 20px 0 30px;
  margin-bottom: 20px;
}
@media screen and (max-width: 1439px) {
  .dashboard {
    padding-left: 220px;
  }
}
@media screen and (max-width: 1359px) {
  .dashboard {
    padding-left: 170px;
  }
}
@media screen and (max-width: 1023px) {
  .dashboard {
    padding-left: 0;
  }
  .sidebar {
    left: -210px;
  }
  .show-sidebar {
    left: 0;
  }
  .view {
    padding: 28px 0 0 28px;
  }
}
</style>
