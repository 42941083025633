export const setCurrentProduct = (state, product) => {
  state.product = product;
};
export const setOptionsAndAdditions = (state, product) => {
  if (product) {
    state.options = product.options;
    state.additions = product.additions;
  } else {
    for (let index = 0; index < state.product.options.length; index++) {
      const option = state.product.options[index];
      state.options.push({
        name: option.name,
        id: index,
        elections: [],
        max: option.max_required,
        maxPerItems: option.selection_times,
        required: option.required,
        quantity: 1,
      });
    }
    for (let index = 0; index < state.product.additions.length; index++) {
      const addition = state.product.additions[index];
      state.additions.push({
        name: addition.name,
        id: index,
        elections: [],
        max: addition.max_required,
        maxPerItems: addition.selection_times,
        required: addition.required,
        quantity: 1,
      });
    }
  }
};
export const setElectionOfOption = (state, { election, index }) => {
  if (state.options[index].elections.includes(election)) {
    for (let i = 0; i < state.options[index].elections.length; i++) {
      const element = state.options[index].elections[i];
      if (element.id === election.id) {
        state.options[index].elections.splice(i, 1);
      }
    }
  } else {
    if (state.options[index].elections.length < state.options[index].max) {
      election.quantity = 1;
      state.options[index].elections.push(election);
    }
  }
};
export const setElectionOfAddition = (state, { election, index }) => {
  if (state.additions[index].elections.includes(election)) {
    for (let i = 0; i < state.additions[index].elections.length; i++) {
      const element = state.additions[index].elections[i];
      if (element.id === election.id) {
        state.additions[index].elections.splice(i, 1);
      }
    }
  } else {
    if (state.additions[index].elections.length < state.additions[index].max) {
      election.quantity = 1;
      state.additions[index].elections.push(election);
    }
  }
};
export const setMoreElectionOfOption = (state, { election, index }) => {
  if (state.options[index].elections.includes(election)) {
    const idx = state.options[index].elections.indexOf(election);
    state.options[index].elections[idx].quantity++;
  } else {
    election.quantity = 1;
    state.options[index].elections.push(election);
  }
};
export const setLessElectionOfOption = (state, { election, index }) => {
  const elections = state.options[index].elections;
  if (elections.includes(election)) {
    const idx = elections.indexOf(election);
    if (elections[idx].quantity < 2) {
      state.options[index].elections.splice(idx, 1);
    } else {
      elections[idx].quantity--;
    }
  }
};
export const setMoreElectionOfAddition = (state, { election, index }) => {
  if (state.additions[index].elections.includes(election)) {
    const idx = state.additions[index].elections.indexOf(election);
    state.additions[index].elections[idx].quantity++;
  } else {
    election.quantity = 1;
    state.additions[index].elections.push(election);
  }
};
export const setLessElectionOfAddition = (state, { election, index }) => {
  const elections = state.additions[index].elections;
  if (elections.includes(election)) {
    const idx = elections.indexOf(election);
    if (elections[idx].quantity < 2) {
      state.additions[index].elections.splice(idx, 1);
    } else {
      elections[idx].quantity--;
    }
  }
};

export const resetProduct = (state) => {
  state.product = null;
  state.options = [];
  state.additions = [];
};
