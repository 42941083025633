import axios from "@/businessApi";

export const fetchTables = async ({ commit, rootState }) => {
  try {
    const response = await axios.get(
      `/r/${rootState.domainName}/v1/tables`,
      rootState.businessAuth
    );
    commit("setTables", response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createTables = ({ rootState }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/r/${rootState.domainName}/v1/set-tables`,

        payload,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
          reject(error);
        }
      );
  });
};

export const updateBranch = async ({ rootState }, payload) => {
  try {
    const response = await axios.put(
      `/r/${rootState.domainName}/v1/branches/${rootState.currentBranch.id}`,
      payload,
      rootState.businessAuth
    );
    return response;
  } catch (error) {
    return error;
  }
};
