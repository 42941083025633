const modals = {
  state: {
    regularUsers: [],
    currentUser: "",
    tutorialModalLoading: false,
  },
  mutations: {
    addRegularUser(state, payload) {
      let exist;
      state.tutorialModalLoading = true;
      if (state.regularUsers.length === 0) {
        state.regularUsers.push({
          userId: payload,
          modals: [
            { modalId: 0, viewed: false },
            { modalId: 1, viewed: false },
            { modalId: 2, viewed: false },
          ],
        });
      } else {
        for (let i = 0; i < state.regularUsers.length; i++) {
          const element = state.regularUsers[i];
          if (element.userId === payload) {
            exist = true;
          }
        }
        if (!exist) {
          state.regularUsers.push({
            userId: payload,
            modals: [
              { modalId: 0, viewed: false },
              { modalId: 1, viewed: false },
              { modalId: 2, viewed: false },
            ],
          });
        }
      }
      this.commit("setCurrentUser", payload);
    },
    setCurrentUser(state, payload) {
      for (let index = 0; index < state.regularUsers.length; index++) {
        const user = state.regularUsers[index];
        if (user.userId === payload) {
          state.currentUser = user;
        }
      }
      state.tutorialModalLoading = false;
    },
    setViewedModal(state, payload) {
      for (let index = 0; index < state.currentUser.modals.length; index++) {
        const element = state.currentUser.modals[index];
        if (element.modalId === payload) {
          state.currentUser.modals[index].viewed = true;
        }
      }
    },
  },
  actions: {},
  getters: {
    getCurrentUser(state) {
      return state.currentUser;
    },
    getTutorialModalLoading(state) {
      return state.tutorialModalLoading;
    },
  },
};

export default modals;
