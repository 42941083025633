<template>
  <div class="create-user">
    <p>Usuarios > Crear usuario</p>
    <div class="container-boxes">
      <div class="data">
        <h3>DATOS</h3>
        <div class="inputs">
          <div class="input">
            <label>Nombre</label>
            <input v-model="first_name" type="text" />
            <div class="warning-text" v-if="warn_name">
              Este campo no puede estar vacio
            </div>
          </div>
          <div class="input">
            <label>Apellido</label>
            <input v-model="last_name" type="text" />
          </div>
        </div>
        <div class="input">
          <label for="">Correo</label>
          <input v-model="email" type="email" placeholder="Ingresa un correo" />
          <small v-if="warn_email.length > 0"> {{ warn_email }} </small>
        </div>
        <div class="input">
          <label for="">Usuario</label>
          <input
            v-model="username"
            type="text"
            placeholder="Nombre de usuario"
          />
          <small v-if="warn_username.length > 0"> {{ warn_username }} </small>
        </div>
        <div class="input">
          <label for="">Contraseña</label>
          <input
            v-model="password"
            :type="hidePassword ? 'password' : 'text'"
            placeholder=""
          />
          <password-verify :password="password" />
          <button class="button-input" @click="togglePassword">
            <img src="@/assets/icons/hide-password.svg" alt="icon" />
          </button>
        </div>
        <div class="input">
          <label for="">Rol</label>
          <nelkir-select :options="groups" :toggleSelect="toggleSelectGroup" />
        </div>
        <div class="check-boxes">
          <h3>Sucursales</h3>
          <div v-if="branches.length > 0">
            <list-radio-check :elements="branches" :action="setBranches" />
          </div>
          <div v-else>*Debe registrar un sucursal primero*</div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button class="delete">Eliminar usuario</button>
      <div>
        <button class="tertiary-button" @click="toUsers">Cancelar</button>
        <button
          class="primary-button"
          @click="createUser"
          :disabled="disableButton"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import PasswordVerify from "@/components/PasswordVerify.vue";
import ListRadioCheck from "@/components/tools/ListRadioCheck.vue";
import NelkirSelect from "@/components/tools/NelkirSelect.vue";
import { mapGetters } from "vuex";
export default {
  name: "CreateUser",
  components: {
    PasswordVerify,
    ListRadioCheck,
    NelkirSelect,
  },
  props: {
    toUsers: {
      type: Function,
      required: true,
    },
    fetchGroups: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      password: "",
      hidePassword: true,
      group: "",
      branches: "",
      branch: [],
      permissions: "",
      warn_username: "",
      warn_email: "",
    };
  },
  computed: {
    disableButton() {
      return (
        this.first_name.length == 0 ||
        this.last_name.length == 0 ||
        this.email.length == 0 ||
        this.username.length == 0 ||
        this.password.length == 0 ||
        this.group.length == 0 ||
        this.branch.length == 0
      );
    },
    ...mapGetters({
      groups: "getVisibleGroups",
    }),
  },
  methods: {
    toggleSelectGroup(payload) {
      this.group = payload.id;
    },
    getBranches() {
      this.$store.dispatch("fetchBranches").then((response) => {
        if (response.status == 200) {
          this.branches = response.data;
        }
      });
    },
    setBranches(payload) {
      const finded = this.branches.find((element) => element.id == payload);

      if (this.branch.includes(finded)) {
        const index = this.branch.indexOf(finded);
        if (index > -1) {
          this.branch.splice(index, 1);
        }
      } else {
        this.branch.push(finded.id);
      }
    },
    setPermissions() {
      this.$store.dispatch("getPermissions").then((response) => {
        this.permissions = response.data;
      });
    },
    togglePassword() {
      this.hidePassword = !this.hidePassword;
    },
    getInfo() {
      return {
        username: this.username,
        password: this.password,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        branches: this.branch,
        groups: [this.group],
      };
    },
    createUser() {
      this.$store
        .dispatch("businessRequest", {
          action: "createUser",
          data: this.getInfo(),
        })
        .then((response) => {
          if (response.status == 201) {
            this.fetchGroups();
            this.toUsers();
            this.$store.commit("setPopUpMessage", "Nuevo usuario agregado :)");
          } else {
            this.$store.commit("setPopUpMessage", "Algo salio mal :(");
            const error = response.data;
            const events1 = { username: false };
            const key1 = "username";
            if (error.hasOwnProperty.call(events1, key1)) {
              this.warn_username =
                "El nombre de usuario ya se encuentra en uso";
            }
            const events2 = { email: false };
            const key2 = "email";
            if (error.hasOwnProperty.call(events2, key2)) {
              this.warn_email = "El email es invalido o ya se encuentra en uso";
            }
          }
        });
    },
  },
  mounted() {
    this.getBranches();
    this.setPermissions();
  },
};
</script>
<style scoped>
small {
  color: #ff6b68;
}
p {
  color: #4f4f4f;
}
.create-user p {
  color: #828282;
}
.container-boxes {
  display: flex;
  margin-top: 15px;
}
.data {
  width: 452px;
  padding: 18px 26px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.permissions {
  width: 572px;
  margin-left: 24px;
  padding: 24px 28px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.box-body {
  max-height: 675px;
  overflow: auto;
}
h3 {
  color: #828282;
}
.inputs {
  display: flex;
  justify-content: space-between;
}
.inputs input {
  width: auto;
}
.input {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  position: relative;
}
label {
  font-weight: 700;
  color: #4f4f4f;
  margin-bottom: 6px;
}
.check-boxes {
  margin-top: 18px;
}
.check-boxes h3 {
  margin-top: 16px;
  color: #4f4f4f;
}
.buttons {
  margin-top: 19px;
  display: flex;
}
.primary-button {
  width: 166px;
  height: 45px;
}
.tertiary-button {
  width: 166px;
  height: 45px;
}
.delete {
  background-color: transparent;
  color: #fd4a46;
  border: none;
  visibility: hidden;
}
.button-input {
  position: absolute;
  right: 13px;
  top: 40px;
  background-color: transparent;
  border: none;
}
@media screen and (max-width: 1439px) {
  .data {
    width: 446px;
  }
  .permissions {
    width: 540px;
  }
}
@media screen and (max-width: 1359px) {
  .data {
    width: 395px;
  }
  .permissions {
    width: 395px;
  }
  .inputs {
    justify-content: space-between;
  }
  .inputs .input {
    width: 158px;
  }
  .buttons {
    flex-direction: column-reverse;
  }
  .delete {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .container-boxes {
    flex-direction: column;
  }
  .data {
    width: 280px;
  }
  .inputs {
    flex-direction: column;
  }
  .inputs .input {
    width: auto;
  }
  .inputs input {
    width: auto;
  }
  .permissions {
    width: 280px;
    margin: 18px 0 0 0;
  }
  .box-body {
    max-height: fit-content;
  }
  .buttons button {
    width: 48%;
  }
  .delete {
    width: auto !important;
  }
}
</style>
