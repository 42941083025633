<template>
  <div
    class="modal fade"
    id="modalBill"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div v-if="render" class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Nombre de empresa</h3>
          <p>NIT 0000000</p>
          <p>Dirección</p>
          <div class="d-flex">
            <p>Ciudad</p>
            &nbsp; | &nbsp;
            <p>Tlf: 000000</p>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="order-info">
            <p>Fecha: {{ today }}</p>
            <div class="t-head">
              <p><strong>Producto</strong></p>
              <p><strong>Cant.</strong></p>
              <p class="text-end"><strong>Valor</strong></p>
            </div>
            <div class="list-container">
              <div v-for="product in order.order_products" :key="product.id">
                <product-bill :product="product" />
              </div>
            </div>
            <hr />
            <p><strong>SUBTOTAL</strong></p>
            <div class="d-flex justify-content-between">
              <p>Impuesto consumo 8%</p>
              <p>000</p>
            </div>
            <div class="d-flex justify-content-between">
              <p>Propina 10%</p>
              <p>1000</p>
            </div>
            <div class="total-price">
              <strong
                >TOTAL: ${{
                  new Intl.NumberFormat("es-CO").format(totalWithDiscount)
                }}</strong
              >
            </div>
            <div
              class="d-flex flex-column jusfity-content-center align-items-center"
            >
              <p>Resolusión DIAN: 000000000/0000</p>
              <p>de {{ today }} del No. 1 al 1000000</p>
              <p>Tipo de Régimen</p>
              <div
                class="d-flex flex-column jusfity-content-center align-items-center mt-4"
              >
                <p>Realizado por: Nelkir</p>
                <div
                  class="d-flex jusfity-content-between align-items-center mt-2 mb-3"
                >
                  <p>www.nelkir.com</p>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <p>NIT. 10201041</p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="primary-button"
              data-bs-dismiss="modal"
            >
              Imprimir
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductBill from "./ProductOfBill.vue";
export default {
  name: "ModalBill",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    ProductBill,
  },
  data() {
    return {
      perquisite: 0,
      render: false,
      today: "",
    };
  },
  computed: {
    totalPrice() {
      let orderPrice = 0;
      for (let index = 0; index < this.order.order_products.length; index++) {
        const product = this.order.order_products[index];
        let totalPrice =
          product.product_price.price * (1 + product.product_price.iva);
        for (
          let index = 0;
          index < product.order_option_election.length;
          index++
        ) {
          const element = product.order_option_election[index].price;
          totalPrice = totalPrice + element;
        }
        for (let index = 0; index < product.order_addition.length; index++) {
          const element = product.order_addition[index].price;
          totalPrice = totalPrice + element;
        }
        orderPrice = orderPrice + totalPrice * product.quantity;
      }
      return orderPrice;
    },
    totalWithDiscount() {
      const total =
        this.totalPrice +
        Number(this.perquisite) -
        this.discountValue -
        this.cuponValue;
      return total;
    },
  },
  methods: {
    renderContent() {
      this.render = true;
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      this.today = `${day}/${month + 1}/${year}`;
    },
    renderOff() {
      this.render = false;
    },
  },
  mounted() {
    const render = this.renderContent;
    const renderOff = this.renderOff;
    const myModalEl = document.querySelector("#modalBill");
    myModalEl.addEventListener("show.bs.modal", () => {
      render();
    });
    myModalEl.addEventListener("hidden.bs.modal", () => {
      renderOff();
    });
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 400px;
}
.modal-content {
  padding: 26px 34px;
}
.modal-header {
  border: none;
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.btn-close {
  position: absolute;
  top: 0;
  right: 0;
}
.modal-body {
  padding: 0;
  display: flex;
  flex-direction: column;
}
.t-head {
  display: grid;
  grid-template-columns: 180px 50px 100px;
}
.order-info {
  display: grid;
  gap: 16px;
}
.list-container {
  overflow: auto;
}
hr {
  margin: 0;
}
.total-price {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-top: 1px solid #e0e0e0;
  padding-top: 12px;
}
.billing-form {
  grid-area: right-a;
}
.billing-form section {
  margin-bottom: 14px;
}
.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
}
input {
  width: 100%;
  margin-top: 8px;
}
.cashReturn {
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}
.modal-footer {
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
}
h4 {
  color: #828282;
  text-align: start;
  margin-bottom: 12px;
}
.break-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 18px 0 14px;
}
.billing-form h4 {
  color: #484848;
}
.pay-button {
  width: 134px;
  height: 45px;
  background: #fbfbfb;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.active {
  box-shadow: none;
  border: 2px solid #e0e0e0;
  color: #fff;
  background-color: #222237;
}
.primary-button {
  width: 140px;
}
.transparent-button {
  width: 120px;
  background-color: transparent;
  border: none;
  height: 45px;
}
</style>
