<template>
  <button class="add-category">
    <img src="@/assets/icons/add-category.svg" alt="icon" />
    <h5>Agregar categoría</h5>
  </button>
</template>
<script>
export default {};
</script>
<style scoped>
.add-category {
  margin-top: 16px;
  margin-right: 30px;
  width: 369px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 5px;
  color: #02c874;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
h3 {
  color: var(--primary);
}
.add-category img {
  margin-right: 12px;
}
@media screen and (max-width: 1439px) {
  .add-category {
    width: 352px;
  }
}
@media screen and (max-width: 1359px) {
  .add-category {
    width: 325px;
  }
}
@media screen and (max-width: 1023px) {
  .add-category {
    width: 280px;
    margin-right: 0;
  }
}
</style>
