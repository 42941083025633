<template>
  <div class="currency-input">
    <strong>{{ label }}</strong>
    <div class="input">
      <p class="currency">$</p>
      <input
        type="number"
        placeholder="0"
        v-model="inputValue"
        @input="$emit('customChange', inputValue)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "CurrencyInput",
  props: {
    label: {
      type: String,
      default: "Ingrese un monto",
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      inputValue: 0,
    };
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
<style scoped>
.currency-input {
  text-align: start;
}
.input {
  display: flex;
  position: relative;
}
.currency {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  padding-left: 15px;
  padding-top: 8px;
  left: 0;
}
input {
  padding-left: 28px;
  width: 100%;
  margin-top: 8px;
}
</style>
