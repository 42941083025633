<template>
  <div class="subButton">
    <button
      :class="active ? 'dp-element active' : 'dp-element'"
      @click="setButton()"
    >
      {{ subButton.name }}
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SidebarSubButton",
  props: {
    subButton: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentButton: "getCurrentSidebarBranchButton",
    }),
    active() {
      return this.currentButton.id == this.subButton.id;
    },
  },
  methods: {
    setButton() {
      this.$store.commit("setCurrentSidebarBranchButton", this.subButton);
    },
  },
};
</script>
<style scoped>
button {
  height: 56px;
  width: 100%;
  color: #828282;
  display: flex;
  align-items: center;
  padding-left: 34px;
  border: none;
  background-color: transparent;
}
.active {
  background-color: rgba(2, 200, 116, 0.08);
  color: #02c874;
  font-weight: 700;
  padding-left: 66px;
}
</style>
