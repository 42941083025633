export default {
  common: {
    edit: "Edit",
    delete: "Delete",
    details: "Details",
    save: "Save",
    options: "Options",
    cancel: "Cancel",
    price: "Price",
    back: "Back",
    next: "Next",
    saveChanges: "Save Changes",
    settings: "Settings",
    description: "Description",
    close: "Close",
    total: "Total",
    admin: "Administrator",
    continueWithoutSaving: "Exit without saving.",
    cancelChanges: "Cancel",
    home: "Home",
    requiredField: "Required Field",
    analitycs: "Analytics",
  },
  pages: {
    login: {
      logIn: "Log in",
      user: "User",
      password: "Password",
      forgotPassword: "Forgot password",
    },
    sedes: {
      locations: "Branches",
      branchesInfo:
        "The branches are the different locations that your establishment has.",
    },
    menu: {
      menu: "Menu",
      addMenuGroup: "Add group",
      menuName: "Menu name",
      menuGroup: "Parent Menu",
      information: "Information",
      categoriesAndProducts: "Categories and products",
      categoryName: "Category name",
      schedules: "Schedules",
      deleteCategory: "Delete ",
      deteleOption: "Delete option",
      photo: "Picture",
      productName: "Product Name",
      stock: "Stock",
      discount: "Discount",
      finalPrice: "Final price",
      preparationPlace: "Preparation place",
      kitchen: "Kitchen",
      bar: "Bar",
      productDelete: "Delete product",
      uploadPhoto: "Upload a photo of your product.",
      uploadImageCategory: "Upload an image for your category.",
      optionsDescription:
        "Add options so your customers can choose how they want their product.",
      addNewOption: "Add option",
      nameOption: "Option name",
      newOption: "New option",
      createOption: "Create option",
      addCategory: "Add category",
      addProduct: "Add product",
      categoriesDescription:
        "Add categories and products to start seeing information about your menu. If you want us to create your menu for you, contact us at",
      noOptions: "This product has no options.",
      editOption: "Edit option",
      selectItem: "An item must be selected",
      limitSelectItem: "Selection limit",
      limitReSelectItem: "Reselection limit",
      nameItem: "Item name",
      addItem: "Add item",
      viewItem: "Add items to display them here.",
      shareLink: "Share link",
      addNewCategory: "Add new category",
      always: "Always",
      sometimes: "Sometimes",
      value: "Value",
      percentage: "Percentage",
      available: "Available",
      outOfStock: "Out of stock",
      personalization: "Personalization",
      mainColor: "Main color",
      characters: "Max. 80 characters",
      alwaysAvailable: "Always available",
      availability: "Availability",
      questionDeleteCategory:
        "If you delete this category, you won't be able to restore it.",
      questionDeleteProduct:
        " Are you sure you want to delete this product? This change cannot be undone.",
      questionDeleteItem: "Are you sure you want to delete this item?",
      questionDeleteOption: "Are you sure you want to delete this option?",
      end: "End",
      start: "Start",
      createSchedules: "Create schedules",
      noSchedules: "You haven't created schedules yet",
      addNew: "Add new",
      addExisting: "Add existing",
      categoryDescription: "Category description",
      editCategory: "Edit category",
      descriptionCategoriesSchedules:
        "Categories can have schedules so that your menu shows whats available to order.",
      editSchedule: "Edit schedule",
      exitWithoutSaving: "Exit without saving?",
      descriptionExit:
        "When you exit without saving, you will lose the unsaved changes.",
    },
    home: {
      sessions: "Sessions",
      help: "Help",
      checkOut: "Check-out",
      statistics: "Statistics",
      orders: " Orders",
      totalSessions: "Total sessions",
      totalOrders: "Total orders",
      totalRequestBills: "Total requested bills",
      totalWaiterCalls: "Total waiter calls",
      graphs: "Graphs",
      mostClicked: "Most clicked",
      leastClicked: "Less clicked",
      date: "Date",
      totalCredit: "Total credit",
      totalDebit: "Total debit",
      totalTransfers: "Total transfers",
      totalCash: "Total cash",
      totalOnline: " Total online",
      type: "Type",
      orderNo: " Order No.",
      invoiceNo: "Invoice No.",
      paymentMethod: "Payment method",
      tip: " Tip",
      least30Days: "Last 30 days",
      sale: "Sale",
      wifi: "Enter wifi network",
      qr: "QR Menu",
      reviews: "Reviews",
      website: "Website",
    },
    schedule: {
      today: "Today",
      yesterday: "Yesterday",
      last7days: "Last 7 days",
      last30days: "Last 30 days",
      last90days: "Last 90 days",
      last180days: "Last 6 months",
      last365days: "Last year",
      dayMondayLetter: "M",
      dayTuesdayLetter: "T",
      dayWednesdayLetter: "W",
      dayThursdayLetter: "T",
      dayFridayLetter: "F",
      daySaturdayLetter: "S",
      daySundayLetter: "S",
      dayMonday: "Monday",
      dayTuesday: "Tuesday",
      dayWednesday: "Wednesday",
      dayThursday: "Thursday",
      dayFriday: "Friday",
      daySaturday: "Saturday",
      daySunday: "Sunday",
    },
    notifications: {
      CategoryCreatedNotif: "Category created",
      ErrorCreateCategoryNotif: "Error creating category",
      CategoryDeletedNotif: "Category deleted ",
      CategoryImgDeletedNotif: "Category image deleted. ",
      ErrorDeletedCategoryNotif: "Error deleting category",
      ErrorDeletedCategoryImgNotif: "Error deleting category image",
      CategoryEditedNotif: "Category edited",
      ErrorCategoryEditedNotif: "Error editing category",
      ProductEditedNotif: "Product edited",
      ErrorProductEditedNotif: "Error editing product",
      ProductDeletedNotif: "Product deleted",
      ErrorProductDeletedNotif: "Error deleting product",
      ProductCreatedNotif: "Product created",
      ErrorCreateProductNotif: "Error creating product",
      SomethingWrong: "Something went wrong",
      OptionCreated: "Option created",
      DeleteOption: "Option deleted",
      UpdateOption: "Option updated",
      DeleteAddition: "Addition deleted",
      AdditionCreated: "Addition created",
      UpdateAddition: "Addition updated",
    },

    notificationsDashboard: {
      pending: "Pending",
      attended: "Attended",
      notifications: "Notifications",
      noPending:
        "You're up to date! There are no pending notifications at the moment.",
      noAttended: "There are no attended notifications at the moment.",
      deleteNotificationsAttended:
        "Attended notifications are deleted every day at 04:00 a.m.",
      table: "Table",
      request: "Requested help at",
      newOrder: "New order",
      readyDelivery: "Ready for delivery",
      waiter: " Help request",
      bill: "Bill requested",
      delayed: "Delayed",
    },
    analitycs: {
      marketing: "Marketing Analytics",
      dashboard: "Dashboard",
      subscribers: "Suscribers",
    },
  },
};
