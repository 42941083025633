<template>
  <div class="product-bill">
    <div class="product-row">
      <p>
        {{ product.product_name }}
      </p>
      <p>
        {{ product.quantity }}
      </p>
      <p class="text-end">
        ${{
          new Intl.NumberFormat("es-CO").format(
            product.product_price.price * (1 + product.product_price.iva)
          )
        }}
      </p>
    </div>
    <div class="food-details">
      <div class="elections">
        <div v-for="addition in additions" :key="addition.id">
          <div class="d-flex justify-content-between">
            <p>{{ addition.name }} x{{ addition.quantity }}</p>
            <p v-if="addition.price > 0">
              ${{ new Intl.NumberFormat("es-CO").format(addition.price) }}
            </p>
          </div>
        </div>
        <div v-for="option in options" :key="option.id">
          <div class="d-flex justify-content-between">
            <p>{{ option.name }} x{{ option.quantity }}</p>
            <p v-if="option.price > 0">
              ${{ new Intl.NumberFormat("es-CO").format(option.price) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductBill",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      additions: [],
      options: [],
    };
  },
  methods: {
    setAdditions() {
      for (let index = 0; index < this.product.order_addition.length; index++) {
        const element = this.product.order_addition[index];
        const finded = this.additions.find((el) => el.id == element.id);
        if (finded) {
          const findedIndex = this.additions.indexOf(finded);
          this.additions[findedIndex].quantity++;
          this.additions[findedIndex].price =
            this.additions[findedIndex].price + element.price;
        } else {
          this.additions.push({
            name: element.name,
            id: element.id,
            quantity: 1,
            price: element.price,
          });
        }
      }
    },
    setOptions() {
      for (
        let index = 0;
        index < this.product.order_option_election.length;
        index++
      ) {
        const element = this.product.order_option_election[index];
        const finded = this.options.find((el) => el.id == element.id);
        if (finded) {
          const findedIndex = this.options.indexOf(finded);
          this.options[findedIndex].quantity++;
        } else {
          this.options.push({
            name: element.name,
            id: element.id,
            quantity: 1,
          });
        }
      }
    },
  },
  mounted() {
    this.setAdditions();
    this.setOptions();
  },
};
</script>
<style scoped>
.product-bill {
  display: grid;
  row-gap: 12px;
  padding-right: 4px;
}
.product-row {
  display: grid;
  grid-template-columns: 180px 50px 100px;
}
.elections {
  display: grid;
  row-gap: 10px;
}
.elections p {
  font-size: 14px;
}
</style>
