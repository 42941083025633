<template>
  <header>
    <figure class="logo-container">
      <q-img src="@/assets/images/payfudnavbar.png" width="80px" />
    </figure>
    <div :class="businessInfo ? 'business-name' : 'd-none'">
      <!-- <h3>{{ businessName }}</h3> -->
    </div>
    <nav>
      <q-btn-dropdown
        class="dp-btn"
        flat
        color="primary"
        text-color="dark"
        no-caps
        :ripple="false"
        dropdown-icon="expand_more"
      >
        <template v-slot:label>
          <div class="row items-center no-wrap">
            <q-avatar class="q-mr-sm" icon="o_account_circle" size="md" />
            <div class="text-center">Usuario</div>
          </div>
        </template>
        <q-list>
          <q-item v-if="inBusiness" clickable v-close-popup @click="toConfig">
            <q-item-section>
              <q-item-label>Configuración</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="logout">
            <q-item-section>
              <q-item-label>Cerrar sesión</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </nav>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "HeaderClient",
  props: {
    showSidebar: {
      type: Function,
      required: true,
    },
    businessInfo: {
      type: Boolean,
      required: true,
    },
    shortcutBranches: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dropdown: false,
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    inBusiness() {
      return this.$route.params.business != null;
    },
    ...mapGetters({
      isAdmin: "getAdmin",
      businessName: "getCurrentBusiness",
      branches: "getBranches",
      business: "getDomainName",
    }),
  },
  methods: {
    toggleDropdown() {
      this.dropdown = !this.dropdown;
    },
    toBusiness() {
      this.$router.push("/businesses");
    },
    toConfig() {
      this.$router.push("/config");
    },
    logout() {
      this.$store
        .dispatch("appRequest", {
          action: "logout",
        })
        .then((response) => {
          if (response.status == 205) {
            this.$store.commit("logout");
            this.$router.push("/");
          }
        });
    },
    toBranch(payload) {
      this.$store.commit("setCurrentBranch", payload);
      this.$router.push({
        name: "branch-view",
        params: { business: this.business, branchId: payload.id },
      });
    },
  },
};
</script>
<style scoped>
header {
  height: 76px;
  width: 100%;
  background: #ffffff;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray);
}
.logo-container {
  width: 250px;
  min-width: 250px;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.business-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
nav {
  display: flex;
  justify-content: space-between;
}
.shortcutBranches {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.user-options {
  height: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
  border: none;
  background-color: transparent;
  align-items: center;
  max-height: 40px;
}
.user-name {
  align-self: center;
}
.arrow-button {
  margin-left: 10px;
}
/* button {
  display: none;
} */
.nelkir-dropdown {
  width: 132px;
  height: 109px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 99;
  background: #faf9f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
}
.nelkir-dropdown::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 14px solid #faf9f9;
  bottom: 100%;
  right: 3px;
}
.nelkir-dropdown button {
  display: block;
  background-color: transparent;
  border: none;
  width: 120px;
  height: 45px;
  color: #828282;
  border-radius: 5px;
  text-align: start;
  padding-left: 7px;
}
.nelkir-dropdown button:hover {
  background-color: #f2f2f2;
  color: #333333;
}
.history-button {
  display: block;
  width: 138px;
  background-color: #f2f2f2;
  height: 45px;
  border: none;
  border-radius: 60px;
  margin-right: 20px;
}
@media screen and (max-width: 1439px) {
  .logo-container {
    width: 240px;
  }
}
@media screen and (max-width: 1359px) {
  .logo-container {
    width: 192px;
  }
}
@media screen and (max-width: 1024px) {
  .user-options {
    display: none;
  }
  button {
    display: block;
    border: none;
    background-color: transparent;
  }
  .business-name {
    display: none;
  }
  .shortcutBranches {
    display: none;
  }
}
</style>
