export default () => ({
  subscribers: [],
  eventDetail: [],
  totalTransfer: 0,
  totalCash: 0,
  totalCard: 0,
  total: 0,
  todayOrders: [],
  todayTip: 0,
  totalDebit: 0,
  totalCredit: 0,
  comparisionTotalBillCalls: {},
  comparisionTotalWaiterCalls: {},
  comparisionTotalSessions: {},
  totalBillCalls: {},
  totalWaiterCalls: {},
  totalSessions: {},
  setMostClicked: 0,
  setLeastClicked: 0,
  googleAnalitycs: {},
});
