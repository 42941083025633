<template>
  <div class="text-input">
    <strong>{{ label }}</strong>
    <input type="text" @input="$emit('customChange', $event.target.value)" />
  </div>
</template>
<script>
export default {
  name: "TextInput",
  props: {
    label: {
      type: String,
      default: "Ingrese un texto",
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.text-input {
  text-align: start;
}
input {
  margin-top: 8px;
  width: 100%;
}
</style>
