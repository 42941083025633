export const getMenuTime = (state) => {
  return state.menuTime;
};
export const getCategories = (state) => {
  return state.categories;
};
export const getCategoriesWithProducts = (state) => {
  return state.categoriesWithProducts;
};
export const getIsFiltered = (state) => {
  return state.isFiltered;
};
export const getProductsFiltered = (state) => {
  return state.productsFiltered;
};
export const getOnlyProducts = (state) => {
  return state.onlyProducts;
};
export const getCurrentCategory = (state) => {
  return state.currentCategory;
};
