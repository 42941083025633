<template>
  <section class="schedules">
    <div class="schedules-list">
      <div>
        <h6>HORARIOS</h6>
        <div v-if="schedules.length == 0">
          <p>Aún no has creado horarios</p>
        </div>
        <div v-else v-for="(schedule, index) in schedules" :key="index">
          <p>{{ schedule }}</p>
          <div class="buttons">
            <button>
              <img
                class="schedule-button"
                src="@/assets/icons/edit-dark.svg"
                alt="edit-icon"
              />
            </button>
            <button @click="deleteSchedule(index)">
              <img
                class="schedule-button"
                src="@/assets/icons/trash-dark.svg"
                alt="delete-icon"
              />
            </button>
          </div>
        </div>
      </div>
      <button data-bs-toggle="modal" data-bs-target="#createScheduleModal">
        <img src="@/assets/icons/plus-green.svg" alt="plus-icon" /> Agregar
        horario
      </button>
    </div>
    <modal-create-schedule :createSchedule="createSchedule" />
  </section>
</template>
<script>
// import ModalCreateSchedule from "./ModalCreateSchedule.vue";
export default {
  name: "schedules-tab",
  components: {
    // ModalCreateSchedule,
  },
  data() {
    return {
      schedules: [],
    };
  },
  methods: {
    createSchedule(payload) {
      this.schedules.push(payload.schedule);
    },
  },
};
</script>
<style scoped>
.schedules-list {
  width: 438px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 28px 22px 24px 28px;
  text-align: start;
}
h6 {
  color: #828282;
}
button {
  background-color: transparent;
  border: none;
  color: #02c874;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
p {
  margin-top: 12px;
}
button img {
  margin-right: 10px;
}
.buttons {
  display: flex;
}
.schedules-button {
  border: none;
  background-color: transparent;
  margin: 0;
}
@media screen and (max-width: 1439px) {
  .schedules-list {
    width: 438px;
  }
}
@media screen and (max-width: 1359px) {
  .schedules-list {
    width: 393px;
  }
}
@media screen and (max-width: 1023px) {
  .schedules-list {
    width: 100%;
  }
}
</style>
