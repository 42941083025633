<template>
  <div class="login">
    <img class="logo" src="@/assets/images/payfudnavbar.png" alt="" />

    <div class="login--container">
      <img
        class="logo-responsive"
        src="@/assets/images/payfud-logo.png"
        alt=""
      />
      <h2>{{ $t("pages.login.logIn") }}</h2>
      <div v-if="typeUser == 1" class="input">
        <label>Empresa</label>
        <input class="fw" type="text" v-model="business" />
      </div>
      <div v-if="errorBusiness.length > 0" class="error-message">
        <img class="" src="@/assets/icons/error.svg" />
        <div>{{ errorBusiness }}</div>
      </div>
      <q-form class="input" @submit="login">
        <div class="input">
          <label>{{ $t("pages.login.user") }}</label>
          <q-input class="full-width" outlined v-model="username" />
        </div>
        <div class="input">
          <div class="password">
            <label>{{ $t("pages.login.password") }}</label>
          </div>
          <q-input
            class="full-width"
            v-model="password"
            outlined
            :type="isPwd ? 'password' : 'text'"
            :rules="[(val) => !!val || 'Campo requerido']"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </div>
        <div v-if="errorPassword.length > 0" class="error-message">
          <img class="" src="@/assets/icons/error.svg" />
          <div>{{ errorPassword }}</div>
        </div>
        <div v-if="errorCredentials.length > 0" class="error-message">
          <img class="" src="@/assets/icons/error.svg" />
          <div>{{ errorCredentials }}</div>
        </div>
        <q-btn
          class="full-width q-mt-lg q-mb-lg"
          unelevated
          color="primary"
          :label="$t('pages.login.logIn')"
          no-caps
          :disable="disableButton"
          type="submit"
        />
      </q-form>
      <p class="full-width">{{ $t("pages.login.password") }}</p>
      <div class="locale-changer">
        <select v-model="$i18n.locale">
          <option
            v-for="locale in $i18n.availableLocales"
            :key="`locale-${locale}`"
            :value="locale"
          >
            {{ locale }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login-view",
  data() {
    return {
      business: "",
      username: "",
      password: "",
      typeUser: 0,
      errorUsername: "",
      errorPassword: "",
      errorCredentials: "",
      errorBusiness: "",
      isPwd: true,
    };
  },
  computed: {
    disableButton() {
      return this.username.length == 0 || this.password.length == 0;
    },
  },
  methods: {
    getInfo() {
      return {
        username: this.username,
        password: this.password,
      };
    },
    refreshError() {
      this.errorUsername = "";
      this.errorPassword = "";
      this.errorCredentials = "";
    },
    login() {
      this.refreshError();
      if (this.typeUser == 1) {
        this.$store
          .dispatch("checkBusiness", this.business)
          .then((response) => {
            if (response.status == 200) {
              this.loginEmployee();
            } else {
              this.errorBusiness =
                "No es posible encontrar un restaurante con ese nombre";
            }
          });
      } else {
        this.$store.dispatch("login", this.getInfo()).then((response) => {
          switch (response.status) {
            case 200:
              this.toDashboard();
              break;
            case 400:
              this.error400();
              break;
            case 401:
              this.errorCredentials = "Usuario o Contraseña invalido";
              break;
            default:
              break;
          }
        });
      }
    },
    loginEmployee() {
      this.$store.dispatch("loginEmployee", this.getInfo()).then((response) => {
        switch (response.status) {
          case 200:
            this.toDashboard();
            break;
          case 400:
            this.error400();
            break;
          case 401:
            this.errorCredentials = "Usuario o Contraseña invalido";
            break;

          default:
            break;
        }
      });
    },
    error400() {
      if (this.username.length == 0 && this.password.length == 0) {
        this.errorUsername = "Debe llenar este campo";
        this.errorPassword = "Debe llenar este campo";
      } else if (this.password.length > 0) {
        this.errorUsername = "Debe llenar este campo";
      } else {
        this.errorPassword = "Debe llenar este campo";
      }
    },
    setOwnerUser() {
      this.typeUser = 0;
    },
    setEmployeeUser() {
      this.typeUser = 1;
    },
    toRegister() {
      this.$router.push("/register");
    },
    toDashboard() {
      this.fetchBusinesses();
    },
    async fetchBusinesses() {
      let response = await this.$store.dispatch("appRequest", {
        action: "fetchBusinesses",
      });

      if (response.status == 200) {
        this.loading = false;
        let location = response.data[0].location;

        if (location === "col") {
          this.$i18n.locale = "es";
          this.$router.push({ name: "businesses" }); //  Colombia
        } else if (location === "us") {
          this.$i18n.locale = "en";
          this.$router.push({ name: "branches-lite" }); // Belongs to USA
        }
      }
    },
  },
  created() {
    this.$store.dispatch("clearPersistedState");
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
input {
  margin: 5px 0 0 0;
}
a {
  margin-bottom: 36px;
}
.login {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login--container {
  width: 411px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}
.logo,
.logo-responsive {
  position: absolute;
  width: 116px;
  top: 47px;
  left: 75px;
}
.logo-responsive {
  display: none;
}

.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: 16px;
}
.input label {
  font-weight: 700;
}

.password {
  display: flex;
  justify-content: space-between;
}
.password .link {
  display: block;
}

.error-message {
  margin-top: 5px;
  color: #ff6b68;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  text-align: start;
}
.error-message img {
  margin-right: 10px;
}
@media screen and (max-width: 1439px) {
  .login--container {
    width: 314px;
  }
  .password .link {
    display: none;
  }
}
@media screen and (max-width: 1359px) {
  .logo {
    left: 52px;
  }
}
@media screen and (max-width: 1200px) {
  figure img {
    height: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .s-img {
    width: auto;
  }
}
@media screen and (max-width: 768px) {
  h2 {
    margin: 0 0 16px;
  }
  .login-area {
    width: 100%;
    min-width: auto;
    padding: 0 20px;
  }
  .login--container {
    width: 425px;
  }
  figure {
    width: 0;
  }
  .type-user button {
    width: 48%;
  }
}
@media screen and (max-width: 425px) {
  .login--container {
    width: 280px;
  }
  .logo {
    display: none;
  }
  .logo-responsive {
    display: block;
    top: -66px;
    left: 0;
  }
}
</style>
