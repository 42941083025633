<template>
  <div class="table-item">
    <div class="table-inputs">
      <input
        class="first"
        type="text"
        placeholder="Nombre ingrediente"
        v-model="name"
        @input="setOptionItem"
      />
      <div class="position-relative">
        <input
          class="second"
          type="number"
          placeholder="Precio"
          v-model="price"
          @input="setOptionItem"
        />
        <p class="money">$</p>
      </div>
    </div>
    <div class="third">
      <button @click="deleteThis">
        <img src="@/assets/icons/trash-red.svg" alt="" />
      </button>
      <div
        :class="isActive ? 'handler active' : 'handler'"
        @click="toggleActive"
      >
        <div class="dot"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    setNewOptionItem: {
      type: Function,
      required: true,
    },
    deleteItemList: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    election: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      price: 0,
      isActive: true,
    };
  },
  computed: {
    checkFields() {
      return this.name.length > 0;
    },
  },
  methods: {
    setOptionItem() {
      this.setNewOptionItem({
        data: {
          name: this.name,
          price: this.price,
          is_active: this.isActive,
        },
        id: this.id,
        fields: this.checkFields,
      });
    },
    deleteThis() {
      this.deleteItemList(this.id);
    },
    toggleActive() {
      this.isActive = !this.isActive;
    },
  },
  // mounted() {
  //   this.name = this.election.name;
  //   this.price = this.election.price;
  //   this.setOptionItem();
  // }
};
</script>
<style scoped>
.table-item {
  display: flex;
  padding: 0;
}
.table-inputs {
  display: flex;
  padding: 0;
}
.first {
  width: 235px;
  margin-right: 22px;
  margin-bottom: 12px;
}
.second {
  width: 97px;
  margin-right: 10px;
  padding-left: 20px;
}
.money {
  position: absolute;
  top: 11px;
  left: 7px;
}
.third {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}
.third button {
  background-color: transparent;
  border: none;
  padding: 0;
}
.third img {
  margin-right: 10px;
}
.handler {
  width: 34px;
  height: 20px;
  background-color: gray;
  border-radius: 80px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 3px;
}
.active {
  background-color: #02c874;
  justify-content: flex-end;
  border: none;
}
.dot {
  height: 14px;
  width: 14px;
  background: #fff;
  border-radius: 100%;
}
@media screen and (max-width: 1359px) {
  .third img {
    margin-right: 0;
  }
}
@media screen and (max-width: 1023px) {
  .table-inputs {
    flex-direction: column;
  }
  .first,
  .second {
    width: 176px;
    margin-right: 10px;
  }
  .third {
    flex-direction: column;
    justify-content: space-between;
    width: 64px;
    height: 100px;
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 10px;
  }
}
</style>
