<template>
  <button
    :class="
      currentProduct.id == product.id
        ? 'product-item active-product'
        : 'product-item'
    "
  >
    <div class="d-flex">
      <img src="@/assets/icons/category-menu-icon-dark.svg" alt="" />
      <p v-text="product.name"></p>
    </div>
  </button>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ProductItem",
  props: {
    product: {
      type: Object,
      required: true,
      validator: function (value) {
        return (
          ["syncing", "synced", "version-conflict", "error"].indexOf(value) !==
          -1
        );
      },
    },
  },
  data() {
    return {
      handler: true,
    };
  },
  computed: {
    ...mapGetters({
      currentProduct: "getCurrentProduct",
    }),
  },
  methods: {
    toggle() {
      this.handler = !this.handler;
    },
  },
};
</script>
<style scoped>
.product-item {
  width: 370px;
  height: 58px;
  background: #fdfdfd;
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-top: none;
  border-bottom: none;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 20px;
  padding-right: 8px;
}
.d-flex {
  display: flex;
}
.active-product {
  background-color: rgba(2, 200, 117, 0.08);
}
.product-item img {
  margin-right: 18px;
}
@media screen and (max-width: 1439px) {
  .product-item {
    width: 352px;
  }
}
@media screen and (max-width: 1359px) {
  .product-item {
    width: 325px;
  }
}
@media screen and (max-width: 1023px) {
  .product-item {
    width: 280px;
  }
}
</style>
