<template>
  <div
    class="modal fade"
    id="modalNewOrder"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div v-if="render" class="modal-content">
        <div class="modal-body">
          <div class="header">
            <h3 class="modal-title" id="staticBackdropLabel">Crear Pedido</h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <section>
            <h4>Tipo de pedido</h4>
            <nelkir-radio-check
              :elements="options"
              :action="setOption"
              :initialState="1"
              :list="false"
            />
          </section>
          <section v-if="currentOption == 1">
            <nelkir-select-v2
              :label="'Número de mesa'"
              :options="tables"
              :select="setTable"
              :uniqueId="'selectTable'"
            />
          </section>
          <div class="footer">
            <nelkir-button @click="action" :disable="disableButton"
              >Continuar</nelkir-button
            >
            <nelkir-button
              data-bs-dismiss="modal"
              :style="2"
              @click="action"
              :disable="disableButton"
              >Cancelar</nelkir-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NelkirRadioCheck from "@/components/tools/NelkirRadioCheck";
import NelkirSelectV2 from "@/components/tools/NelkirSelectV2";
import NelkirButton from "@/components/tools/buttons/NelkirButton";
import { mapGetters } from "vuex";
export default {
  name: "ModalNewOrder",
  components: {
    NelkirRadioCheck,
    NelkirSelectV2,
    NelkirButton,
  },
  data() {
    return {
      options: [
        { name: "Comer aquí", id: 1 },
        { name: "Para llevar", id: 2 },
      ],
      render: false,
      currentOption: null,
      currentTable: null,
    };
  },
  computed: {
    ...mapGetters({
      tables: "getTables",
      domainName: "getDomainName",
      currentBranch: "getCurrentBranch",
    }),
    disableButton() {
      let disableValue = true;
      if (this.currentOption == 1) {
        disableValue = !this.currentOption || !this.currentTable;
      } else {
        disableValue = !this.currentOption;
      }
      return disableValue;
    },
  },
  methods: {
    renderContent() {
      this.render = true;
    },
    renderOff() {
      this.render = false;
    },
    action() {
      this.$store.commit("setCurrentTable", this.currentTable);
      window.$("#modalNewOrder").modal("hide");
      this.toMenu();
    },
    setOption(payload) {
      this.currentOption = payload;
    },
    setTable(payload) {
      this.currentTable = payload;
    },
    toMenu() {
      this.$router.push({
        name: "menu-view",
        params: {
          business: this.domainName,
          branchId: this.currentBranch.id,
        },
      });
    },
  },
  mounted() {
    const render = this.renderContent;
    const renderOff = this.renderOff;
    const myModalEl = document.querySelector("#modalNewOrder");
    myModalEl.addEventListener("show.bs.modal", () => {
      render();
    });
    myModalEl.addEventListener("hidden.bs.modal", () => {
      renderOff();
    });
  },
};
</script>
<style scoped>
.modal-dialog {
  justify-content: center;
}
.modal-content {
  max-width: 350px;
}
section {
  margin-top: 22px;
  text-align: start;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 12px;
}
.footer {
  margin-top: 12px;
  display: grid;
  row-gap: 12px;
}
</style>
