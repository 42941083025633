export default {
  common: {
    edit: "Editar",
    delete: "Eliminar",
    details: "Detalles",
    save: "Guardar",
    options: "Opciones",
    cancel: "Cancelar",
    price: "Precio",
    back: "Atrás",
    next: "Siguiente",
    saveChanges: "Guardar cambios",
    settings: "Configuraciones",
    description: "Descripción",
    close: "Cerrar",
    total: "Total",
    admin: "Administrador/a",
    continueWithoutSaving: "Salir sin guardar",
    cancelChanges: "Cancelar",
    home: "Inicio",
    requiredField: "Campo requerido",
    analitycs: "Análisis",
  },

  pages: {
    graph: {
      Monday: "lunes",
      Tuesday: "martes",
      Wednesday: "miércoles",
      Thursday: "jueves",
      Friday: "viernes",
      Saturday: "sábado",
      Sunday: "domingo",
      January: "enero",
      February: "febrero",
      March: "marzo",
      April: "abril",
      May: "mayo",
      June: "junio",
      July: "julio",
      August: "agosto",
      September: "septiembre",
      October: "octubre",
      November: "noviembre",
      December: "diciembre",
    },
    schedule: {
      today: "Hoy",
      yesterday: "Ayer",
      last7days: "Últimos 7 días",
      last30days: "Últimos 30 días",
      last90days: "Últimos 90 días",
      last180days: "Últimos 6 meses",
      last365days: "Último año",
      dayMondayLetter: "L",
      dayTuesdayLetter: "M",
      dayWednesdayLetter: "M",
      dayThursdayLetter: "J",
      dayFridayLetter: "V",
      daySaturdayLetter: "S",
      daySundayLetter: "D",
      dayMonday: "Lunes",
      dayTuesday: "Martes",
      dayWednesday: "Miercoles",
      dayThursday: "Jueves",
      dayFriday: "Viernes",
      daySaturday: "Sabado",
      daySunday: "Domingo",
    },
    notifications: {
      CategoriaCreadaNotif: "Categoría creada",
      ErrorCrearCategoriaNotif: "Error al crear categoría",
      CategoriaEliminadaNotif: "Categoría eliminada",
      CategoryImgDeletedNotif: "Imagen de categoría eliminada.",
      ErrorDeletedCategoryImgNotif: "Error al eliminar imagen de categoría.",
      ErrorEliminadoCategoriaNotif: "Error al eliminar categoría",
      CategoriaEditadaNotif: "Categoría editada",
      ErrorCategoriaEditadaNotif: "Error al editar la categoría",
      ProductoEditadoNotif: "Producto editado",
      ErrorProductoEditadoNotif: "Error al editar producto ",
      ProductoEliminadoNotif: "Producto eliminado",
      ErrorProductoEliminadoNotif: "Error al eliminar producto ",
      ProductoCreadoNotif: "Producto creado",
      ErrorCrearProductodoNotif: "Error al crear producto ",
      SomethingWrong: "Algo salió mal",
      OptionCreated: "Opción creada",
      DeleteOption: "Opción eliminada",
      UpdateOption: "Opción actualizada",
      DeleteAdition: "Adición eliminada",
      AditionCreated: "Adición creada",
      UpdateAdition: "Adición actualizada",
    },
    login: {
      logIn: "Iniciar Sesión",
      user: "Usuario",
      password: "Contraseña",
      forgotPassword: "Olvidé contraseña",
    },
    sedes: {
      locations: "Sedes",
      branchesInfo:
        "Las sedes son las diferentes ubicaciones con las cuales cuenta tu establecimiento",
    },
    menu: {
      menu: " Menú",
      addMenuGroup: "Añadir grupo",
      menuName: "Nombre menú",
      menuGroup: "Menú Principal",
      information: "Información",
      categoriesAndProducts: "Categorías y productos",
      categoryName: "Nombre categoría",
      schedules: "Horarios",
      deleteCategory: "Eliminar ",
      deteleOption: "Eliminar opción",
      photo: "Foto",
      productName: "Nombre de producto",
      stock: "Inventario",
      discount: "Descuento",
      finalPrice: "Precio final",
      preparationPlace: "Lugar de preparación",
      kitchen: "Cocina",
      bar: "Bar",
      productDelete: "Eliminar producto",
      uploadPhoto: "Sube una foto de tu producto",
      uploadImageCategory: "Sube una imagen para tu categoría",
      optionsDescription:
        "Añade opciones para que tus clientes puedan elegir cómo desean su producto",
      addNewOption: "Agregar opción",
      nameOption: "Nombre opción",
      newOption: "Nueva opción",
      createOption: "Crear opción",
      addCategory: "Agregar categoria",
      addProduct: "Agregar producto",
      categoriesDescription:
        "Agrega categorías y productos para comenzar a visualizar información sobre tu menú. Si quieres que creemos tu menú por ti contáctanos a ",
      noOptions: "Este producto no tiene opciones.",
      editOption: "Editar opción",
      selectItem: "Se debe elegir un item",
      limitSelectItem: "Limite selección",
      limitReSelectItem: "Limite reselección",
      nameItem: "Nombre ítem",
      addItem: "Agrega item",
      viewItem: "Agrega items para visualizarlos aquí",
      shareLink: "Compartir link",
      addNewCategory: "Añadir nueva categoría",
      always: "Siempre",
      sometimes: "Algunas veces",
      value: "Valor",
      percentage: "Porcentaje",
      available: "Disponible",
      outOfStock: "Agotado",
      personalization: "Personalización",
      mainColor: "Color principal",
      characters: "Máx. 80 carácteres",
      alwaysAvailable: "Siempre disponible",
      availability: "Disponibilidad",
      questionDeleteCategory:
        "Si eliminas esta categoría no podrás volver a reestablecerla.",
      questionDeleteProduct:
        " ¿Seguro que desea eliminar este producto? Este cambio no se puede deshacer",
      questionDeleteItem: "¿Seguro que deseas eliminar esta item?",
      questionDeleteOption: " ¿Seguro que desea eliminar esta opción?",
      end: "Final",
      start: "Inicio",
      createSchedules: "Crear horarios",
      noSchedules: "Aún no has creado horarios",
      addNew: "Agregar nueva",
      addExisting: "Agregar existente",
      categoryDescription: "Descripción categoría",
      editCategory: "Editar categoría",
      descriptionCategoriesSchedules:
        "Las categorías pueden tener horarios para que tu menú muestre lo que está disponible para ordenar.",
      editSchedule: "Editar horario",
      exitWithoutSaving: "¿Salir sin guardar?",
      descriptionExit:
        "Al salir sin guardar perderás los cambios no guardados.",
    },
    home: {
      sessions: "Sesiones",
      help: "Ayuda",
      checkOut: "Verificar",
      statistics: "Estadisticas",
      orders: "Pedidos",
      totalSessions: "Total sesiones",
      totalOrders: "Total pedidos",
      totalRequestBills: "Total cuentas solicitadas",
      totalWaiterCalls: "Total llamado de meseros",
      graphs: "Gráficos",
      mostClicked: "Más clickeados",
      leastClicked: "Menos clickeados",
      date: "Fecha",
      totalCredit: "Total Crédito",
      totalDebit: "Total Débito",
      totalTransfers: "Total transferencias",
      totalCash: "Total efectivo",
      totalOnline: "Total online",
      type: "Tipo",
      orderNo: "No.Pedido",
      paymentMethod: "Método de Pago",
      tip: "Propina",
      least30Days: "Últimos 30 días",
      sale: "Venta",
      qr: "QR Menú",
      reviews: "Reseñas",
      website: "Sitio web",
    },

    notificationsDashboard: {
      pending: "Pendientes",
      attended: "Atendidas",
      notifications: "Notificaciones",
      noPending:
        "¡Estás al día! No hay notificaciones pendientes en este momento.",
      noAttended: "No hay notificaciones atendidas en este momento.",
      deleteNotificationsAttended:
        "Las notificaciones atendidas se eliminan todos los días a las 04:00 a.m",
      table: "Mesa",
      request: "Solicitó ayuda a las",
      newOrder: "Nueva orden",
      readyDelivery: "Listo para entrega",
      waiter: " Mesero solicitado",
      bill: "Cuenta solicitada",
      delayed: "Atrasado",
    },

    analitycs: {
      marketing: "Análisis de marketing",
      dashboard: "Tablero",
      subscribers: "Suscriptores",
    },
  },

  restaurants: "Restaurantes",

  logOut: "Salir",
  locations: "Sedes",
  users: "Usuarios",
  locationDescription: "Descubre qué está sucediendo en tu sede",
  locationsDescription: "Descubre qué está sucediendo en tus sedes",

  dayMondayLetter: "L",
  dayTuesdayLetter: "M",
  dayWednesdayLetter: "M",
  dayThursdayLetter: "J",
  dayFridayLetter: "V",
  daySaturdayLetter: "S",
  daySundayLetter: "D",
  starts: "Inicia",
  finishes: "Finaliza",
  noSchedules: "Aún no has creado horarios",
  optionsNewGroup: "Nuevo grupo de opciones",
  optionsProductsDescription:
    "Las opciones se conforman por ítems que tu comensal puede o debe elegir al pedir este producto",
  optionExample: "Ej: Tipo de proteína",
  rules: "Reglas",
  items: "Ítems",
  basicInformation: "Información básica",
  payments: "Pagos",
  qrTables: "QR Mesas",
  generalInformation: "Datos generales",
  changeNameDescription:
    "Para cambiar los nombres del restaurante y sede debes comunicarte con soporte.",
  serviceType: "Tipo de servicio",
  immediatePayment: "Pago inmediato",
  payAtTheEnd: "Pago al final",
  address: "Dirección",
  state: "Departamento",
  city: "Ciudad",
  cash: "Efectivo",
  dataphone: "Datáfono",
  transfer: "Transferencia",
  qrData: "Datos QR",
  qrDescription:
    "El código QR debe ser ubicado en cada mesa de tu restaurante para controlar correctamente los pedidos de tus comensales.",
  wifiName: "Nombre WiFi",
  wifiPassword: "Contraseña WiFi",
  instructions: "Instrucciones",
  addQuantity: "Agregar la cantidad de mesas que hay en tu establecimiento",
  downloadPdfFile:
    "Descarga el archivo pdf que es generado automáticamente con la cantidad de mesas que seleccionaste",
  printSnipedLocate:
    "Imprime, recorta y ubica cada imagen de acuerdo al número de mesa",
  qrPreview: "Previsualizar QR",
  tablesTotal: "Total mesas",
  addTables: "Agregar mesas",
  table: "Mesá",
  downloadPdf: "Descargar PDF",
  tablesQuantity: "Número de mesas",

  card: "Tarjeta",
  total: "Total",
  news: "Nuevos",
  inProgress: "En progreso",
  delivered: "Entregados",
  searchTables: "Buscar mesas",
  createOrder: "Crear pedido",
  tableNum: "Mesa #",
  today: "Hoy",
  yesterday: "Ayer",
  orderInformation: "Información del pedido",
  noOrders: "There are no selected orders to display",
  newOrder: "Nuevo pedido",
  orderType: "Tipo de pedido",
  eatHere: "Comer aquí",
  takeOut: "Para llevar",
  tableNumber: "Número de mesa",
  continue: "Continuar",
  NewOrder: "NUEVA ORDEN",
  estimateTip: "Est. Propina",
  accept: "Aceptar",
  reject: "Rechazar",
  searchProduct: "Buscar producto",
  noSelectedProducts: "Aún no has seleccionado productos",
  kitchenTickets: "Comandas en cocina",
  closeComanda: "Cerrar comanda",
  page: "Página",
  userSettings: "Configuración de usuario",
  settingsDescription: "Encuentra tu información y preferencias aquí",
  personalInformatión: "Información personal",
  name: "Nombre",
  lastname: "Apellido",
  gender: "Género",
  selectGender: "Selecciona género",
  male: "Masculino",
  female: "Femenino",
  birthday: "Fecha de cumpleaños",
  selectDate: "Elige fecha",
  contact: "Contacto",
  email: "Correo electrónico",
  mobilePhone: "Teléfono móvil",
  country: "País",
  selectCountry: "Seleccione un país",
  selectState: "Selecciona un departamento",
  selectCity: "Selecciona una ciudad",
  language: "Idioma",
  selectLanguage: "Selecciona un idioma",
};
