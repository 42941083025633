<template>
  <div class="new-category-information">
    <div class="input">
      <label for="">Nombre de la categoría</label>
      <input class="radio__input" type="text" v-model="name" />
    </div>
    <div class="input">
      <radio-buttons :list="list" :toggle="toggleRadioCheck" />
    </div>
    <div v-if="!always_available">
      <div class="days">
        <div v-for="day in daysButtons" :key="day.id">
          <button
            :class="day.active ? 'day active' : 'day'"
            @click="selectDay(day.id)"
            v-text="day.text"
          ></button>
        </div>
      </div>
      <div class="inputs">
        <div class="input">
          <label for="">Inicio</label>
          <time-picker :saveTime="saveStartTime" />
        </div>
        <div class="separator">-</div>
        <div class="input">
          <label for="">Final</label>
          <time-picker :saveTime="saveFinalTime" />
        </div>
      </div>
      <button
        class="schedule"
        :disabled="disableCreateSchedule"
        @click="createSchedule"
      >
        Crear horario
      </button>
      <div>
        <h3>Horarios</h3>
        <div v-if="schedules.length == 0">
          <p>Aún no has creado horarios</p>
        </div>
        <div v-else v-for="(schedule, index) in schedules" :key="index">
          <p>{{ schedule }}</p>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button class="secondary-button" @click="cancel">Cancelar</button>
      <button
        class="primary-button"
        @click="create"
        :disabled="disableCreateCategory"
      >
        Aceptar
      </button>
    </div>
  </div>
</template>

<script>
import RadioButtons from "@/components/RadioButtons.vue";
import TimePicker from "@/components/tools/TimePicker.vue";
export default {
  name: "NewCategoryInformation",
  props: {
    cancel: {
      type: Function,
      required: true,
    },
    reload: {
      type: Function,
      required: true,
    },
  },
  components: {
    RadioButtons,
    TimePicker,
  },
  data() {
    return {
      daysButtons: [
        { text: "L", active: false, id: 0, value: "Lunes" },
        { text: "M", active: false, id: 1, value: "Martes" },
        { text: "M", active: false, id: 2, value: "Miercoles" },
        { text: "J", active: false, id: 3, value: "Jueves" },
        { text: "V", active: false, id: 4, value: "Viernes" },
        { text: "S", active: false, id: 5, value: "Sabado" },
        { text: "D", active: false, id: 6, value: "Domingo" },
      ],
      name: "",
      list: {
        title: "Horario",
        options: [
          {
            name: "Siempre",
            active: true,
            id: 0,
          },
          {
            name: "Algunas veces",
            active: false,
            id: 1,
          },
        ],
      },
      always_available: true,
      days: [],
      startTime: "",
      finalTime: "",
      startTimeServer: "",
      finalTimeServer: "",
      schedules: [],
      availability: [],
    };
  },
  computed: {
    disableCreateSchedule() {
      return (
        this.startTime.includes("-") ||
        this.startTime.length == 0 ||
        this.finalTime.includes("-") ||
        this.finalTime.length == 0 ||
        this.days.length == 0
      );
    },
    disableCreateCategory() {
      if (this.always_available) {
        return this.name.length == 0;
      }
      return (
        this.name.length == 0 ||
        this.disableCreateSchedule ||
        this.schedules.length == 0
      );
    },
  },
  methods: {
    toggleRadioCheck(payload) {
      for (let index = 0; index < this.list.options.length; index++) {
        const element = this.list.options[index].id;
        if (element == payload) {
          this.list.options[index].active = true;
        } else {
          this.list.options[index].active = false;
        }
      }
      if (payload == 0) {
        this.always_available = true;
      } else {
        this.always_available = false;
      }
    },
    selectDay(payload) {
      for (let index = 0; index < this.daysButtons.length; index++) {
        const element = this.daysButtons[index].id;
        if (element == payload) {
          this.daysButtons[index].active = !this.daysButtons[index].active;
          if (this.daysButtons[index].active) {
            this.days.push(this.daysButtons[index]);
          } else {
            this.days.splice(this.days.indexOf(this.daysButtons[index]), 1);
          }
        }
      }
    },
    saveStartTime(payload) {
      this.startTime = payload;
      this.startTimeServer = `${this.startTime.slice(0, -5)}:00`;
      if (this.startTime.includes("P.M.")) {
        const hours = parseInt(this.startTimeServer.slice(0, 2)) + 12;
        this.startTimeServer = hours + this.startTimeServer.slice(2);
      }
    },
    saveFinalTime(payload) {
      this.finalTime = payload;
      this.finalTimeServer = `${this.finalTime.slice(0, -5)}:00`;
      if (this.finalTime.includes("P.M.")) {
        const hours = parseInt(this.finalTimeServer.slice(0, 2)) + 12;
        this.finalTimeServer = hours + this.finalTimeServer.slice(2);
      }
    },
    createSchedule() {
      let days;
      for (let index = 0; index < this.days.length; index++) {
        days =
          index == 0
            ? this.days[index].value
            : `${days}, ${this.days[index].value}`;
      }
      this.schedules.push(`${days}: ${this.startTime} - ${this.finalTime}`);
      this.availability.push({
        days: this.days.map((day) => {
          return day.id;
        }),
        start_time: this.startTimeServer,
        final_time: this.finalTimeServer,
      });
    },
    getInfo() {
      if (this.always_available) {
        return {
          name: this.name,
          description: "alguna",
          status: true,
          always_available: this.always_available,
        };
      }
      return {
        name: this.name,
        description: "alguna",
        status: true,
        always_available: this.always_available,
        availability: this.availability,
      };
    },
    create() {
      this.$store
        .dispatch("businessRequest", {
          action: "createCategory",
          data: this.getInfo(),
        })
        .then((response) => {
          if (response.status == 201) {
            this.cancel();
            this.reload();
          }
        });
    },
  },
};
</script>

<style scoped>
.new-category-information {
  margin-top: 16px;
  width: 396px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 5px;
  padding: 26px 22px;
}
.inputs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputs input {
  width: 119px;
}
.separator {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
.input input {
  margin-top: 7px;
}
.radio {
  display: flex;
  align-items: center;
}
label {
  font-weight: 700;
}
.days {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 16px;
}
.day {
  height: 32.5px;
  width: 32.5px;
  background: #f2f2f2;
  border-radius: 100%;
  border: none;
}
.active {
  background-color: transparent;
  border: 1px solid #02c874;
  color: #02c874;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
.buttons button {
  width: 132px;
  height: 45px;
}
.secondary-button {
  background-color: transparent;
  border: none;
}
p {
  width: 280px;
  margin-top: 8px;
  line-height: 150%;
}
.schedule {
  width: 100%;
  height: 45px;
  background-color: #fff;
  border: 1px solid #02c874;
  color: #02c874;
  border-radius: 5px;
  margin: 16px 0;
}
.schedule:disabled {
  border: 1px solid #828282;
  color: #828282;
}
@media screen and (max-width: 1359px) {
  .new-category-information {
    width: 465px;
    height: 537px;
  }
  p {
    width: auto;
  }
  .inputs input {
    width: 165px;
  }
  .separator {
    width: 87px;
  }
}
@media screen and (max-width: 1359px) {
  .new-category-information {
    width: 280px;
    height: 572px;
  }
  .separator {
    width: 20px;
    color: transparent;
  }
  .inputs input {
    width: 119px;
  }
  .buttons button {
    width: 112px;
  }
}
</style>
