<template>
  <button class="new-employee">
    <img src="@/assets/icons/add-shop.svg" alt="" />
    <h3>Crear usuario</h3>
  </button>
</template>
<script>
export default {
  name: "NewEmployee",
};
</script>
<style scoped>
.new-employee {
  width: 370px;
  height: 128px;
  background-color: #fff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 10px;
  margin: 0 20px 20px 0;
}
@media screen and (max-width: 1439px) {
  .new-employee {
    width: 349px;
  }
}
@media screen and (max-width: 1359px) {
  .new-employee {
    width: 393px;
    height: 128px;
  }
}
@media screen and (max-width: 1023px) {
  .new-employee {
    width: 280px;
    height: 128px;
  }
}
</style>
