import api from "@/api";
import axios from "axios";

const tables = {
  state: {
    tables: [],
  },

  mutations: {
    setTables(state, rootState, payload) {
      const branch = rootState.currentBranch.id;
      state.tables = payload.data.filter((table) => table.branch === branch);
    },
  },

  actions: {
    fetchTables({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.domainName}/v1/tables`,
            rootState.businessAuth
          )
          .then(
            (response) => {
              commit("setTables", response);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    createTables({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${api}/r/${rootState.domainName}/v1/set-tables`,
            payload,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
              commit("setTables", response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    updateTable({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${api}/r/${rootState.domainName}/v1/set-tables?id=${payload.id}`,
            payload,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
              commit("setTables", response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
  },

  getters: {
    getTables(state) {
      return state.tables;
    },
  },
};

export default tables;
