<template>
  <div class="comanda">
    <header>
      <h3>MESA #{{ order.table_name }}</h3>
      <div class="row">
        <q-icon name="watch"></q-icon>
        <h3>{{ time }}</h3>
      </div>
    </header>
    <div class="comanda-body">
      <div v-for="(product, index) in order.order_products" :key="product.id">
        <product-order
          :product="product"
          :setReady="setReadyProduct"
          :remove="removeReadyProduct"
        />
        <hr v-if="index + 1 < order.order_products.length" />
      </div>
    </div>
    <footer>
      <button
        class="primary-button"
        :disabled="!allProducts"
        @click="finishOrder"
      >
        Terminar orden
      </button>
    </footer>
  </div>
</template>
<script>
import ProductOrder from "./ProductOrder.vue";
export default {
  name: "kitchen-comanda",
  components: {
    ProductOrder,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    fetchOrders: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      ready: [],
      time: "00:00",
    };
  },
  computed: {
    allProducts() {
      return this.ready.length == this.order.order_products.length;
    },
  },
  methods: {
    setReadyProduct(payload) {
      this.ready.push(payload);
    },
    removeReadyProduct(payload) {
      const index = this.ready.indexOf(payload);
      this.ready.splice(index, 1);
    },
    setTime() {
      let cycle = "A.M.";
      let hour = Number(this.order.created_at.substring(11, 13));
      const min = Number(this.order.created_at.substring(14, 16));
      if (hour == 0) {
        hour = 12;
      }
      if (hour > 12) {
        hour = hour - 12;
        cycle = "P.M.";
      }
      this.time = `${hour}:${min} ${cycle}`;
    },
    finishOrder() {
      this.$store
        .dispatch("businessRequest", {
          action: "finishOrder",
          data: {
            id: this.order.id,
            order_status: 3,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.fetchOrders();
          }
        });
    },
  },
  mounted() {
    this.setTime();
  },
};
</script>
<style scoped>
.comanda {
  height: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
header {
  height: 54px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
h3 {
  color: #4f4f4f;
}
hr {
  margin: 0;
}
.comanda-body {
  padding: 0 15px;
  height: calc(100% - 130px);
  overflow: hidden;
}
footer {
  height: 75px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary-button {
  height: 54px;
  width: 240px;
}
@media screen and (max-width: 1439px) {
  .comanda {
    width: 258px;
  }
}
@media screen and (max-width: 1359px) {
  .comanda {
    width: 255px;
  }
}
@media screen and (max-width: 767px) {
  .comanda {
    width: 100%;
    margin: 0;
  }
  .primary-button {
    width: 90%;
  }
}
</style>
