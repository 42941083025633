<template>
  <div>
    <div>
      <label v-text="list.title"></label>
    </div>
    <div class="list">
      <div v-for="option in list.options" :key="option.name">
        <div class="radio">
          <button
            :class="
              option.active == true ? 'radio-button checked' : 'radio-button'
            "
            @click="toggle(option.id)"
          >
            <div v-if="option.active" class="fill"></div></button
          ><span v-text="option.name"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioButtons",
  props: {
    list: {
      type: Object,
      required: true,
    },
    toggle: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
.list {
  display: flex;
}
.radio {
  display: flex;
  align-items: flex-start;
  margin-right: 16px;
}
.radio button {
  margin-right: 6px;
}
.radio-button {
  height: 22px;
  width: 22px;
  min-width: 22px;
  border: 1px solid #939393;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checked {
  border: 1px solid #02c874;
}
.fill {
  height: 16.5px;
  width: 16.5px;
  background-color: #02c874;
  border-radius: 100%;
}
label {
  font-weight: 700;
  margin-bottom: 8px;
}
@media screen and (max-width: 767px) {
  .list {
    display: block;
  }
  .radio {
    margin-bottom: 10px;
  }
}
</style>
