<template>
  <div class="Business-Details">
    <button class="header" @click="toBusiness">
      <img src="@/assets/icons/back-arrow.svg" alt="" />
      <h3>{{ businessName }}</h3>
    </button>
    <div class="tab">
      <div class="tab-container">
        <div v-for="button in buttons" :key="button">
          <button
            :class="button.active ? 'tab-button active' : 'tab-button'"
            v-text="button.text"
            @click="toggleButton(button.id)"
          ></button>
        </div>
      </div>
      <div class="tab-line"></div>
    </div>
    <branches-view v-if="currentView == 0" />
    <menu-view v-if="currentView == 1" />
    <teleport to="#modalMenu">
      <modal-menu v-if="modalMenu" :close="closeModalMenu" />
    </teleport>
  </div>
</template>
<script>
import BranchesView from "@/views/businesses/BranchesView.vue";
import MenuView from "@/views/businesses/MenuView.vue";
import ModalMenu from "@/tutorialModals/ModalMenu.vue";
export default {
  components: {
    ModalMenu,
    BranchesView,
    MenuView,
  },
  data() {
    return {
      buttons: [
        { text: "Sucursales", active: true, id: 0 },
        { text: "Menú", active: false, id: 1 },
        // { text: "Impuestos", active: false, id: 2 }
      ],
      modalCreateBranch: false,
      modalMenu: false,
      currentView: 0,
    };
  },
  computed: {
    businessName() {
      return this.$store.getters.getCurrentBusiness;
    },
  },
  methods: {
    toggleButton(payload) {
      for (let index = 0; index < this.buttons.length; index++) {
        if (index == payload) {
          this.buttons[index].active = true;
          this.openModal(payload);
          this.currentView = payload;
        } else {
          this.buttons[index].active = false;
        }
      }
    },
    openModal(id) {
      switch (id) {
        case 0:
          break;
        case 1:
          this.modalMenu = true;
          break;
        case 2:
          break;

        default:
          break;
      }
    },
    openModalCreateBranch() {
      this.modalCreateBranch = true;
    },
    closeModalCreateBranch() {
      this.modalCreateBranch = false;
    },
    openModalMenu() {
      this.modalMenu = true;
    },
    closeModalMenu() {
      this.modalMenu = false;
    },
    toBusiness() {
      this.$store.dispatch("businessRequest", {
        action: "businessLogout",
      });
      this.$router.push({ name: "Businesses" });
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  margin-bottom: 18px;
  border: none;
  background-color: transparent;
}
.header img {
  margin-right: 14px;
}
.tab {
  width: 100%;
  position: relative;
  background-color: transparent;
  display: flex;
  height: 40px;
}
.tab-container {
  display: flex;
  position: absolute;
  z-index: 2;
}
.tab-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #dddddd;
  bottom: 0;
  z-index: 1;
}
.tab-button {
  height: 40px;
  background-color: #f8f8f8;
  padding: 0 15px;
  border: 1px solid #dddddd;
  border-radius: 2px 2px 0px 0px;
  margin-right: 3px;
  font-size: 14px;
}
.active {
  background-color: #fff;
  border-bottom: 1px solid #fff;
}
.business-list {
  display: flex;
  margin-top: 17px;
}
.business {
  margin-right: 30px;
}
@media screen and (max-width: 1023px) {
  .business {
    margin-right: 12px;
  }
}
</style>
