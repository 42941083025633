<template>
  <div class="account q-mt-xl">
    <h4>Configuración de usuario</h4>
    <p class="text-grey">Encuentra tu información y preferencias aquí.</p>

    <h5 class="text-grey q-mt-md">Datos personales</h5>
    <div class="inputs">
      <div class="input">
        <label for="">Nombre</label>
        <q-input class="full-width" v-model="name" outlined />
      </div>
      <div class="input">
        <label for="">Apellido</label>
        <q-input class="full-width" v-model="lastname" outlined />
      </div>
    </div>
    <div class="inputs">
      <div class="input">
        <label for="">Usuario</label>
        <q-input class="full-width" v-model="username" outlined disable />
      </div>
      <!-- <div class="input">
        <label for="">Fecha de cumpleaños</label>
        <q-input type="date" class="input" outlined />
      </div> -->
    </div>
    <q-separator class="q-my-lg" />
    <h5 class="text-grey">Contacto</h5>
    <div class="inputs">
      <div class="input">
        <label for="">Correo</label>
        <q-input class="input" v-model="email" outlined />
      </div>
      <!-- <div class="input">
        <q-input class="input" v-model="phone" outlined />
      </div> -->
      <div class="input">
        <label for="">Teléfono</label>
        <div class="phone-input">
          <q-select
            class="country code"
            borderless
            v-model="model"
            :options="options"
          >
            <template v-slot:prepend>
              <q-avatar v-if="model != null" class="q-ml-md" size="xs">
                <img :src="model.icon" />
              </q-avatar>
            </template>
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps">
                <q-item-section avatar>
                  <q-avatar size="xs">
                    <img :src="scope.opt.icon" />
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ scope.opt.label }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-select>
          <q-input
            class="q-mb-none phone"
            input-style="padding: 0 16px;"
            v-model="phone"
            mask="###-###-####"
            unmasked-value
            borderless
          />
        </div>
      </div>
    </div>
    <div class="inputs">
      <div class="input">
        <label for="">País</label>
        <q-select
          style="width: 100%"
          outlined
          placeholder="Ej. Cra 21 No. 1 - 24"
          v-model="city"
          option-value="id"
          option-label="name"
          :options="getCities"
          dense
        />
      </div>
      <div class="input">
        <label for="">Departamento</label>
        <q-select
          style="width: 100%"
          outlined
          placeholder="Ej. Cra 21 No. 1 - 24"
          v-model="city"
          option-value="id"
          option-label="name"
          :options="getCities"
          dense
        />
      </div>
    </div>
    <div class="inputs">
      <div class="input">
        <label for="">Ciudad</label>
        <q-select
          style="width: 100%"
          outlined
          placeholder="Ej. Cra 21 No. 1 - 24"
          v-model="city"
          option-value="id"
          option-label="name"
          :options="getCities"
          dense
        />
      </div>
      <div class="input">
        <label for="">Idioma</label>
        <q-select
          style="width: 100%"
          outlined
          placeholder="Ej. Cra 21 No. 1 - 24"
          v-model="city"
          option-value="id"
          option-label="name"
          :options="getCities"
          dense
        />
      </div>
    </div>
    <div class="row">
      <q-btn outline color="primary" @click="back" label="Volver" no-caps />
      <q-space />
      <q-btn outline color="primary" label="Cancelar" no-caps />
      <q-btn
        class="q-ml-md"
        unelevated
        color="primary"
        label="Guardar"
        no-caps
      />
    </div>
  </div>
</template>
<script>
import colombianBall from "@/assets/icons/colombia.svg";
import usaBall from "@/assets/icons/usa.png";

import { mapGetters } from "vuex";
export default {
  name: "account-view",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      lastname: "",
      username: "",
      email: "",
      phone: "",
      country: "",
      city: "",
      department: "",
      language: "",
      model: null,
      options: [
        { label: "+57", value: "$", icon: colombianBall },
        { label: "+1", value: "$", icon: usaBall },
      ],
    };
  },
  watch: {
    user() {
      this.name = this.user.first_name;
      this.lastname = this.user.last_name;
      this.email = this.user.email;
      this.phone = this.user.phone_number;
      this.username = this.user.username;
    },
  },
  computed: {
    ...mapGetters({
      business: "getDomainName",
    }),
  },
  methods: {
    back() {
      this.$router.push({
        name: "branches",
        params: { business: this.business },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.account {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: fit-content;
}
.inputs {
  display: flex;
  margin-bottom: 10px;
  .input {
    width: 277px;
  }
}
.phone-input {
  display: grid;
  grid-template-columns: 96px 1fr;
  padding: 0;

  .q-input {
    margin-bottom: 0;
  }
}
.code {
  :before {
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 2px 0 0 2px;
  }
}
.phone {
  :before {
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    border-left: none;
  }
}
.country {
  border-right: 1px solid var(--gray);
  max-height: 43px;
  margin-bottom: 0;
}
@media screen and (max-width: 1023px) {
}
</style>
