<template>
  <div class="modal-nelkir">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <h3>Nuevo ingrediente</h3>
          <input type="text" placeholder="Busca un ingrediente" />
          <input type="text" />
          <div class="inputs">
            <input class="input1" type="number" />
            <select name="" id="">
              <option value="">uno</option>
              <option value="">dos</option>
            </select>
          </div>
          <div class="buttons">
            <button class="secondary-button">Cancelar</button>
            <button class="primary-button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalAddIngredient",
};
</script>
<style scoped>
.modal-body {
  width: 280px;
  height: 274px;
  text-align: start;
}
h3 {
  margin-bottom: 12px;
}
input {
  margin-bottom: 10px;
  width: 100%;
}
.inputs {
  display: flex;
  justify-content: space-between;
}
.input1 {
  width: 78px;
}
select {
  width: 170px;
}
.buttons button {
  width: 124px;
}
.secondary-button {
  background-color: transparent;
  border: none;
}
</style>
