const popup = {
  state: {
    popUpMessage: "",
    deleteAction: "",
    deleteData: "",
    deleteInfo: "",
    toastMessage: "",
  },
  mutations: {
    setPopUpMessage(state, payload) {
      state.popUpMessage = payload;
      setTimeout(() => {
        state.popUpMessage = "";
      }, 5000);
    },
    setDeleteData(state, payload) {
      state.deleteAction = payload.deleteAction;
      state.deleteData = payload.deleteData;
      state.deleteInfo = payload.deleteInfo;
    },
    setToastMessage(state, payload) {
      state.toastMessage = payload;
    },
  },
  actions: {},
  getters: {
    getPopUpMessage(state) {
      return state.popUpMessage;
    },
    getDeleteAction(state) {
      return state.deleteAction;
    },
    getDeleteData(state) {
      return state.deleteData;
    },
    getDeleteInfo(state) {
      return state.deleteInfo;
    },
    getToastMessage(state) {
      return state.toastMessage;
    },
  },
};

export default popup;
