import api from "@/api";
import axios from "axios";

const branches = {
  state: {
    branches: "",
    currentBranchInfo: null,
    updateInfo: {},
    // DUMMY
    dummy: {
      name: "dummy",
      icon: "",
      iconActive: "",
      id: 999,
    },
  },
  mutations: {
    setBranches(state, payload) {
      state.branches = payload.data;
    },
    setCurrentBranchInfo(state, payload) {
      state.currentBranchInfo = payload.data;
    },
  },
  actions: {
    fetchUserBranches({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.domainName}/v1/user-branches`,
            rootState.auth
          )
          .then(
            (response) => {
              commit("setBranches", response);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    fetchLiteBranches({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.businesses.businesses[0].domain_name}/v1/branches`,
            rootState.auth
          )
          .then(
            (response) => {
              commit("setBranches", response);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    fetchBranches({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.domainName}/v1/branches`,
            rootState.businessAuth
          )
          .then(
            (response) => {
              commit("setBranches", response);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    createBranch({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${api}/r/${rootState.domainName}/v1/branches`,
            payload,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    fetchBranchInfo({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.domainName}/v1/branches/${rootState.currentBranch.id}`,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
              commit("setCurrentBranchInfo", response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    updateBranch({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${api}/r/${rootState.domainName}/v1/branches/${rootState.currentBranch.id}`,
            payload,
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
              commit("setCurrentBranchInfo", response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
  },
  getters: {
    getBranches(state) {
      return state.branches;
    },
    getCurrentSidebarBranchButton(state) {
      return state.currentSidebarBranchButton;
    },
    getCurrentSidebarBranchDropdownButton(state) {
      return state.currentSidebarBranchDropdownButton;
    },
    getSidebaBranchButtons(state) {
      return state.sidebarBranchButtons;
    },
    getCurrentBranchInfo(state) {
      return state.currentBranchInfo;
    },
    getUpdateInfo(state) {
      return state.updateInfo;
    },
    getExistsChanges(state) {
      return state.existsChanges;
    },
  },
};

export default branches;
