<template>
  <button :class="btnStyle.style" :disabled="disable">
    <span v-if="isLoading">Cargando...</span>
    <p v-else-if="text">
      {{ text }}
    </p>
    <p v-else>
      <slot></slot>
    </p>
  </button>
</template>
<script>
export default {
  name: "PrimaryButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    style: {
      type: Number,
      default: 0,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styles: [
        { style: "primary-button", id: 0 },
        { style: "secondary-button", id: 1 },
        { style: "tertiary-button", id: 2 },
        { style: "alert-button", id: 3 },
        { style: "alert-white", id: 4 },
      ],
    };
  },
  computed: {
    btnStyle() {
      const currentStyle = this.styles[this.style];
      return currentStyle;
    },
  },
  methods: {
    onclick() {
      this.$emit("click");
    },
  },
};
</script>
<style scoped>
button {
  height: 40px;
  padding: 0 36px;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px;
  padding: 0;
  transition: ease 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary-button {
  background-color: #02c874;
  color: #fdfdfd;
  border: none;
  border-radius: 5px;
}
.primary-button p {
  color: #fff;
}
.primary-button:hover {
  background-color: #109a5f;
}
.primary-button:focus {
  border: 2px solid #03ab64;
}
.primary-button:disabled {
  background-color: #cdcdcd;
  color: #828282;
  font-weight: 400;
}

.secondary-button {
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
}
.secondary-button p {
  color: #000;
}
.secondary-button:hover {
  background-color: #109a5f;
}
.secondary-button:focus {
  border: 2px solid #03ab64;
}
.secondary-button:disabled {
  background-color: #e0e0e0;
  color: #828282;
}

.tertiary-button {
  background-color: transparent;
  color: #4f4f4f;
  border: none;
  height: 45px;
  font-weight: 700;
}
.tertiary-button:hover {
  text-decoration: underline;
}
.tertiary-button:focus {
  background-color: #02c874;
  text-decoration: underline;
}
.tertiary-button:disabled {
  color: #bdbdbd;
}
.alert-button {
  background-color: #cb3734;
  color: #fff;
  border-radius: 5px;
  border: none;
  height: 45px;
}
.alert-button p {
  color: #fff;
}
.alert-button:hover {
  background: #a8201e;
}
.alert-button:focus {
  background-color: #cb3734;
  border: 2px solid #b62320;
}
.alert-white {
  background-color: transparent;
  border-radius: 5px;
  border: none;
  height: 45px;
}
.alert-white p {
  color: #cb3734;
}
/* .alert-white:hover {
  background: #A8201E;
} */
.alert-white:focus {
  border: 2px solid #b62320;
}
</style>
