export const setTotal = (state, payload) => {
  state.total = payload.data;
};
export const setTotalCard = (state, payload) => {
  state.totalCard = payload.data;
};
export const setTotalTransfer = (state, payload) => {
  state.totalTransfer = payload.data;
};
export const setTotalCash = (state, payload) => {
  state.totalCash = payload.data;
};
export const setTodayOrders = (state, payload) => {
  state.todayOrders = payload.data;
};
export const setTodayTip = (state, payload) => {
  state.todayTip = payload.data;
};
export const setTotalDebit = (state, payload) => {
  state.totalDebit = payload.data;
};
export const setTotalCredit = (state, payload) => {
  state.totalCredit = payload.data;
};
export const setTotalBillCalls = (state, payload) => {
  state.totalBillCalls = payload.data;
};
export const setTotalAnalytics = (state, payload) => {
  state.totalAnalytics = payload.data;
};
export const setTotalWaiterCalls = (state, payload) => {
  state.totalWaiterCalls = payload.data;
};
export const setTotalSessions = (state, payload) => {
  state.totalSessions = payload.data;
};
export const setMostClicked = (state, payload) => {
  state.mostClicked = payload.data;
};
export const setLeastClicked = (state, payload) => {
  state.leastClicked = payload.data;
};
export const setSubscribersData = (state, payload) => {
  state.subscribers = payload.data;
};
export const setEventsDetail = (state, payload) => {
  state.eventDetail = payload.data;
};
export const setComparisionTotalBillCalls = (state, payload) => {
  state.comparisionTotalBillCalls = payload.data;
};
export const setComparisionTotalWaiterCalls = (state, payload) => {
  state.comparisionTotalWaiterCalls = payload.data;
};
export const setComparisionTotalSessions = (state, payload) => {
  state.comparisionTotalSessions = payload.data;
};
export const setGoogleAnalitycs = (state, payload) => {
  state.googleAnalitycs = payload.data;
};
