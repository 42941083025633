<template>
  <div class="menu-title-container">
    <h4 class="menu-title">{{ $t("pages.menu.menu") }}</h4>
  </div>
  <div class="row justify-between">
    <q-tabs
      v-model="tab"
      narrow-indicator
      dense
      align="left"
      class="text-dark"
      indicator-color="secondary"
    >
      <q-tab :ripple="false" name="menu" no-caps>
        <h4>{{ $t("pages.menu.menu") }}</h4>
      </q-tab>

      <q-tab :ripple="false" name="settings" no-caps>
        <h4>{{ $t("common.settings") }}</h4>
      </q-tab>
      <q-tab :ripple="false" name="stock" no-caps>
        <h4>
          Out of stock ({{ countInactiveProducts + countInactiveElections }})
        </h4>
      </q-tab>
    </q-tabs>
    <!-- <q-btn
      class="hide-button"
      color="primary"
      unelevated
      :label="$t('pages.menu.shareLink')"
      no-caps
      @click="copy"
    /> -->
  </div>
  <MenuView v-if="tab === 'menu'" />
  <Settings v-if="tab === 'settings'" />
  <Stock v-if="tab === 'stock'" />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import { copyToClipboard, useQuasar } from "quasar";
export default {
  components: {
    MenuView: defineAsyncComponent(() => import("../views/Menu.vue")),
    Settings: defineAsyncComponent(() => import("../views/Settings.vue")),
    Stock: defineAsyncComponent(() => import("../views/Stock.vue")),
  },
  data() {
    return {
      tab: "menu",
    };
  },
  computed: {
    ...mapGetters({
      getProducts: "getProducts",
    }),
    countInactiveProducts() {
      return this.getProducts.filter((product) => !product.is_active).length;
    },
    countInactiveElections() {
      let count = 0;
      for (const product of this.getProducts) {
        for (const option of product.options) {
          for (const election of option.election) {
            if (!election.is_active) {
              count++;
            }
          }
        }
      }
      return count;
    },
  },
  methods: {
    copy() {
      copyToClipboard("some text").then(() =>
        this.showNotif("bottom-right", "Enlace copiado al portapapeles")
      );
    },
  },
  setup() {
    const $q = useQuasar();

    return {
      showNotif(position, message) {
        $q.notify({
          message,
          position,
        });
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.menu-title-container {
  text-align: left;
  margin-top: 4px;
  margin-bottom: 19px;
}
.menu-title {
  margin: 0;
}

.q-tabs {
  margin-bottom: 22px;
}
.q-tab {
  padding: 0 12px 0 0;
}
@media screen and (max-width: 414px) and (max-height: 896px) {
  .hide-button {
    display: none;
  }
}
@media (min-width: 1180px) and (min-height: 820px) {
  .hide-button {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1133px),
  (min-width: 1180px) and (min-height: 820px) {
  .menu-title-container {
    text-align: left;
    margin-top: -1px !important;
    margin-bottom: 19px;
  }
}
@media screen and (max-width: 478px),
  (min-width: 375px) and (min-height: 667px) {
  .menu-title-container {
    text-align: left;
    margin-top: -1px !important;
    margin-bottom: 19px;
  }
}
</style>
