<template>
  <button class="category-button">
    <div class="button-info">
      <img src="@/assets/icons/category-menu-icon.svg" alt="icon" />
      <h3>Nueva Categoria</h3>
    </div>
    <img
      class="arrow"
      src="@/assets/icons/category-arrow-icon.svg"
      alt="icon"
    />
  </button>
</template>
<script>
export default {
  name: "new-category",
};
</script>
<style scoped>
.category-button {
  margin-top: 16px;
  width: 370px;
  height: 58px;
  background: #fdfdfd;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px 5px 0px 0px;
  color: #02c874;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  background-color: rgba(2, 200, 117, 0.08);
}

.button-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
h2 {
  margin: 0;
}
.category-button img {
  margin-right: 18px;
}
.arrow {
  justify-self: flex-end;
}
@media screen and (max-width: 1439px) {
  .category-button {
    width: 352px;
  }
}
@media screen and (max-width: 1359px) {
  .category-button {
    width: 325px;
  }
}
@media screen and (max-width: 1023px) {
  .category-button {
    width: 280px;
    margin-right: 0;
  }
}
</style>
