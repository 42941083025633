<template>
  <div class="nelkir-select">
    <select v-model="selection">
      <option class="placeholder-select" value="" disabled selected>
        Selecciona una opción
      </option>
      <option v-for="option in options" :key="option.id" :value="option">
        {{ option.name }}
      </option>
    </select>
    <figure>
      <img src="@/assets/icons/chevron-down.svg" alt="" />
    </figure>
  </div>
</template>
<script>
export default {
  name: "NelkirSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    toggleSelect: {
      type: Function,
      required: true,
    },
    initialValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selection: "",
    };
  },
  watch: {
    selection() {
      this.toggleSelect(this.selection);
    },
  },
};
</script>
<style scoped>
.nelkir-select {
  position: relative;
  width: 100%;
}
select:focus {
  outline: none;
}
select {
  font-family: "DM Sans", sans-serif !important;
  padding-left: 12px;
  padding-right: 34px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  height: 45px;
  appearance: none;
  width: 100%;
}
figure {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 10px;
  pointer-events: none;
}
select[multiple]:focus option:checked {
  background-color: #f2f2f2;
}
</style>
