<template>
  <div :class="list ? '' : 'd-flex'">
    <div v-for="element in elements" :key="element.id">
      <div class="radio-check" @click="toggleRadioList(element.id)">
        <div class="circle">
          <div v-if="currentOption.includes(element.id)" class="dot"></div>
        </div>
        <p v-text="element.name"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ListRadioCheck",
  props: {
    elements: {
      type: Object,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
    initialState: {
      type: Number,
      required: true,
    },
    list: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentOption: [],
    };
  },
  methods: {
    toggleRadioList(payload) {
      this.currentOption[0] = payload;
      this.action(payload);
    },
  },
  mounted() {
    this.currentOption[0] = this.initialState;
    this.action(this.initialState);
  },
};
</script>
<style scoped>
.radio-check {
  display: flex;
  align-items: flex-start;
  margin-top: 13px;
  margin-right: 28px;
  align-items: center;
}
.circle {
  margin-right: 22px;
  height: 18px;
  width: 18px;
  border: 1px solid #bdbdbd;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot {
  height: 12px;
  width: 12px;
  background-color: #02c874;
  border-radius: 100%;
}
</style>
