<template>
  <div class="check-box" @click="toggle(checkbox.id)">
    <img :src="checkbox.value ? checkIcon : nocheckIcon" alt="" />
    <p v-text="checkbox.text"></p>
  </div>
</template>
<script>
import NoCheckIcon from "@/assets/icons/nocheck.svg";
import CheckIcon from "@/assets/icons/check.svg";
export default {
  name: "NelkirCheckBox",
  props: {
    checkbox: {
      type: Object,
      required: true,
    },
    toggle: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      nocheckIcon: NoCheckIcon,
      checkIcon: CheckIcon,
    };
  },
};
</script>
<style scoped>
.check-box {
  display: flex;
  align-items: flex-start;
}
.check-box img {
  margin-right: 9px;
  height: 18px;
  width: 18px;
  align-self: flex-start;
}
</style>
