export const setMenuTime = (state, payload) => {
  state.menuTime = payload.data;
};

export const setCategories = (state, payload) => {
  state.categories = payload.data;
};
export const setCategoriesWithProducts = (state, payload) => {
  state.categoriesWithProducts = payload.data;
};
export const filterByText = (state, payload) => {
  if (payload === "") {
    state.isFiltered = false;
    state.productsFiltered = JSON.parse(
      JSON.stringify(state.categoriesWithProducts)
    );
  } else {
    state.isFiltered = true;
    state.productsFiltered = JSON.parse(
      JSON.stringify(state.categoriesWithProducts)
    );
    payload = payload.toLowerCase();
    for (let index = 0; index < state.productsFiltered.length; index++) {
      state.productsFiltered[index].products = state.productsFiltered[
        index
      ].products.filter((product) => {
        return product.name.toLowerCase().indexOf(payload) > -1;
      });
    }
  }
};
export const setOnlyProducts = (state, payload) => {
  for (let index = 0; index < payload.data.length; index++) {
    const category = payload.data[index];
    for (let index2 = 0; index2 < category.products.length; index2++) {
      const product = category.products[index2];
      state.onlyProducts.push(product);
    }
  }
};
export const setCurrentCategory = (state, payload) => {
  state.currentCategory = payload;
};
