const deleteVerification = {
  state: {
    action: "",
    data: "",
  },
  mutations: {
    setDeleteSettings(state, payload) {
      state.action = payload.action;
      state.data = payload.data;
    },
  },
  getters: {
    getDeleteSettings(state) {
      return {
        action: state.action,
        data: state.data,
      };
    },
  },
};

export default deleteVerification;
