<template>
  <div class="product-order">
    <div class="d-flex" @click="toggle">
      <img :src="selected ? selectedIcon : noSelected" alt="" />
      <div class="product-name">
        {{ product.product_name }} (x{{ product.quantity }})
      </div>
    </div>
    <div class="elections">
      <div v-for="addition in additions" :key="addition.id">
        <p>- {{ addition.name }} x{{ addition.quantity }}</p>
      </div>
      <div v-for="option in options" :key="option.id">
        <p>- {{ option.name }} x{{ option.quantity }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import NoCheckIcon from "@/assets/icons/nocheck.svg";
import CheckIcon from "@/assets/icons/check.svg";
export default {
  name: "ProductOrder",
  props: {
    product: {
      type: Object,
      required: true,
    },
    setReady: {
      type: Function,
      required: true,
    },
    remove: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected: false,
      selectedIcon: CheckIcon,
      noSelected: NoCheckIcon,
      additions: [],
      options: [],
    };
  },
  methods: {
    toggle() {
      this.selected = !this.selected;
      if (this.selected) {
        this.setReady(this.product);
      } else {
        this.remove(this.product);
      }
    },
    setAdditions() {
      for (let index = 0; index < this.product.order_addition.length; index++) {
        const element = this.product.order_addition[index];
        const finded = this.additions.find((el) => el.id === element.id);
        if (finded) {
          const findedIndex = this.additions.indexOf(finded);
          this.additions[findedIndex].quantity++;
        } else {
          this.additions.push({
            name: element.name,
            id: element.id,
            quantity: 1,
          });
        }
      }
    },
    setOptions() {
      for (
        let index = 0;
        index < this.product.order_option_election.length;
        index++
      ) {
        const element = this.product.order_option_election[index];
        const finded = this.options.find((el) => el.id === element.id);
        if (finded) {
          const findedIndex = this.options.indexOf(finded);
          this.options[findedIndex].quantity++;
        } else {
          this.options.push({
            name: element.name,
            id: element.id,
            quantity: 1,
          });
        }
      }
    },
  },
  mounted() {
    this.setAdditions();
    this.setOptions();
  },
};
</script>
<style scoped>
.product-order {
  margin: 15px 0;
  text-align: start;
}
.elections {
  margin-top: 17px;
  padding-left: 36px;
}
img {
  margin-right: 12px;
}
.product-name {
  margin-top: 2px;
  font-weight: 700;
}
</style>
