<template>
  <div>
    <div class="orders-view" v-if="!loading && box.open">
      <nav>
        <div v-for="tab in tabs" :key="tab.id">
          <button
            :class="currentTab == tab.id ? 'active' : ''"
            @click="toggleTabs(tab.id)"
          >
            {{ tab.name }}
            <div v-if="currentTab == tab.id" class="bottom-line"></div>
          </button>
        </div>
      </nav>
      <h3>Mesas</h3>
      <div class="orders">
        <order-list
          :orders="ordersFiltered"
          :setCurrentOrder="setCurrentOrder"
          :currentOrder="currentOrder"
        />
        <div v-if="currentOrder.id == -1" class="empty-order">
          Seleccione una orden para ver los detalles
        </div>
        <order-details
          :order="currentOrder"
          :fetchOrders="fetchOrders"
          v-else
        />
      </div>
    </div>
    <div class="box-settings" v-else>
      <div class="box-form">
        <p>Para comenzar a recibir pedidos debes abrir la caja</p>
        <div class="input">
          <label for=""><span>Total efectivo</span></label>
          <input
            type="number"
            placeholder="1000"
            v-model="box.start"
            name=""
            id=""
          />
          <p :class="box.start.length < 1 ? 'symbol transparent' : 'symbol'">
            $
          </p>
        </div>
        <n-button @click="startBox" :disable="box.start.length < 1">
          Abrir caja
        </n-button>
      </div>
    </div>
    <modal-billing-order :order="currentOrder" :fetchOrders="fetchOrders" />
    <modal-bill :order="currentOrder" />
    <modal-new-order />
    <modal-close-box />
  </div>
</template>
<script>
import NButton from "@/components/tools/buttons/NelkirButton";
import ModalBillingOrder from "@/components/branchView/orders/ModalBillingOrder.vue";
import ModalBill from "@/components/branchView/orders/ModalBill.vue";
import OrderList from "@/components/branchView/orders/OrderList.vue";
import OrderDetails from "@/components/branchView/orders/OrderDetails.vue";
import ModalCloseBox from "@/modals/orders/ModalCloseBox.vue";
import ModalNewOrder from "@/modals/ModalNewOrder.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "OrdersView",
  components: {
    OrderList,
    OrderDetails,
    ModalBillingOrder,
    ModalCloseBox,
    ModalNewOrder,
    ModalBill,
    NButton,
  },
  data() {
    return {
      tabs: [
        { name: "Nuevos", id: 1 },
        { name: "Preparando", id: 2 },
        { name: "Por cobrar", id: 3 },
      ],
      currentTab: 1,
      loading: false,
      currentOrder: { id: -1 },
      orders: "",
      ordersFiltered: [],
      box: {
        open: false,
        start: 0,
        final: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      buttons: "getSidebaBranchButtons",
      currentButton: "getCurrentSidebarBranchButton",
      // orders: "getOrders",
      currentBranch: "getCurrentBranch",
      tables: "getTables",
    }),
    // filterByStatus() {
    //   return this.ordersFiltered.filter(
    //     (order) => order.order_status == this.currentTab
    //   );
    // }
  },
  methods: {
    ...mapMutations(["setCurrentSidebarBranchButton"]),
    toggleTabs(id) {
      this.currentTab = id;
      this.filterByStatus(this.currentTab);
      this.setCurrentOrder({ id: -1 });
    },
    setCurrentOrder(order) {
      this.currentOrder = order;
    },
    fetchOrders() {
      this.loading = true;
      this.$store
        .dispatch("businessRequest", {
          action: "fetchOrders",
        })
        .then((response) => {
          this.loading = false;
          this.orders = response.data;
          this.currentOrder = { id: -1 };
          // this.filterByStatus(this.currentTab);
          this.filterByBranch();
        });
    },
    filterByStatus(payload) {
      this.ordersFiltered = this.orders.filter(
        (order) => order.order_status == payload
      );
    },
    // fetchTables() {
    //   this.$store.dispatch("businessRequest", {
    //     action: "fetchTables",
    //   });
    //   setTimeout(() => {
    //     this.filterByBranch();
    //   }, 500);
    // },
    startBox() {
      this.box.open = true;
    },
    filterByBranch() {
      for (let i = 0; i < this.orders.length; i++) {
        const order = this.orders[i];
        for (let j = 0; j < this.tables.length; j++) {
          const table = this.tables[j];
          if (order.table == table.id) {
            this.ordersFiltered.push(order);
          }
        }
      }
      this.filterByStatus(this.currentTab);
    },
  },
  created() {
    this.fetchOrders();
    // this.fetchTables();
  },
};
</script>
<style scoped>
.box-settings {
  width: calc(100vw - 267px);
  height: calc(100vh - 116px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-form {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 5px;
  padding: 30px;
  width: 300px;
  display: grid;
  gap: 16px;
  text-align: start;
}
.input {
  position: relative;
}
.input .symbol {
  position: absolute;
  top: 44px;
  left: 15px;
}
.transparent {
  opacity: 0.5;
}
.box-form input {
  margin-bottom: 4px;
  margin-top: 8px;
  width: 100%;
  padding-left: 25px;
}
.box-form .primary-button {
  height: 50px;
}
.orders-view {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 30px auto;
  row-gap: 24px;
  text-align: start;
}
nav {
  display: flex;
  width: 100%;
  overflow: auto;
}
::-webkit-scrollbar {
  height: 0;
  width: 0;
}
nav button {
  position: relative;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #828282;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 26px;
}
.active {
  color: #02c874;
}
.bottom-line {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #02c874;
}
.orders {
  display: grid;
  grid-template-columns: 370px 1fr;
  gap: 30px;
}
@media screen and (max-width: 1439px) {
  nav button {
    margin-right: 20px;
  }
}
</style>
