<template>
  <div class="percent-input">
    <strong>{{ label }}</strong>
    <div class="input">
      <p class="percent">%</p>
      <input
        type="number"
        v-model="inputValue"
        placeholder="0.00"
        @input="$emit('customChange', $event.target.value)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "PercentInput",
  props: {
    label: {
      type: String,
      default: "Ingrese un monto",
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      inputValue: "",
    };
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
<style scoped>
.percent-input {
  text-align: start;
}
.input {
  display: flex;
  position: relative;
}
.percent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  padding-right: 15px;
  padding-top: 8px;
  right: 0;
}
input {
  padding-right: 28px;
  width: 100%;
  text-align: right;
  margin-top: 8px;
}
</style>
