<template>
  <div class="time-picker">
    <button
      :class="placeholder ? 'time placeholder' : 'time'"
      @click="editTime"
    >
      <img
        src="@/assets/icons/watch-icon.svg"
        alt="watch-icon"
        v-if="placeholder"
      />
      {{ time }}
    </button>
    <div v-if="showSelectors" class="selectors">
      <div class="selector">
        <div v-for="currentHour in hours" :key="currentHour.id">
          <button
            :class="currentHour.active ? 'active' : ''"
            @click="setHour(currentHour.id)"
          >
            {{ currentHour.hour }}
          </button>
        </div>
      </div>
      <div class="separator"></div>
      <div class="selector">
        <div v-for="currentMinute in minutes" :key="currentMinute.id">
          <button
            :class="currentMinute.active ? 'active' : ''"
            @click="setMinute(currentMinute.id)"
          >
            {{ currentMinute.minute }}
          </button>
        </div>
      </div>
      <div class="separator"></div>
      <div class="selector">
        <div v-for="currentCycle in cycles" :key="currentCycle.id">
          <button
            :class="currentCycle.active ? 'active' : ''"
            @click="setCycle(currentCycle.id)"
          >
            {{ currentCycle.text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    saveTime: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showSelectors: false,
      cycles: [
        { text: "A.M.", id: 0 },
        { text: "P.M.", id: 1 },
      ],
      hour: "--",
      minute: "--",
      cycle: "--",
    };
  },
  computed: {
    time() {
      if (
        this.hour == "--" &&
        this.minute == "--" &&
        this.cycle == "--" &&
        this.showSelectors == false
      ) {
        return "Hora";
      }
      return `${this.hour}:${this.minute} ${this.cycle}`;
    },
    placeholder() {
      return this.hour == "--" && this.minute == "--" && this.cycle == "--";
    },
    hours() {
      const array = [];
      for (let index = 0; index < 12; index++) {
        const value = index + 1;
        if (index < 9) {
          array.push({ hour: 0 + value.toString(), id: index, active: false });
        } else {
          array.push({ hour: value.toString(), id: index, active: false });
        }
      }
      return array;
    },
    minutes() {
      const array = [];
      for (let index = 0; index < 59; index++) {
        const value = index;
        if (index < 9) {
          array.push({
            minute: 0 + value.toString(),
            id: index,
            active: false,
          });
        } else {
          array.push({ minute: value.toString(), id: index, active: false });
        }
      }
      return array;
    },
  },
  methods: {
    setHour(payload) {
      for (let index = 0; index < this.hours.length; index++) {
        const element = this.hours[index];
        if (element.id == payload) {
          this.hours[index].active = true;
          this.hour = this.hours[index].hour;
        } else {
          this.hours[index].active = false;
        }
      }
      this.closeTimePicker();
    },
    setMinute(payload) {
      for (let index = 0; index < this.minutes.length; index++) {
        const element = this.minutes[index];
        if (element.id == payload) {
          this.minutes[index].active = true;
          this.minute = this.minutes[index].minute;
        } else {
          this.minutes[index].active = false;
        }
      }
      this.closeTimePicker();
    },
    setCycle(payload) {
      for (let index = 0; index < this.cycles.length; index++) {
        const element = this.cycles[index];
        if (element.id == payload) {
          this.cycles[index].active = true;
          this.cycle = this.cycles[index].text;
        } else {
          this.cycles[index].active = false;
        }
      }
      this.closeTimePicker();
    },
    editTime() {
      this.showSelectors = !this.showSelectors;
      if (!this.showSelectors) {
        this.saveTime(this.time);
      }
    },
    closeTimePicker() {
      if ((this.hour != "--") & (this.minute != "--") & (this.cycle != "--")) {
        this.showSelectors = false;
        this.saveTime(this.time);
      }
    },
  },
};
</script>
<style scoped>
.time-picker {
  position: relative;
  margin-top: 7px;
  width: 100%;
}
.time {
  width: 100%;
  height: 45px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  letter-spacing: 1px;
}
.selectors {
  display: flex;
  justify-content: center;
  position: absolute;
  height: 154px;
  width: 165px;
  overflow: hidden;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  z-index: 99;
  padding: 14px 0px;
}
.selector {
  overflow-x: hidden;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 0;
}
.separator {
  height: 100%;
  border: 1px solid #d0d0d0;
}
button {
  width: 52px;
  font-size: 18px;
  background-color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.active {
  color: #02c874;
}
.placeholder {
  color: #bdbdbd;
  display: flex;
  justify-content: flex-start;
  padding-left: 30px;
}
</style>
