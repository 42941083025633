<template>
  <div class="businesses-v2">
    <header-client />
    <section class="businesses-container">
      <h2>Establecimientos</h2>
      <p>Los establecimientos son los restaurantes o bares que has creado</p>
      <div class="businesses-list">
        <div v-for="business in businesses" :key="business.id">
          <BusinessItem
            :shop="business"
            :description="true"
            :shopDetails="businessDetails"
            :editShop="editBusiness"
          />
        </div>
        <!-- <button
          class="add-shop-button"
          data-bs-toggle="modal"
          data-bs-target="#createBusinessModal"
        >
          <img src="@/assets/icons/icon-shop.svg" alt="add-shop-icon" />
          <p>Crear tienda</p>
        </button> -->
        <button
          v-if="businesses.length < 1"
          class="add-shop-button"
          @click="toggleModalCreateBusiness"
        >
          <img src="@/assets/icons/icon-shop.svg" alt="add-shop-icon" />
          <p>Crear tienda</p>
        </button>
      </div>
    </section>
    <ModalCreateBusiness
      :reloadList="fetchBusinesses"
      v-model="showModalCreateBusiness"
    />
    <q-dialog v-model="showModalEditBusiness">
      <ModalEditBusiness
        :reloadList="fetchBusinesses"
        :close="toggleModalCreateBusiness"
        :currentBusinessInfo="currentBusinessInfo"
      />
    </q-dialog>
    <!-- <modal-create-business :reloadList="fetchBusinesses" /> -->
    <!-- <modal-edit-business :reloadList="fetchBusinesses" /> -->
  </div>
</template>
<script>
import HeaderClient from "@/components/HeaderClient.vue";
// import ShopItem from "@/components/ShopItem.vue";
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";
export default {
  name: "BusinessesV2",
  components: {
    HeaderClient,
    BusinessItem: defineAsyncComponent(() =>
      import("../components/BusinessItem.vue")
    ),
    ModalCreateBusiness: defineAsyncComponent(() =>
      import("../modals/ModalCreateBusiness.vue")
    ),
    ModalEditBusiness: defineAsyncComponent(() =>
      import("../modals/ModalEditBusiness.vue")
    ),
  },

  data() {
    return {
      loading: false,
      currentBusinessInfo: "",
      showModalCreateBusiness: false,
      showModalEditBusiness: false,
    };
  },
  computed: {
    ...mapGetters({
      businesses: "getBusinesses",
      isAdmin: "getAdmin",
      isLogued: "getLogued",
      business: "getDomainName",
    }),
  },
  methods: {
    toggleModalCreateBusiness() {
      this.showModalCreateBusiness = !this.showModalCreateBusiness;
    },
    toggleModalEditBusiness() {
      this.showModalEditBusiness = !this.showModalEditBusiness;
    },
    editBusiness(payload) {
      this.currentBusinessInfo = payload;
      window.$("#editBusinessModal").modal("show");
    },
    fetchBusinesses() {
      this.$store
        .dispatch("appRequest", {
          action: "fetchBusinesses",
        })
        .then((response) => {
          if (response.status == 200) {
            this.loading = false;
          }
        });
    },
    businessDetails(payload) {
      this.$store.dispatch("fetchBusinessTokens", payload).finally(() => {
        this.$store.dispatch("fetchBranches");
        this.toBusiness();
      });
    },
    toBusiness() {
      this.$router.push({
        name: "branches",
        params: { business: this.business },
      });
    },
  },
  mounted() {
    if (this.isLogued) {
      if (!this.isAdmin) {
        this.toBusiness();
      }
    } else {
      this.$router.push("/");
    }
    if (this.isAdmin) {
      this.fetchBusinesses();
    }
  },
};
</script>
<style scoped>
.businesses-v2 {
  width: 100%;
}
.businesses-container {
  padding: 30px 0 30px 20px;
  text-align: start;
}
h2 {
  margin-bottom: 8px;
}
.businesses-list {
  display: flex;
  flex-wrap: wrap;
}
.add-shop-button {
  margin-top: 26px;
  height: 130px;
  width: 324px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 10px;
  padding: 25px 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-shop-button img {
  margin-right: 20px;
}
@media screen and (max-width: 1439px) {
  .add-shop-button {
    width: 304px;
    margin-top: 18px;
  }
}
@media screen and (max-width: 1359px) {
  .add-shop-button {
    width: 308px;
  }
}
@media screen and (max-width: 767px) {
  .add-shop-button {
    width: 100%;
  }
}
</style>
