import axios from "@/businessApi";

export const fetchTables = async ({ commit, rootState }) => {
  try {
    const response = await axios.get(
      `/r/${rootState.domainName}/v1/tables`,
      rootState.businessAuth
    );
    commit("setTables", response.data);
  } catch (error) {
    console.error(error);
  }
};
export const createOrder = ({ rootState, commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/r/${rootState.domainName}/v1/orders`,
        payload,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
          commit("setOrderInProgress");
        },
        (error) => {
          resolve(error.response);
          reject(error);
        }
      );
  });
};
