<template>
  <div class="img-cropper">
    <div class="cropper-area">
      <div class="img-container">
        <img ref="image" :src="img" alt="" />
      </div>
      <div>
        <img class="destination-container" :src="destination" alt="" />
        <p>Vista previa</p>
      </div>
    </div>
    <div class="buttons">
      <button class="tertiary-button" @click="cancel">Cancelar</button>
      <button class="primary-button" @click="saveImg">Guardar</button>
    </div>
  </div>
</template>
<script>
import Cropper from "cropperjs";
export default {
  name: "ImgCropper",
  props: {
    img: {
      type: String,
      required: true,
    },
    reset: {
      type: Function,
      required: true,
    },
    ratio: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cropper: {},
      destination: {},
      image: {},
    };
  },
  mounted() {
    this.image = this.$refs.image;
    this.cropper = new Cropper(this.image, {
      zoomable: false,
      scalable: false,
      aspectRatio: this.ratio,
      minContainerHeight: 280,
      minContainerWidth: 280,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.destination = canvas.toDataURL("image/png");
      },
    });
  },
  methods: {
    saveImg() {
      this.$store.commit("setCurrentImg", this.destination);
      window.$("#modalCropImg").modal("hide");
      this.$emit("close");
      this.reset();
    },
    cancel() {
      window.$("#modalCropImg").modal("hide");
      this.reset();
    },
  },
};
</script>
<style scoped>
.cropper-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img-container {
  height: 280px;
  width: 280px;
}
.destination-container {
  width: 145px;
  height: 145px;
  border: 1px solid #e0e0e0;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.tertiary-button {
  background-color: #fff;
  color: #4f4f4f;
}
.tertiary-button:hover {
  background-color: #fff;
}
.buttons button {
  width: 150px;
  margin-left: 20px;
}
@media screen and (max-width: 425px) {
  .cropper-area {
    flex-direction: column;
    align-items: center;
  }
  .destination-container {
    margin-top: 16px;
  }
  .buttons {
    justify-content: center;
  }
}
</style>
