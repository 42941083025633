<template>
  <div class="order-food">
    <div class="food-name">
      <p>
        <img src="@/assets/icons/new-item.svg" alt="status-icon" />
        {{ food.product_name }}
      </p>
      <p class="qty">(x{{ food.quantity }})</p>
    </div>
    <div class="food-details">
      <h5>OPCIONES Y DETALLES</h5>
      <div class="elections">
        <div v-for="addition in additions" :key="addition.id">
          <li>{{ addition.name }} x{{ addition.quantity }}</li>
        </div>
        <div v-for="option in options" :key="option.id">
          <li>{{ option.name }} x{{ option.quantity }}</li>
        </div>
      </div>
    </div>
    <div class="food-price">
      <h5>PRECIO</h5>
      <p>${{ new Intl.NumberFormat("es-CO").format(priceWithElecctions) }}</p>
    </div>
    <div class="actions">
      <img src="@/assets/icons/trash-dark.svg" alt="trash-icon" />
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderFood",
  props: {
    food: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      additions: [],
      options: [],
    };
  },
  computed: {
    priceWithElecctions() {
      let totalPrice = this.food.product_price.price;
      for (
        let index = 0;
        index < this.food.order_option_election.length;
        index++
      ) {
        const element = this.food.order_option_election[index].price;
        totalPrice = totalPrice + element;
      }
      for (let index = 0; index < this.food.order_addition.length; index++) {
        const element = this.food.order_addition[index].price;
        totalPrice = totalPrice + element;
      }
      return totalPrice * this.food.quantity;
    },
  },
  methods: {
    setAdditions() {
      for (let index = 0; index < this.food.order_addition.length; index++) {
        const element = this.food.order_addition[index];
        const finded = this.additions.find((el) => el.id == element.id);
        if (finded) {
          const findedIndex = this.additions.indexOf(finded);
          this.additions[findedIndex].quantity++;
        } else {
          this.additions.push({
            name: element.name,
            id: element.id,
            quantity: 1,
          });
        }
      }
    },
    setOptions() {
      for (
        let index = 0;
        index < this.food.order_option_election.length;
        index++
      ) {
        const element = this.food.order_option_election[index];
        const finded = this.options.find((el) => el.id == element.id);
        if (finded) {
          const findedIndex = this.options.indexOf(finded);
          this.options[findedIndex].quantity++;
        } else {
          this.options.push({
            name: element.name,
            id: element.id,
            quantity: 1,
          });
        }
      }
    },
  },
  mounted() {
    this.$store.commit(
      "setMoreTotalPriceOfCurrentOrder",
      this.priceWithElecctions
    );
  },
  created() {
    this.setAdditions();
    this.setOptions();
  },
};
</script>
<style scoped>
h5 {
  color: #828282;
  margin: 16px 0 8px;
  display: none;
}
.order-food {
  display: grid;
  grid-template-columns: auto 200px 100px 120px;
  column-gap: 8px;
  width: 100%;
  margin-top: 26px;
}
.food-name {
  display: flex;
}
.food-name p {
  display: flex;
  align-items: center;
  max-height: 24px;
}
.qty {
  width: 156px;
  margin-left: 4px;
}
.food-price {
  text-align: end;
}
.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-height: 24px;
}
.actions img {
  height: 48px;
}
@media screen and (max-width: 1359px) {
  h5 {
    display: block;
  }
  .order-food {
    grid-template-columns: auto 100px;
    grid-template-rows: auto auto;
    grid-template-areas: "top-a top-b" "bot-a bot-b";
  }
  .food-name {
    justify-content: space-between;
    grid-area: top-a;
  }
  .food-details {
    margin-left: 40px;
    grid-area: bot-a;
  }
  .qty {
    width: 70px;
  }
  .food-price {
    grid-area: bot-b;
  }
  .actions {
    grid-area: top-b;
  }
}
@media screen and (max-width: 767px) {
  .order-food {
    flex-direction: column;
  }
  .food-name p {
    width: 100%;
  }
  .food-name .qty {
    width: 66px;
  }
  .food-details {
    width: auto;
  }
}
</style>
