<template>
  <div class="nelkir-select">
    <strong>{{ label }}</strong>
    <div class="select-option">
      <button
        class="select-box"
        :id="uniqueId"
        @click="showList"
        @blur="blur"
      ></button>
      <div class="current-option">
        {{ currentOption.name }}
      </div>
      <button class="arrow" @click="showList">
        <img src="@/assets/icons/chevron-down.svg" alt="" />
      </button>
      <div v-if="list" class="container-list shadow">
        <div v-for="option in listOptions" :key="option.id">
          <div class="option" @click="setOption(option)">
            <p>{{ option.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectOption",
  props: {
    label: {
      type: String,
      default: "Seleccione una opcion",
    },
    options: {
      type: Array,
      required: true,
      default: () => {
        return [{ name: "opcion 1", id: 0 }];
      },
    },
    uniqueId: {
      type: String,
      required: true,
      default: "nelkirSelect",
    },
    select: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      list: false,
      currentOption: "",
      listOptions: [{ name: "Elige una opcion", id: 0 }],
    };
  },
  methods: {
    showList() {
      this.list = !this.list;
    },
    setOption(payload) {
      this.currentOption = payload;
      this.list = false;
      this.select(payload.id);
    },
    blur() {
      setTimeout(() => {
        this.list = false;
      }, 100);
    },
    reset() {
      this.currentOption = "";
      this.disableInput = false;
      const select = document.getElementById(this.uniqueId);
      setTimeout(() => {
        select.focus();
      }, 100);
    },
  },
  mounted() {
    for (let index = 0; index < this.options.length; index++) {
      const element = this.options[index];
      this.listOptions.push(element);
    }
    // const setInitialValue = this.setOption(this.listOptions[0]);
    // const myModalEl = document.querySelector(`#${this.uniqueId}`);
    this.setOption(this.listOptions[0]);
    // myModalEl.addEventListener("show.bs.modal", () => {
    //   setInitialValue()
    // });
  },
};
</script>
<style scoped>
.nelkir-select {
  text-align: start;
}
.select-option {
  position: relative;
  width: 100%;
}
.select-box {
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 8px;
}
.select-box:focus {
  border-color: #02c874;
}
.arrow {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 8px;
  top: 46%;
  transform: translate(0, -50%);
}
.current-option {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%);
}
.option {
  padding: 0 5px;
  height: 55px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.option:hover {
  background: #f2f2f2;
  border-radius: 2px;
  color: #fff;
}
.container-list {
  border: 1px solid rgb(150, 150, 150);
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  position: absolute;
  z-index: 90;
}
</style>
