<template>
  <div class="modal-nelkir">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <button class="button-close" @click="close">
            <img src="@/assets/icons/close-modal.svg" alt="" />
          </button>
          <img class="sprite" src="@/assets/images/menu-demo.png" alt="image" />
          <div class="modal-text">
            <h1>Menú</h1>
            <p>
              Crea el menú que va a ser visualizado por tus clientes en tu
              restaurante o si quieres, nosotros hacemos este proceso por ti
              comunicándote con <span>contacto@nelkir.com</span>
            </p>
            <button class="primary-button" @click="close(1)">Comenzar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalMenu",
  props: {
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      id: 1,
    };
  },
  computed: {
    checkViewed() {
      const currentUser = this.$store.getters.getCurrentUser;
      let value;
      for (let index = 0; index < currentUser.modals.length; index++) {
        const element = currentUser.modals[index];
        if (element.modalId == this.id) {
          value = currentUser.modals[index].viewed;
        }
      }
      return value;
    },
  },
  mounted() {
    if (this.checkViewed) {
      this.close();
    } else {
      this.$store.commit("setViewedModal", this.id);
    }
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 690px;
  max-height: 503px;
}
.modal-body {
  width: 690px;
  height: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
}
.modal-text {
  margin-top: 30px;
}
.button-close {
  position: absolute;
  background-color: transparent;
  border: none;
  top: 29px;
  right: 34px;
}
p {
  margin: 12px 0 0 0;
  width: 277px;
}
.primary-button {
  width: 160px;
  margin-top: 20px;
}
@media screen and (max-width: 1439px) {
  .modal-body {
    width: 651px;
  }
}
@media screen and (max-width: 1359px) {
  .modal-body {
    width: 641px;
  }
}
@media screen and (max-width: 1023px) {
  .modal-body {
    width: 280px;
    height: 503px;
    flex-direction: column;
  }
  .sprite {
    width: 230px;
  }
  .modal-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    text-align: center;
  }
  p {
    width: 226px;
    margin: 0;
  }
}
</style>
