<template>
  <div class="information-tab">
    <div class="item-a">
      <h6>DATOS GENERALES</h6>
      <div class="inputs">
        <div class="input">
          <label for="">Nombre de la tienda</label>
          <input type="text" v-model="business" disabled />
          <figure class="lock-icon">
            <img src="@/assets/icons/lock-icon.svg" alt="lock-icon" />
          </figure>
        </div>
        <div class="input">
          <label for="">Nombre de la sede</label>
          <input type="text" v-model="branchInfo.name" />
        </div>
      </div>
      <div class="input">
        <label for="">URL web</label>
        <input type="text" />
      </div>
      <p>
        Tu dominio web será www.nelkir.com/urlweb. Si cambias este URL, los QR
        de tus mesas deberán ser reimpresos.
      </p>
    </div>
    <div class="item-b">
      <h6>MEDIOS DE PAGO</h6>
    </div>
    <div class="item-c">
      <h6>TIPO DE SERVICIO</h6>
      <nelkir-radio-check
        :elements="serviceTypes"
        :action="setServiceType"
        :initialState="branchInfo.service_type"
      />
    </div>
    <div class="item-d">
      <h6>UBICACIÓN</h6>
      <div class="inputs">
        <div class="input">
          <label for="">País</label>
          <select-option
            :uniqueId="'countries-select'"
            :options="countries"
            :select="setCountry"
          />
        </div>
        <div class="input">
          <label for="">Estado o departamento</label>
          <select-option
            :uniqueId="'states-select'"
            :options="states"
            :select="setState"
            :disable="country == null"
          />
        </div>
      </div>
      <div class="inputs">
        <div class="input">
          <label for="">Ciudad</label>
          <select-option
            :uniqueId="'cities-select'"
            :options="cities"
            :select="setCity"
            :disable="state == null"
          />
        </div>
        <div class="input">
          <label for="">Tipo de moneda</label>
          <input type="text" />
        </div>
      </div>
      <div class="input">
        <label for="">Dirección</label>
        <input type="text" v-model="branchInfo.detail.address" />
      </div>
      <figure class="map-container"></figure>
    </div>
    <footer class="item-e">
      <button class="primary-button" @click="save">Guardar</button>
    </footer>
  </div>
</template>
<script>
import NelkirRadioCheck from "@/components/tools/NelkirRadioCheck.vue";
import SelectOption from "@/components/tools/SelectOption.vue";
import { mapGetters } from "vuex";
export default {
  name: "InformationTab",
  components: {
    NelkirRadioCheck,
    SelectOption,
  },
  data() {
    return {
      newBranchInfo: {},
      serviceTypes: [
        { name: "Pago Inmediato", id: 1 },
        { name: "Pago al final", id: 2 },
      ],
      countries: null,
      states: null,
      cities: null,
      country: null,
      state: null,
      city: null,
      serviceType: null,
    };
  },
  computed: {
    ...mapGetters({
      branchInfo: "getCurrentBranchInfo",
      changes: "getExistsChanges",
      currencies: "getCurrencies",
      business: "getCurrentBusiness",
    }),
  },
  methods: {
    setServiceType(payload) {
      this.serviceType = payload;
    },
    fetchCountries() {
      this.$store
        .dispatch("businessRequest", {
          action: "fetchCountries",
        })
        .then((response) => {
          this.countries = response.data;
        });
    },
    fetchStates() {
      this.$store
        .dispatch("businessRequest", {
          action: "fetchStates",
        })
        .then((response) => {
          this.states = response.data;
        });
    },
    fetchCities(id) {
      this.$store
        .dispatch("businessRequest", {
          action: "fetchCities",
          data: id,
        })
        .then((response) => {
          this.cities = response.data;
        });
    },
    setCountry(payload) {
      this.country = payload;
    },
    setState(payload) {
      this.state = payload;
      this.fetchCities(payload.id);
    },
    setCity(payload) {
      this.city = payload;
    },
    getInfo() {
      return {
        name: this.branchInfo.name,
        service_type:
          this.serviceType === null
            ? this.branchInfo.service_type
            : this.serviceType,
        branch_detail: {
          city: this.city === null ? this.branchInfo.detail.city : this.city,
          address: this.branchInfo.detail.address,
        },
      };
    },
    save() {
      this.$store.dispatch("businessRequest", {
        action: "updateBranch",
        data: this.getInfo(),
      });
    },
  },
  created() {
    this.fetchCountries();
    this.fetchStates();
  },
  beforeUnmount() {
    const result = window.confirm(
      "Tiene cambios sin guardar ¿Guardar cambios?"
    );
    if (result) {
      this.save();
    }
  },
};
</script>
<style scoped>
.information-tab {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto auto auto;
  column-gap: 33px;
  row-gap: 16px;
  grid-template-areas:
    "general-data location"
    "pay-methods location"
    "service-type location"
    "footer footer";
}
.item-a {
  grid-area: general-data;
}
.item-b {
  grid-area: pay-methods;
}
.item-c {
  grid-area: service-type;
}
.item-d {
  grid-area: location;
}
.item-e {
  grid-area: footer;
}
h6 {
  margin-bottom: 16px;
  color: #828282;
}
.item-a,
.item-b,
.item-c,
.item-d {
  height: 100%;
  width: 100%;
  background: #fff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 20px 22px 24px 24px;
  text-align: start;
}
.input {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}
.input label {
  font-weight: 700;
  margin-bottom: 6px;
}
.input input {
  width: 100%;
}
.inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.map-container {
  width: 100%;
  height: 280px;
  background: gainsboro;
  margin: 0;
}
footer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.primary-button {
  width: 170px;
}
.branch-pay-methods {
  margin-top: 16px;
}
.lock-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  margin: 0;
}
@media screen and (max-width: 1439px) {
  .information-tab {
    column-gap: 20px;
  }
}
@media screen and (max-width: 1359px) {
  .information-tab {
    grid-template-columns: 325px auto;
    column-gap: 24px;
    grid-template-areas:
      "general-data service-type"
      "general-data location"
      "pay-methods location"
      "footer footer";
  }
}
@media screen and (max-width: 1023px) {
  .information-tab {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "general-data"
      "pay-methods"
      "service-type"
      "location"
      "footer";
  }
  .branch-pay-methods {
    margin-bottom: 16px;
  }
  .input label {
    font-size: 12px;
  }
}
</style>
