<template>
  <div class="business">
    <button><img src="@/assets/icons/edit-shop.svg" alt="" /></button>
    <figure>
      <img class="img" :src="business.image" alt="Foto de tienda" />
    </figure>
    <div class="text-container">
      <p>{{ business.name }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    business: {
      type: Array,
      required: true,
    },
  },
  name: "BranchItem",
};
</script>
<style scoped>
.business {
  width: 170px;
  height: 170px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.text-container {
  background-color: #333;
  color: #fff;
  height: 53px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
figure {
  margin-top: 20px;
  height: 82px;
  width: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;
}
.img {
  width: 100%;
}
button {
  position: absolute;
  background-color: #02c874;
  height: 25px;
  width: 25px;
  border: none;
  border-radius: 100%;
  right: 17px;
  top: 17px;
}
p {
  color: #fff;
}
@media screen and (max-width: 1439px) {
  .business {
    width: 164px;
    height: 164px;
  }
  .img {
    height: 76px;
    width: 76px;
  }
}
@media screen and (max-width: 1359px) {
  .business {
    width: 183px;
    height: 183px;
  }
  .img {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1023px) {
  .business {
    width: 134px;
    height: 134px;
  }
  .img {
    height: 55px;
    width: 55px;
  }
  button {
    top: 9px;
    right: 9px;
  }
}
@media screen and (max-width: 767px) {
  .business {
    width: 134px;
    height: 134px;
  }
  .img {
    height: 52px;
    width: 52px;
    margin-top: 20px;
  }
}
</style>
