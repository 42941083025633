<template>
  <div :class="active ? 'order-item active-order' : 'order-item'">
    <p>Mesa #{{ order.table_name }}</p>
    <p>{{ order.order_products.length }} item(s)</p>
    <div class="date">
      <img :src="active ? watchWhite : watchDark" alt="watch-icon" />
      <p>Hoy, {{ time }}</p>
    </div>
  </div>
</template>
<script>
import WatchDark from "@/assets/icons/order-watch-dark.svg";
import WatchWhite from "@/assets/icons/order-watch-white.svg";
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      watchDark: WatchDark,
      watchWhite: WatchWhite,
      time: "00:00",
    };
  },
  computed: {
    active() {
      return this.current.id == this.order.id;
    },
  },
  methods: {
    setTime() {
      let cycle = "A.M.";
      let hour = Number(this.order.created_at.substring(11, 13));
      const min = Number(this.order.created_at.substring(14, 16));
      if (hour == 0) {
        hour = 12;
      }
      if (hour > 12) {
        hour = hour - 12;
        cycle = "P.M.";
      }
      this.time = `${hour}:${min} ${cycle}`;
    },
  },
  mounted() {
    this.setTime();
  },
};
</script>
<style scoped>
.order-item {
  height: 109px;
  width: 170px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.active-order {
  background-color: #333333;
}
.active-order p {
  color: #fff;
}
.date {
  display: flex;
}
img {
  margin-right: 10px;
}
@media screen and (max-width: 1439px) {
  .order-item {
    width: 162px;
  }
}
@media screen and (max-width: 1359px) {
  .order-item {
    width: 184px;
  }
}
@media screen and (max-width: 1023px) {
  .order-item {
    width: 135px;
    padding-right: 0;
    margin-right: 12px;
  }
}
</style>
