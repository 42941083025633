import axios from "axios";
import api from "@/api";

export const getConfig = async ({ rootState }) => {
  const { data } = await axios.get(
    `${api}/r/${rootState.domainName}/v1/configs`
  );
  return data[0];
};
export const createConfig = async ({ rootState }, payload) => {
  const resp = await axios.post(
    `${api}/r/${rootState.domainName}/v1/configs`,
    payload
  );
  return resp;
};
export const updateConfig = async ({ rootState }, payload) => {
  const resp = await axios.put(
    `${api}/r/${rootState.domainName}/v1/configs/${payload.id}`,
    payload
  );
  return resp;
};
