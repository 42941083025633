<template>
  <div class="product-details-grid">
    <div class="photo item-a">
      <label>Foto</label>
      <button
        class="container-img"
        data-bs-toggle="modal"
        data-bs-target="#modalCropImg"
      >
        <div v-if="image == null" class="no-image">
          <img src="@/assets/images/upload-photo.png" alt="" />
          <p>Sube una foto de tu producto</p>
        </div>
        <img
          v-else
          :src="newImg != null ? newImg : image"
          alt="product-image"
          class="product-img"
        />
      </button>
    </div>
    <div class="input item-b">
      <label>Nombre del producto</label>
      <input type="text" placeholder="Ej: Hamburguesa Triple" v-model="name" />
    </div>
    <div class="input item-c">
      <label>Descripción</label>
      <textarea
        rows="2"
        placeholder="Escribe aquí una descripción de tu producto"
        v-model="description"
      ></textarea>
    </div>
    <div class="select item-e">
      <label>Inventario</label>
      <select-inventory
        :initialState="isActive"
        :toggle="toggleIsActive"
        :a="'coño'"
      />
    </div>
    <div class="input item-d">
      <label>Precio</label>
      <input type="number" placeholder="$30.000" v-model="price" />
    </div>
    <div class="input item-f">
      <label>Descuento</label>
      <input
        type="number"
        placeholder="$30.000"
        v-model="discountTotal"
        :disabled="!applyDiscount.value"
        @input="setDiscountTotal"
      />
    </div>
    <div class="input item-g">
      <label class="checkbox">
        <nelkir-check-box :checkbox="applyDiscount" :toggle="toggleDiscount"
      /></label>
      <input
        type="number"
        placeholder="30"
        v-model="discountRate"
        :disabled="!applyDiscount.value"
        @input="setDiscountRate"
      />
      <p v-if="discountRate.length > 0" class="percent-symbol">%</p>
    </div>
    <div class="item-h">
      <p><span>Precio final:</span> &nbsp; &nbsp; ${{ totalPrice }}</p>
    </div>
    <div class="item-i">
      <label>Lugar de preparación</label>
      <nelkir-radio-check
        :elements="preparationPlaces"
        :action="setPreparationPlace"
        :initialState="productType"
      />
    </div>
    <div class="item-j">
      <button class="delete" @click="openModalDelete">Eliminar producto</button>
      <div class="buttons">
        <button
          class="primary-button"
          @click="updateProduct"
          :disabled="disableCreateProduct"
        >
          Guardar
        </button>
      </div>
    </div>
    <modal-delete-product :id="product.id" :update="update" />
  </div>
</template>
<script>
import NelkirCheckBox from "@/components/NelkirCheckBox.vue";
import SelectInventory from "@/components/tools/SelectInventory.vue";
import NelkirRadioCheck from "@/components/tools/NelkirRadioCheck.vue";
import ModalDeleteProduct from "@/modals/categories/ModalDeleteProduct.vue";
import { mapGetters } from "vuex";
export default {
  name: "ProductDetails",
  components: {
    NelkirCheckBox,
    SelectInventory,
    NelkirRadioCheck,
    ModalDeleteProduct,
  },
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
    fetchProducts: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      description: "",
      isActive: "",
      price: "",
      iva: "",
      discountRate: "",
      discountTotal: "",
      applyDiscount: {
        text: "",
        id: 0,
        value: false,
      },
      options: [
        {
          name: "Disponible",
          value: true,
          id: 0,
        },
        {
          name: "Agotado",
          value: false,
          id: 1,
        },
      ],
      image: null,
      preparationPlaces: [
        { name: "Cocina", id: 1 },
        { name: "Bar", id: 2 },
      ],
      preparetionPlace: null,
      productType: "",
      updates: new Object(),
    };
  },
  computed: {
    ...mapGetters({
      newImg: "getCurrentImg",
    }),
    sameImg() {
      return this.image == this.product.image;
    },
    product() {
      return this.$store.getters.getCurrentProduct;
    },
    totalPrice() {
      let priceTotal;
      if (this.applyDiscount.value) {
        const totalDiscount = this.price * (this.discountRate / 100);
        const subtotal = this.price - totalDiscount;
        priceTotal = subtotal;
      } else {
        priceTotal = this.price;
      }
      return Math.round(priceTotal);
    },
    disableCreateProduct() {
      return this.name.length == 0 || this.price == 0 || this.loading;
    },
  },
  methods: {
    getInfo() {
      let data;
      if (this.newImg === null) {
        const productInfo = {
          name: this.name,
          description: this.description,
          is_active: this.isActive,
          product_type: this.preparetionPlace,
          price: this.price,
          iva: this.iva / 100,
          discount_rate: this.discountRate / 100,
        };
        data = productInfo;
      } else {
        const productInfo = {
          name: this.name,
          description: this.description,
          is_active: this.isActive,
          product_type: this.preparetionPlace,
          price: this.price,
          iva: this.iva / 100,
          discount_rate: this.discountRate / 100,
          image: this.image,
        };
        data = productInfo;
      }
      return data;
    },
    setData() {
      this.name = this.product.name;
      this.description = this.product.description;
      this.isActive = this.product.is_active;
      this.price = this.product.product_price.price;
      this.iva = this.product.product_price.iva * 100;
      this.discountRate = this.product.product_price.discount_rate * 100;
      this.discountTotal = 100 * this.discountRate;
      this.image = this.product.image;
      this.productType = this.product.product_type;
    },
    setPreparationPlace(payload) {
      this.preparetionPlace = payload;
    },
    toggleDiscount() {
      this.applyDiscount.value = !this.applyDiscount.value;
    },
    setDiscountTotal() {
      this.discountRate = ((this.discountTotal * 100) / this.price).toFixed(1);
    },
    setDiscountRate() {
      this.discountTotal = (this.price * this.discountRate) / 100;
    },
    toggleIsActive(payload) {
      this.isActive = payload;
    },
    getUpdateInfo() {
      return {
        productId: this.product.id,
        // info: this.sameImg ? this.getInfo() : this.getInfoWithImage(),
        info: this.getInfo(),
      };
    },
    updateProduct() {
      this.loading = true;
      this.$store
        .dispatch("businessRequest", {
          action: "updateProduct",
          data: this.getUpdateInfo(),
        })
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.$store.commit("setPopUpMessage", "Producto Actualizado");
            this.update();
          } else {
            this.$store.commit("setPopUpMessage", "Algo salio mal :(");
          }
        });
    },
    update() {
      this.select(2);
      this.fetchProducts();
    },
    openModalDelete() {
      window.$("#modalDeleteProduct").modal("show");
    },
  },
  created() {
    this.setData();
  },
  unmounted() {
    this.$store.commit("setCurrentImg", null);
  },
};
</script>
<style scoped>
.product-details-grid {
  padding: 25px 30px;
  display: grid;
  grid-template-columns: 170px 220px 100px 54px;
  grid-template-rows: auto auto auto auto auto auto;
  grid-template-areas:
    "product-img product-name product-name product-name"
    "product-img product-description product-description product-description"
    "inventory price discount percent"
    "total total total total"
    "preparation-place preparation-place preparation-place preparation-place"
    "buttons buttons buttons buttons";
  gap: 20px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 5px;
  margin-top: 16px;
}
.item-a {
  grid-area: product-img;
}
.item-b {
  grid-area: product-name;
}
.item-c {
  grid-area: product-description;
}
.item-d {
  grid-area: inventory;
}
.item-e {
  grid-area: price;
}
.item-f {
  grid-area: discount;
}
.item-g {
  grid-area: percent;
}
.item-h {
  grid-area: total;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.item-i {
  grid-area: preparation-place;
}
.item-j {
  grid-area: buttons;
  display: flex;
  justify-content: space-between;
}
.input {
  display: flex;
  flex-direction: column;
}
label {
  font-weight: 700;
  margin-bottom: 8px;
}

.photo {
  display: flex;
  flex-direction: column;
  width: 170px;
}

.input textarea {
  height: 77px;
  resize: none;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 12px;
}
textarea:focus {
  outline: none;
  border: 1px solid #02c874;
}
.product-img {
  width: 100%;
}
.container-img {
  height: 170px;
  border: 1px dashed #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
}
.no-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}
.no-image p {
  width: 130px;
  font-size: 14px;
  margin-top: 30px;
}

.checkbox {
  height: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.select {
  width: 100%;
}
.percent-symbol {
  position: absolute;
  top: 44px;
  right: 15px;
}

.third {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.delete {
  color: #e94a47;
  background-color: transparent;
  border: none;
}
.buttons button {
  width: 132px;
}
.secondary-button {
  background-color: transparent;
  border: none;
}
@media screen and (max-width: 1439px) {
  .product-details-grid {
    padding: 25px 25px 30px 30px;
  }
}
@media screen and (max-width: 1359px) {
  .product-details-grid {
    padding: 25px 20px;
  }
}
@media screen and (max-width: 1023px) {
  .product-details-grid {
    padding: 16px 20px;
  }
  .container-img {
    width: 235px;
    height: 235px;
  }
  .select {
    width: auto;
    margin-bottom: 16px;
  }

  .buttons button {
    width: 117px;
  }
  .delete {
    margin-top: 20px;
  }
}
</style>
