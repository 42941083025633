import axios from "@/businessApi";

export const fetchTables = async ({ commit, rootState }) => {
  try {
    const response = await axios.get(
      `/r/${rootState.domainName}/v1/tables`,
      rootState.businessAuth
    );
    commit("setTables", response.data);
  } catch (error) {
    console.error(error);
  }
};
