<template>
  <div
    class="modal fade show"
    id="editOptionModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <button
          type="button"
          class="btn-close button-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <h5>Editar opciones</h5>
        <p class="gray">
          Las opciones son items que tu comensal puede o debe elegir al pedir
          este producto.
        </p>
        <div class="columns">
          <div class="new-option">
            <div class="input">
              <label>Nombre de la opción</label>
              <input
                type="text"
                placeholder="Ej: Tipo de proteína"
                v-model="name"
              />
            </div>
            <div class="card-box">
              <h3 class="sub-title">REGLAS</h3>
              <div class="check-box">
                <img
                  :src="required ? check : nocheck"
                  @click="toggleRequired"
                />
                <p>Se debe elegir un item</p>
              </div>
              <h6>¿Cuál es la máxima cantidad de items a seleccionar?</h6>
              <div class="counter">
                <button @click="lessCounter1">-</button>
                <div class="counter-value">{{ maxRequired }}</div>
                <button @click="moreCounter1">+</button>
              </div>
              <h6>¿Cuántas veces se puede seleccionar cada item?</h6>
              <div class="counter">
                <button @click="lessCounter2">-</button>
                <div class="counter-value">{{ selectionTimes }}</div>
                <button @click="moreCounter2">+</button>
              </div>
            </div>
          </div>
          <div class="options">
            <div class="input">
              <label>Items</label>
              <div class="search-bar">
                <img
                  class="search-icon"
                  src="@/assets/icons/search.svg"
                  alt="search-icon"
                />
                <input type="text" placeholder="Busca un item" />
              </div>
            </div>
            <div class="table">
              <div v-if="elections.length > 0" class="table-titles">
                <div class="first">NOMBRE</div>
                <div class="second">PRECIO</div>
              </div>
              <div v-if="renderList">
                <div v-for="(election, index) in elections" :key="index">
                  <editable-option-item
                    :EditOptionItem="EditOptionItem"
                    :deleteEditItemList="deleteEditItemList"
                    :id="index"
                    :election="election"
                  />
                </div>
                <div v-for="(election, index) in newElections" :key="index">
                  <new-option-item
                    :setNewOptionItem="setNewOptionItem"
                    :deleteItemList="deleteItemList"
                    :id="index"
                    :election="election"
                  />
                </div>
              </div>
            </div>
            <button class="add-item" @click="createItemList">
              <img src="@/assets/icons/add-product-icon.svg" alt="icon" />
              <p>Agregar items</p>
            </button>
            <div class="buttons">
              <button class="secondary-button" data-bs-dismiss="modal">
                Cancelar
              </button>
              <button
                class="primary-button"
                @click="updateOption"
                :disabled="disableButton"
                data-bs-dismiss="modal"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import iconNoCheck from "@/assets/icons/nocheck.svg";
import iconCheck from "@/assets/icons/check.svg";
import NewOptionItem from "@/components/menu/products/options/NewOptionItem.vue";
import EditableOptionItem from "@/components/menu/products/options/EditableOptionItem.vue";
export default {
  name: "ModalOptionEdit",
  props: {
    option: {
      type: Object,
      required: true,
    },
    reloadListOptions: {
      type: Function,
      required: true,
    },
  },
  components: {
    EditableOptionItem,
    NewOptionItem,
  },
  data() {
    return {
      maxRequired: 0,
      selectionTimes: 0,
      nocheck: iconNoCheck,
      check: iconCheck,
      renderList: true,
      name: "",
      required: false,
      elections: [],
      electionsFields: [],
      newElections: [],
      newElectionsFields: [],
    };
  },
  computed: {
    disableButton() {
      return this.name.length == 0 || this.checkItemsFields || this.checkLists;
    },
    checkItemsFields() {
      return (
        this.electionsFields.includes(false) ||
        this.newElectionsFields.includes(false)
      );
    },
    checkLists() {
      return this.elections.length == 0 && this.newElections.length == 0;
    },
  },
  methods: {
    moreMaxRequired() {
      if (this.maxRequired == 99) {
        this.maxRequired = 99;
      } else {
        this.maxRequired++;
      }
    },
    lessMaxRequired() {
      if (this.maxRequired == 0) {
        this.maxRequired = 0;
      } else {
        this.maxRequired--;
      }
    },
    moreSelectionTimes() {
      if (this.selectionTimes == 99) {
        this.selectionTimes = 98;
      } else {
        this.selectionTimes++;
      }
    },
    lessSelectionTimes() {
      if (this.selectionTimes == 0) {
        this.selectionTimes = 0;
      } else {
        this.selectionTimes--;
      }
    },
    toggleRequired() {
      this.required = !this.required;
    },
    setNewOptionItem(payload) {
      this.newElections[payload.id] = payload.data;
      this.checkNewElectionFields(payload);
    },
    EditOptionItem(payload) {
      this.elections[payload.id] = payload.data;
      this.checkElectionFields(payload);
    },
    checkElectionFields(payload) {
      this.electionsFields[payload.id] = payload.fields;
    },
    checkNewElectionFields(payload) {
      this.newElectionsFields[payload.id] = payload.fields;
    },
    createItemList() {
      this.newElections.push({ name: "", price: "" });
    },
    deleteItemList(payload) {
      this.newElections.splice(payload, 1);
      this.newElectionsFields.splice(payload, 1);
      this.reloadList();
    },
    deleteEditItemList(payload) {
      this.elections.splice(payload, 1);
      this.electionsFields.splice(payload, 1);
      this.reloadList();
    },
    async reloadList() {
      this.renderList = false;
      await this.$nextTick();
      this.renderList = true;
    },
    updateOption() {
      this.$store
        .dispatch("businessRequest", {
          action: "updateOption",
          data: {
            option: {
              name: this.name,
              required: this.required,
              elections: this.elections,
              new_elections: this.newElections,
              max_required: this.maxRequired,
              selection_times: this.selectionTimes,
            },
            id: this.option.id,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$store.commit("setPopUpMessage", "Opcion actualizada");
            this.$store
              .dispatch("businessRequest", {
                action: "fetchProduct",
                data: this.$store.getters.getCurrentProduct.id,
              })
              .then(() => {
                this.reloadListOptions();
              });
          } else {
            this.$store.commit("setPopUpMessage", "Algo salio mal :(");
          }
        });
    },
  },
  watch: {
    option() {
      this.name = this.option.name;
      this.required = this.option.required;
      this.elections = this.option.election;
      this.maxRequired = this.option.max_required;
      this.selectionTimes = this.option.selection_times;
      this.reloadList();
    },
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 869px;
}
.modal-content {
  width: 869px;
  color: #4f4f4f;
  text-align: start;
  padding: 38px 34px 24px;
}
.button-close {
  position: absolute;
  background-color: transparent;
  border: none;
  top: 29px;
  right: 34px;
}
.columns {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}
.new-option {
  width: 310px;
}
.card-box {
  background-color: #f7f7f7;
  padding: 12px 17px 26px;
  border-radius: 5px;
}
.check-box {
  display: flex;
  align-items: center;
}
.check-box img {
  margin-right: 14px;
}
h6 {
  margin-top: 16px;
}
.options {
  width: 468px;
}
.search-bar {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.search-bar input {
  width: 100%;
  padding-left: 45px;
}
.search-icon {
  position: absolute;
  left: 15px;
}
.table-titles {
  display: flex;
}
.first {
  width: 235px;
  margin-right: 22px;
  margin-bottom: 12px;
}
.second {
  width: 97px;
  margin-right: 10px;
}
h1 {
  margin-bottom: 12px;
}
.gray {
  color: #828282;
}
.sub-title {
  color: #828282;
  margin-bottom: 14px;
}
.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.counter {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.counter button {
  width: 18px;
  height: 18px;
  background-color: #333333;
  color: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.counter-value {
  height: 23px;
  width: 23px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
label {
  font-weight: 700;
  margin-bottom: 8px;
}
.add-item {
  border: none;
  background-color: transparent;
  color: #02c874;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}
.add-item p {
  margin-left: 10px;
  color: #02c874;
}
.inputs {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
}
.buttons {
  position: absolute;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-self: end;
  bottom: 24px;
  right: 34px;
}
.buttons button {
  width: 112px;
}
.secondary-button {
  background-color: transparent;
  border: none;
}
@media screen and (max-width: 1439px) {
  .modal-body {
    width: 822px;
    padding: 25px 20px 15px 25px;
  }
  .options {
    width: 445px;
  }
}
@media screen and (max-width: 1359px) {
  .modal-body {
    width: 822px;
  }
}
@media screen and (max-width: 1023px) {
  .modal-content {
    width: 280px;
    padding: 12px 14px 25px;
  }
  .button-close {
    top: 14px;
    right: 10px;
  }
  .columns {
    flex-direction: column;
  }
  .new-option,
  .options {
    width: 253px;
  }
  .table-titles {
    display: none;
  }
  .first,
  .second {
    width: 176px;
    margin-right: 10px;
  }
  .add-item {
    margin: 0;
  }
  .buttons {
    position: relative;
    bottom: 0;
    left: 0;
    justify-content: center;
  }
}
</style>
