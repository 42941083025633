import api from "@/api";
import axios from "@/businessApi";
import getToday from "@/helpers/getToday";

export const fetchTotalOrders = ({ commit, rootState }) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${
          rootState.domainName
        }/v1/orders/get_total_order?date=${getToday()}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTotal", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchTotalCard = ({ commit, rootState }) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${
          rootState.domainName
        }/v1/orders/get_total_card?date=${getToday()}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTotalCard", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchTotaltransfer = ({ commit, rootState }) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${
          rootState.domainName
        }/v1/orders/get_total_transfer?date=${getToday()}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTotalTransfer", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
