<template>
  <div class="test">
    <h1>Pantalla de Prueba</h1>
    <ProductDetails style="max-width: 768px" :product="exampleProduct" />
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
// import { useQuasar } from "quasar";
export default {
  components: {
    ProductDetails: defineAsyncComponent(() =>
      import("@/modules/business/modules/menu/components/ProductDetails")
    ),
  },
  data() {
    return {
      items: [
        { name: "hola mundo", deshabilitar: false },
        { name: "hola mundo", deshabilitar: false },
        { name: "hola mundo", deshabilitar: false },
        { name: "hola mundo", deshabilitar: false },
        { name: "hola mundo", deshabilitar: false },
        { name: "hola mundo", deshabilitar: false },
        { name: "hola mundo", deshabilitar: false },
        { name: "hola mundo", deshabilitar: false },
        { name: "hola mundo", deshabilitar: false },
        { name: "hola mundo", deshabilitar: false },
        { name: "hola mundo", deshabilitar: false },
      ],
      exampleProduct: {
        id: 3,
        name: "Patatine Fritte",
        image: null,
        description:
          "400g. de papas rústicas della casa con ragú napoletano, provola affumicata, albahaca orgánica & pecorino romano.",
        is_active: true,
        allergens: [],
        category: 1,
        total_price: 26000.0,
        product_price: {
          id: 3,
          price: 26000.0,
          iva: 0.0,
          discount_rate: 0.0,
          crated_at: "2023-05-15T09:25:03.912835-05:00",
          updated_at: "2023-05-15T09:25:03.912849-05:00",
          product: 3,
        },
        options: [],
        additions: [],
        product_type: 1,
        position: 0,
      },

      slideActivo: 0,
      activeChange: false,
    };
  },
  methods: {
    irSiguiente() {
      this.$refs.slides.scrollLeft =
        this.$refs.slides.scrollLeft + this.$refs.slides.offsetWidth;
    },
    irAnterior() {
      this.$refs.slides.scrollLeft =
        this.$refs.slides.scrollLeft - this.$refs.slides.offsetWidth;
    },
    otro() {
      console.log("otro");
    },
    // changes() {
    //   if (activeChanges) {
    //   }
    // },
  },
  setup() {
    // const $q = useQuasar();
    // function alert() {
    //   $q.dialog({
    //     title: "Alert",
    //     message: "Some message",
    //   })
    //     .onOk(() => {
    //       // console.log('OK')
    //     })
    //     .onCancel(() => {
    //       // console.log('Cancel')
    //     })
    //     .onDismiss(() => {
    //       // console.log('I am triggered on both OK and Cancel')
    //     });
    // }
    // return { alert };
  },
};
</script>
<style scoped>
.test {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carrusel {
  max-width: 900px;
}
.slides {
  max-width: 900px;
  background-color: aliceblue;
  height: 300px;
  display: flex;
  gap: 20px;
  overflow: hidden;
  scroll-behavior: smooth;
}
.item {
  background-color: blue;
  width: 280px;
  height: 100%;
}
.anterior,
.siguiente {
  width: 50px;
}
</style>
