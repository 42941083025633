<template>
  <div class="new-category-information">
    <div class="input">
      <label for="">Nombre de la categoría</label>
      <p>{{ currentCategory.name }}</p>
    </div>
    <div class="input">
      <label for="">Horarios</label>
      <p>Siempre disponible</p>
    </div>
    <div class="buttons">
      <button class="edit-btn" @click="openModalEdit">
        <img src="@/assets/icons/edit-category.svg" alt="edit-icon" /> Editar
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewCategoryInformation",
  props: {
    cancel: {
      type: Function,
      required: true,
    },
    reload: {
      type: Function,
      required: true,
    },
    currentCategory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      daysButtons: [
        { text: "L", active: false, id: 0, value: "Lunes" },
        { text: "M", active: false, id: 1, value: "Martes" },
        { text: "M", active: false, id: 2, value: "Miercoles" },
        { text: "J", active: false, id: 3, value: "Jueves" },
        { text: "V", active: false, id: 4, value: "Viernes" },
        { text: "S", active: false, id: 5, value: "Sabado" },
        { text: "D", active: false, id: 6, value: "Domingo" },
      ],
      name: "",
      list: {
        title: "Horario",
        options: [
          {
            name: "Siempre",
            active: true,
            id: 0,
          },
          {
            name: "Algunas veces",
            active: false,
            id: 1,
          },
        ],
      },
      always_available: true,
      days: [],
      startTime: "",
      finalTime: "",
      startTimeServer: "",
      finalTimeServer: "",
      schedules: [],
    };
  },
  methods: {
    openModalEdit() {
      window.$("#modalEditCategory").modal("show");
    },
  },
  mounted() {
    this.name = this.currentCategory.name;
  },
};
</script>

<style scoped>
.edit-btn {
  color: var(--primary);
  border: none;
  background-color: transparent;
  height: 30px;
  width: 120px;
  text-align: start;
}
.edit-btn img {
  margin-right: 10px;
}
.new-category-information {
  width: 396px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 5px;
  padding: 26px 22px;
  display: flex;
  flex-direction: column;
}
.inputs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputs input {
  width: 119px;
}
.separator {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 100%;
}
.input input {
  margin-top: 7px;
}
.radio {
  display: flex;
  align-items: center;
}
label {
  font-weight: 700;
}
.days {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 16px;
}
.day {
  height: 32.5px;
  width: 32.5px;
  background: #f2f2f2;
  border-radius: 100%;
  border: none;
}
.active {
  background-color: transparent;
  border: 1px solid #02c874;
  color: #02c874;
}
.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.buttons button {
  height: 45px;
}
.secondary-button {
  background-color: transparent;
  border: none;
}
p {
  width: 280px;
  margin-top: 8px;
  line-height: 150%;
}
.schedule {
  width: 100%;
  height: 45px;
  background-color: #fff;
  border: 1px solid #02c874;
  color: #02c874;
  border-radius: 5px;
  margin: 16px 0;
}
.schedule:disabled {
  border: 1px solid #828282;
  color: #828282;
}
@media screen and (max-width: 1439px) {
  .new-category-information {
    width: 465px;
  }
  p {
    width: auto;
  }
  .inputs input {
    width: 165px;
  }
  .separator {
    width: 87px;
  }
}
@media screen and (max-width: 1359px) {
  .new-category-information {
    width: 280px;
  }
  .separator {
    width: 20px;
    color: transparent;
  }
  .inputs input {
    width: 119px;
  }
  .buttons button {
    width: 112px;
  }
}
</style>
