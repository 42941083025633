<template>
  <input v-if="disable" type="text" disabled />
  <div v-else class="select-option">
    <input
      :id="uniqueId"
      type="text"
      @focus="filterOptions"
      @input="filterOptions"
      @blur="blur"
      v-model="search"
    />
    <div v-if="currentOption.name" class="current-option">
      {{ currentOption.name }}
    </div>
    <div v-else class="current-option">{{ currentOption.iso }}</div>
    <button v-if="disableInput" class="btn-close" @click="reset"></button>
    <div v-if="list" class="container-list shadow">
      <div v-for="option in filteredList" :key="option.id">
        <div class="option" @click="setOption(option)">
          <p v-if="option.name">{{ option.name }}</p>
          <p v-else>{{ option.iso }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectOption",
  props: {
    options: {
      type: Object,
      required: true,
    },
    uniqueId: {
      type: String,
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
    initialValue: {
      type: Object,
      required: true,
    },
    disable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      list: false,
      disableInput: false,
      currentOption: "",
      filteredList: "",
      search: "",
    };
  },
  methods: {
    filterOptions() {
      this.list = true;
      if (this.search === "") {
        this.filteredList = JSON.parse(JSON.stringify(this.options));
      } else {
        this.filteredList = JSON.parse(JSON.stringify(this.options));
        const payload = this.search.toLowerCase();
        this.filteredList = this.filteredList.filter((option) => {
          return option.name.toLowerCase().indexOf(payload) > -1;
        });
      }
    },
    setOption(payload) {
      this.currentOption = payload;
      this.list = false;
      this.disableInput = true;
      this.select(payload);
    },
    blur() {
      setTimeout(() => {
        this.list = false;
      }, 100);
    },
    reset() {
      this.currentOption = "";
      this.select(null);
      this.disableInput = false;
      const select = document.getElementById(this.uniqueId);
      setTimeout(() => {
        select.focus();
      }, 100);
    },
  },
  mounted() {
    if (this.initialValue) {
      this.setOption(this.initialValue);
    }
  },
};
</script>
<style scoped>
.select-option {
  position: relative;
}
input {
  width: 100%;
}
.btn-close {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
}
.current-option {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%);
}
.option {
  padding: 0 5px;
}
.option:hover {
  background-color: #02c874;
  color: #fff;
}
.container-list {
  border: 1px solid rgb(150, 150, 150);
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  position: absolute;
  z-index: 90;
}
</style>
