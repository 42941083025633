<template>
  <div
    class="modal fade show d-block"
    id="modalCropImg"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Sube una imagen</h5>
          <button
            type="button"
            class="btn-close"
            @click="$emit('close')"
          ></button>
        </div>
        <div class="modal-body">
          <input
            type="file"
            class="d-none"
            accept="image/png, image/jpeg"
            ref="fileInput"
            @change="onFilePicked"
          />
          <button
            class="upload-button"
            v-if="imageUrl == null"
            @click="onPickFile"
          >
            Haga click aquí para subir una imagen
          </button>
          <img-cropper
            v-else
            :ratio="aspectRatio"
            :img="imageUrl"
            :reset="reset"
            @close="$emit('close')"
          />
          <div v-if="imageUrl == null" class="buttons">
            <button class="tertiary-button" @click="$emit('close')">
              Cancelar
            </button>
            <button class="primary-button" disabled>Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgCropper from "@/components/tools/ImgCropper.vue";
export default {
  props: {
    aspectRatio: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  components: {
    ImgCropper,
  },
  data() {
    return {
      imageUrl: null,
    };
  },
  methods: {
    reset() {
      this.imageUrl = null;
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      this.files = event.target.files;
      if (this.files[0].size > 1000000) {
        this.files = "";
        this.warnImg = true;
      } else {
        this.warnImg = false;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageUrl = fileReader.result;
        });
        fileReader.readAsDataURL(this.files[0]);
        const [image] = this.files;
        this.image = image;
      }
    },
  },
};
</script>
<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.3);
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.buttons button {
  width: 150px;
  margin-left: 20px;
}
.tertiary-button {
  background-color: #fff;
  color: #4f4f4f;
}
.tertiary-button:hover {
  background-color: #fff;
}
.upload-button {
  background: #f9f9f9;
  border: 2px dashed #bdbdbd;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
}
@media screen and (max-width: 425px) {
  .buttons {
    justify-content: center;
  }
}
</style>
