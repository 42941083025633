<template>
  <div class="container-boxes">
    <div :class="!seeDetails ? 'column1 see' : 'column1'">
      <h3 class="column-title">Categorías y productos</h3>
      <div v-if="showNewCategory" class="categories-list">
        <new-category />
      </div>
      <div v-if="categories.length > 0" class="categories-list">
        <div v-for="category in categories" :key="category.name">
          <category-button
            :category="category"
            :select="select"
            :setCurrentCategory="setCurrentCategory"
            :currentCategory="currentCategory"
          />
        </div>
      </div>
      <add-category-button @click="createNewCategory" />
    </div>
    <div v-if="showInfoBox">
      <div class="info-box">
        <p>
          Crea una categoría y productos para comenzar a visualizar información
          sobre tu menú. Si quieres que creemos tu menú por ti, contáctanos a
          soporte@nelkir.com
        </p>
      </div>
    </div>
    <div :class="seeDetails ? 'column2 see' : 'column2'" v-else>
      <h3 class="column-title">Información</h3>
      <h3 class="see">
        <button @click="backToList">Categorías</button> > Información
      </h3>
      <category-information v-if="showCategoryInfo" />
      <new-category-information
        v-if="showNewCategory"
        :cancel="cancelNewCategory"
        :reload="fetchCategories"
      />
      <product-information
        v-if="showProductDetails && renderProduct"
        :product="currentProduct"
      />
      <new-product-details
        v-if="showNewProductDetails"
        :categoryId="currentCategory"
        :select="select"
        :fetchProducts="fetchProducts"
      />
    </div>
  </div>
</template>
<script>
import AddCategoryButton from "@/components/menu/categories/AddCategoryButton.vue";
import CategoryButton from "@/components/menu/categories/CategoryButton.vue";
import CategoryInformation from "@/components/menu/categories/CategoryInformation.vue";
import ProductInformation from "@/views/menu/ProductInformation.vue";
import NewCategory from "@/components/menu/categories/NewCategory.vue";
import NewCategoryInformation from "@/components/menu/categories/NewCategoryInformation.vue";
import NewProductDetails from "@/components/menu/products/NewProductDetails.vue";
export default {
  components: {
    AddCategoryButton,
    CategoryButton,
    CategoryInformation,
    ProductInformation,
    NewCategory,
    NewCategoryInformation,
    NewProductDetails,
  },
  data() {
    return {
      categories: "",
      currentCategory: "",
      currentSelect: 0,
      seeDetails: false,
      createCategory: false,
      creatingCategory: [],
      renderProduct: true,
    };
  },
  computed: {
    showInfoBox() {
      return this.currentSelect == 0;
    },
    showNewCategory() {
      return this.currentSelect == 1;
    },
    showCategoryInfo() {
      return this.currentSelect == 2;
    },
    showProductDetails() {
      return this.currentSelect == 3;
    },
    showNewProductDetails() {
      return this.currentSelect == 4;
    },
  },
  methods: {
    select(payload) {
      this.currentSelect = payload;
      this.seeDetails = true;
      if (payload == 3) {
        this.reloadProductInformation();
      }
    },
    async reloadProductInformation() {
      this.renderProduct = false;
      await this.$nextTick();
      this.renderProduct = true;
    },
    setCurrentCategory(payload) {
      this.currentCategory = payload;
    },
    backToList() {
      this.seeDetails = false;
    },
    fetchCategories() {
      this.$store
        .dispatch("businessRequest", {
          action: "fetchCategories",
        })
        .then((response) => {
          if (response.status == 200) {
            this.categories = response.data;
          }
        })
        .finally(() => this.fetchProducts());
    },
    createNewCategory() {
      if (this.creatingCategory > 0) {
        this.$store.commit(
          "setPopUpMessage",
          "Aún tiene un categoria sin guardar"
        );
      } else {
        this.creatingCategory = {
          name: "Nueva Categoria",
        };
        this.currentSelect = 1;
      }
    },
    cancelNewCategory() {
      this.creatingCategory = [];
      this.currentSelect = 0;
    },
    fetchProducts() {
      this.$store.dispatch("businessRequest", {
        action: "fetchProducts",
      });
    },
  },
  mounted() {
    this.fetchCategories();
  },
};
</script>
<style scoped>
.container-boxes {
  display: flex;
  margin-top: 19px;
  text-align: start;
}
.info-box {
  width: 370px;
  height: 143px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 5px;
  padding: 23px;
  margin-top: 35px;
}
.info-box p {
  line-height: 150%;
}
.categories-list {
  margin-top: 16px;
}
.see {
  display: none;
}
.column1 {
  display: block;
}
.column2 {
  display: block;
}
h3 {
  color: #4f4f4f;
}
h3 button {
  border: none;
  background-color: transparent;
  margin-top: 16px;
}
@media screen and (max-width: 1439px) {
  .info-box {
    width: 352px;
    padding: 22px 15px;
  }
}
@media screen and (max-width: 1359px) {
  .info-box {
    width: 395px;
  }
}
@media screen and (max-width: 1023px) {
  .container-boxes {
    flex-direction: column;
    margin-top: 0;
  }
  .column-title {
    margin-top: 19px;
  }
  .info-box {
    width: 280px;
    height: 186px;
    padding: 22px 15px 18px;
  }
  .column1 {
    display: none;
  }
  .column2 {
    display: none;
  }
  .column-title {
    display: none;
  }
  .see {
    display: block;
  }
}
</style>
