<template>
  <div class="history-view">
    <h3>Historial</h3>
    <div class="history-head">
      <p>Conoce tu historial de facturas</p>
      <n-button @click="printAll"> Descargar Excel </n-button>
    </div>
    <div class="inputs">
      <div class="input">
        <label for="">Fecha desde</label>
        <input type="date" v-model="dateStart" @change="filterByDate" />
      </div>
      <div class="input">
        <label for="">Fecha hasta</label>
        <input type="date" v-model="dateEnd" @change="filterByDate" />
      </div>
      <div class="input">
        <label for="">Administrador</label>
        <select-option />
      </div>
      <div class="input">
        <label for="">Buscar factura</label>
        <input type="text" v-model="numberForFilter" @input="filterByNumber" />
      </div>
    </div>
  </div>
  <div class="lots">
    <lot :bills="billsFiltered" />
  </div>
</template>
<script>
import XLSX from "xlsx";
import NButton from "@/components/tools/buttons/NelkirButton.vue";
import Lot from "@/components/branchView/orders/history/Lot.vue";
import SelectOption from "@/components/tools/SelectOption.vue";
import { mapGetters } from "vuex";
export default {
  name: "HistoryView",
  components: {
    NButton,
    Lot,
    SelectOption,
  },
  data() {
    return {
      cols: [
        { name: "A", key: 0 },
        { name: "B", key: 1 },
        { name: "C", key: 2 },
      ],
      data: [
        ["id", "name", "value"],
        [1, "sheetjs", 7262],
        [2, "js-xlsx", 6969],
      ],
      products: [
        { name: "Pollo", id: 1, description: "algo", price: 1000 },
        { name: "Carne", id: 2, description: "algo", price: 1000 },
        { name: "Harina", id: 3, description: "algo", price: 1000 },
        { name: "Sopa", id: 4, description: "algo", price: 1000 },
        { name: "Pan", id: 5, description: "algo", price: 1000 },
        { name: "Verdura", id: 6, description: "algo", price: 1000 },
      ],
      billsFiltered: "",
      numberForFilter: "",
      dateStart: "",
      dateEnd: "",
    };
  },
  computed: {
    ...mapGetters({
      bills: "getOrders",
    }),
  },
  methods: {
    filterByNumber() {
      if (this.numberForFilter === "") {
        this.billsFiltered = this.bills;
      } else {
        this.billsFiltered = this.bills.filter(
          (bill) => bill.id === this.numberForFilter
        );
      }
    },
    createSheet() {
      const worksheet = XLSX.utils.aoa_to_sheet(this.parseToArray());
      const new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(new_workbook, "sheetjs.xlsx");
    },
    parseToArray() {
      const newArray = [];
      newArray[0] = ["Id", "Nombre", "Descripcion", "Precio"];
      for (let index = 0; index < this.products.length; index++) {
        const element = this.products[index];
        newArray.push([
          element.id,
          element.name,
          element.description,
          element.price,
        ]);
      }
      return newArray;
    },
    getAllProducts() {
      const allProducts = [];
      for (let index = 0; index < this.bills.length; index++) {
        const bill = this.bills[index];
        for (let index2 = 0; index2 < bill.order_products.length; index2++) {
          const product = bill.order_products[index2];
          allProducts.push(product);
        }
      }
      return allProducts;
    },
    printAll() {
      const newArray = [];
      const products = this.getAllProducts();
      newArray[0] = [
        "Fecha",
        "Número Factura",
        "Vendedor",
        "Producto",
        "Precio con IVA",
        "Precio sin IVA",
        "Descuento Total",
        "Método de pago",
      ];
      for (let index = 0; index < products.length; index++) {
        const element = products[index];
        newArray.push([
          element.date,
          element.id,
          element.seller,
          element.product_name,
          element.product_price.price * 1.08,
          element.product_price.price,
          element.discount,
          element.paid_method,
        ]);
      }
      const worksheet = XLSX.utils.aoa_to_sheet(newArray);
      const new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(new_workbook, "sheetjs.xlsx");
    },
  },
  mounted() {
    this.filterByNumber();
  },
};
</script>
<style scoped>
.history-view {
  text-align: start;
}

h3 {
  margin-bottom: 14px;
}
.history-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.history-head button {
  height: 45px;
  width: 150px;
}

.inputs {
  display: grid;
  grid-template-columns: 250px 250px 250px 250px;
  column-gap: 20px;
  align-items: end;
}

.input label {
  font-weight: 700;
  margin-bottom: 8px;
}

.input input {
  width: 100%;
  padding-right: 10px;
}

.lots {
  margin-top: 22px;
  height: calc(100vh - 279px);
}

@media screen and (max-width: 1439px) {
}
@media screen and (max-width: 1359px) {
  .inputs {
    grid-template-columns: 180px 180px 180px 180px;
  }
}
@media screen and (max-width: 1023px) {
  .inputs {
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
  }
}
@media screen and (max-width: 767px) {
  .history-head {
    display: grid;
    gap: 16px;
  }
  .inputs {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    row-gap: 20px;
  }
}
</style>
