import api from "@/api";
import axios from "axios";

const payments = {
  state: {
    paymentTypes: null,
  },
  mutations: {
    setPaymentTypes(state, payload) {
      state.paymentTypes = payload.data;
    },
  },
  actions: {
    fetchPaymentTypes({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.domainName}/v1/payment-types`,
            rootState.businessAuth
          )
          .then(
            (response) => {
              commit("setPaymentTypes", response);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
  },
  getters: {
    getPaymentTypes(state) {
      return state.paymentTypes;
    },
  },
};

export default payments;
