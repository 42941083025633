<template>
  <div class="loadingio-spinner-spin-nwc0mljd0nj">
    <div class="ldio-kbw6nothdy">
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
    </div>
  </div>
</template>
<style type="text/css">
@keyframes ldio-kbw6nothdy {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5, 1.5);
  }
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
.ldio-kbw6nothdy div > div {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #000000;
  animation: ldio-kbw6nothdy 1s linear infinite;
}
.ldio-kbw6nothdy div:nth-child(1) > div {
  left: 87px;
  top: 47px;
  animation-delay: -0.9166666666666666s;
}
.ldio-kbw6nothdy > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 90px 50px;
}
.ldio-kbw6nothdy div:nth-child(2) > div {
  left: 82px;
  top: 67px;
  animation-delay: -0.8333333333333334s;
}
.ldio-kbw6nothdy > div:nth-child(2) {
  transform: rotate(30deg);
  transform-origin: 85px 70px;
}
.ldio-kbw6nothdy div:nth-child(3) > div {
  left: 67px;
  top: 82px;
  animation-delay: -0.75s;
}
.ldio-kbw6nothdy > div:nth-child(3) {
  transform: rotate(60deg);
  transform-origin: 70px 85px;
}
.ldio-kbw6nothdy div:nth-child(4) > div {
  left: 47px;
  top: 87px;
  animation-delay: -0.6666666666666666s;
}
.ldio-kbw6nothdy > div:nth-child(4) {
  transform: rotate(90deg);
  transform-origin: 50px 90px;
}
.ldio-kbw6nothdy div:nth-child(5) > div {
  left: 27px;
  top: 82px;
  animation-delay: -0.5833333333333334s;
}
.ldio-kbw6nothdy > div:nth-child(5) {
  transform: rotate(120deg);
  transform-origin: 30px 85px;
}
.ldio-kbw6nothdy div:nth-child(6) > div {
  left: 12px;
  top: 67px;
  animation-delay: -0.5s;
}
.ldio-kbw6nothdy > div:nth-child(6) {
  transform: rotate(150deg);
  transform-origin: 15px 70px;
}
.ldio-kbw6nothdy div:nth-child(7) > div {
  left: 7px;
  top: 47px;
  animation-delay: -0.4166666666666667s;
}
.ldio-kbw6nothdy > div:nth-child(7) {
  transform: rotate(180deg);
  transform-origin: 10px 50px;
}
.ldio-kbw6nothdy div:nth-child(8) > div {
  left: 12px;
  top: 27px;
  animation-delay: -0.3333333333333333s;
}
.ldio-kbw6nothdy > div:nth-child(8) {
  transform: rotate(210deg);
  transform-origin: 15px 30px;
}
.ldio-kbw6nothdy div:nth-child(9) > div {
  left: 27px;
  top: 12px;
  animation-delay: -0.25s;
}
.ldio-kbw6nothdy > div:nth-child(9) {
  transform: rotate(240deg);
  transform-origin: 30px 15px;
}
.ldio-kbw6nothdy div:nth-child(10) > div {
  left: 47px;
  top: 7px;
  animation-delay: -0.16666666666666666s;
}
.ldio-kbw6nothdy > div:nth-child(10) {
  transform: rotate(270deg);
  transform-origin: 50px 10px;
}
.ldio-kbw6nothdy div:nth-child(11) > div {
  left: 67px;
  top: 12px;
  animation-delay: -0.08333333333333333s;
}
.ldio-kbw6nothdy > div:nth-child(11) {
  transform: rotate(300deg);
  transform-origin: 70px 15px;
}
.ldio-kbw6nothdy div:nth-child(12) > div {
  left: 82px;
  top: 27px;
  animation-delay: 0s;
}
.ldio-kbw6nothdy > div:nth-child(12) {
  transform: rotate(330deg);
  transform-origin: 85px 30px;
}
.loadingio-spinner-spin-nwc0mljd0nj {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background: #fff;
}
.ldio-kbw6nothdy {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-kbw6nothdy div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
</style>
