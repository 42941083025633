<template>
  <div class="config">
    <header-client />
    <div class="layout-config">
      <account :user="user" />
    </div>
  </div>
</template>
<script>
import HeaderClient from "@/components/HeaderClient.vue";
import Account from "@/views/Account.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "config-view",
  components: {
    HeaderClient,
    Account,
  },
  computed: {
    ...mapGetters(["getMainUser"]),
  },
  data() {
    return {
      loading: false,
      user: null,
    };
  },
  methods: {
    ...mapActions(["fetchMainUser"]),
  },
  created() {
    this.loading = true;
    this.fetchMainUser()
      .then((resp) => {
        const {
          first_name,
          last_name,
          gender,
          username,
          phone_number,
          id,
          email,
          city,
        } = resp.data;
        this.user = {
          first_name,
          last_name,
          gender,
          username,
          phone_number,
          id,
          email,
          city,
        };
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
<style scoped>
.config {
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layout-config {
  width: 100%;
  max-width: 1176px;
}
</style>
