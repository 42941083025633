<template>
  <div>
    <div class="d-flex mt-2 flex-wrap">
      <div
        :class="has_two_numbers ? 'pill-condition success' : 'pill-condition'"
      >
        <img
          v-if="has_two_numbers"
          src="@/assets/icons/check-white.svg"
          alt=""
        />
        2 números
      </div>
      <div :class="has_uppercase ? 'pill-condition success' : 'pill-condition'">
        <img v-if="has_uppercase" src="@/assets/icons/check-white.svg" alt="" />
        1 Mayúscula
      </div>
      <div
        :class="has_minimum_lenth ? 'pill-condition success' : 'pill-condition'"
      >
        <img
          v-if="has_minimum_lenth"
          src="@/assets/icons/check-white.svg"
          alt=""
        />
        8 Carácteres
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PasswordVerify",
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      has_minimum_lenth: false,
      has_uppercase: false,
      has_two_numbers: false,
    };
  },
  watch: {
    password() {
      this.has_minimum_lenth = this.password.length > 7;
      this.has_two_numbers = this.password.replace(/[^0-9]/g, "").length > 1;
      this.has_uppercase = this.password.replace(/[^A-Z]/g, "").length > 0; // eslint-disable-line
    },
  },
};
</script>
<style scoped>
.pill-condition {
  background-color: #e0e0e0;
  border-radius: 60px;
  padding: 0 10px;
  margin-right: 10px;
}
.success {
  background-color: #02c874;
  color: #fff;
}
</style>
