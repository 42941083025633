<template>
  <div>
    <header>
      <img src="@/assets/images/logo-green.svg" alt="" />
    </header>
    <div class="onboarding">
      <div class="carrousel">
        <div class="handlers">
          <button :disabled="currentStep == 0" @click="previousStep">
            <div class="arrow-left"></div>
          </button>
          <button :disabled="currentStep == 3" @click="nextStep">
            <div class="arrow-right"></div>
          </button>
        </div>
        <div class="stepper">
          <div :class="currentStep == 0 ? 'step active' : 'step'"></div>
          <div :class="currentStep == 1 ? 'step active' : 'step'"></div>
          <div :class="currentStep == 2 ? 'step active' : 'step'"></div>
          <div :class="currentStep == 3 ? 'step active' : 'step'"></div>
        </div>
        <transition name="fade">
          <div v-if="currentStep == 0" class="carrousel-item">
            <img src="@/assets/images/welcome.png" alt="" />
            <div class="carrousel-text">
              <h1>Bievenido a Nelkir</h1>
              <p>
                Antes de comenzar, queremos que conozcas dónde encontrar
                nuestras funciones
              </p>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="currentStep == 1" class="carrousel-item">
            <img src="@/assets/images/shops.png" alt="" />
            <div class="carrousel-text">
              <h1>Tiendas</h1>
              <p class="small-text">
                Crea tus tiendas y dentro de ellas, crea tus sedes
              </p>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="currentStep == 2" class="carrousel-item">
            <img src="@/assets/images/users.png" alt="" />
            <div class="carrousel-text">
              <h1>Usuarios</h1>
              <p>
                Crea usuarios y controla los permisos de los componentes a los
                cuales tienen acceso
              </p>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="currentStep == 3" class="carrousel-item">
            <img src="@/assets/images/menu.png" alt="" />
            <div class="carrousel-text">
              <h1>Menú</h1>
              <p>
                Manten tu menú actualizado, podrás crear categorías con tus
                productos y productos con opciones de ingredientes
              </p>
              <button class="primary-button" @click="toDashboard">
                Comenzar
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "onboarding-view",
  data() {
    return {
      currentStep: 0,
    };
  },
  methods: {
    nextStep() {
      if (this.currentStep == 3) {
        this.currentStep = 3;
      } else {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep == 0) {
        this.currentStep = 0;
      } else {
        this.currentStep--;
      }
    },
    toDashboard() {
      this.$router.push({ name: "businesses" });
    },
  },
};
</script>
<style scoped>
.fade-enter-active {
  transition: opacity 0.5s ease;
  transition-delay: 0.5s;
}
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
header {
  position: absolute;
  top: 0;
  display: flex;
  padding: 0 20px;
  height: 70px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
}
header img {
  width: 87px;
}
.onboarding {
  height: 100vh;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.carrousel {
  height: 388px;
  width: 452px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.carrousel-item {
  position: absolute;
  z-index: 97;
  top: 0;
  width: 100%;
}
.carrousel-text {
  position: absolute;
  height: 181px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1 {
  color: #02c874;
}
p {
  width: 354px;
}
.small-text {
  width: 262px;
}
.stepper {
  width: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
}
.step {
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #333333;
  opacity: 0.5;
  transition: ease 0.25s;
}
.active {
  opacity: 1;
  height: 10px;
  width: 10px;
}
.handlers {
  position: absolute;
  width: 446px;
  display: flex;
  justify-content: space-between;
  z-index: 99;
}
.handlers button {
  height: 20px;
  width: 20px;
  background-color: #333333;
  border: none;
  border-radius: 100%;
  padding: 0;
  position: relative;
}
.handlers button:disabled {
  opacity: 0.5;
}
.arrow-left {
  border-top: 5px solid transparent;
  border-right: 6px solid #faf9f9;
  border-bottom: 5px solid transparent;
  position: absolute;
  top: 25%;
  left: 30%;
}
.arrow-right {
  border-top: 5px solid transparent;
  border-left: 6px solid #faf9f9;
  border-bottom: 5px solid transparent;
  position: absolute;
  top: 25%;
  right: 30%;
}
.primary-button {
  width: 164px;
  height: 45px;
  margin-top: 27px;
}
@media screen and (max-width: 1439px) {
  p {
    width: 426px;
  }
}
@media screen and (max-width: 1359px) {
  p {
    width: 312px;
  }
}
@media screen and (max-width: 1023px) {
  p {
    width: 280px;
  }
  .carrousel {
    width: 276px;
  }
  .carrousel-item img {
    width: 100%;
  }
  .handlers {
    width: 316px;
  }
}
</style>
