<template>
  <div
    :class="seeMore ? 'employee-badge see-more' : 'employee-badge'"
    @click="toggleSeeMore"
  >
    <button>
      <img src="@/assets/icons/edit-shop.svg" alt="" />
    </button>
    <div class="info">
      <div class="img-container">
        <img src="@/assets/icons/badge-user-email.svg" alt="" />
      </div>
      <p v-text="user.email"></p>
    </div>
    <div :class="seeMore ? 'info align-items-start auto-h' : 'info'">
      <div class="img-container">
        <img src="@/assets/icons/badge-user-location.svg" alt="" />
      </div>
      <div :class="seeMore ? 'branches auto-h' : 'branches'">
        <div v-for="branch in user.branches" :key="branch.id">
          <p>{{ branch.name }}</p>
        </div>
        <figure
          v-if="user.branches.length > 1"
          :class="seeMore ? 'arrow-button arrow-button-down' : 'arrow-button'"
        >
          <img src="@/assets/icons/arrow-button.svg" alt="" />
        </figure>
      </div>
    </div>
    <div class="info">
      <div class="img-container">
        <img src="@/assets/icons/badge-user-job.svg" alt="" />
      </div>
      <p>{{ groupName }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "EmployeeBadge",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      seeMore: false,
    };
  },
  computed: {
    groupName() {
      let groupName = "";
      if (this.user.is_superuser) {
        groupName = "Super Admin";
      } else {
        const groupId = this.user.groups[0];
        const groups = this.$store.getters.getGroups;
        const finded = groups.find((element) => element.id == groupId);

        groupName = finded.name;
      }
      return groupName;
    },
  },
  methods: {
    toggleSeeMore() {
      if (this.user.branches.length > 1) {
        this.seeMore = !this.seeMore;
      }
    },
  },
};
</script>
<style scoped>
.employee-badge {
  width: 370px;
  background-color: #fff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 10px;
  margin: 0 20px 20px 0;
  padding: 18px 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  height: 24px;
  margin-bottom: 10px;
  width: 100%;
}
.employee-badge .info:last-child {
  margin-bottom: 0;
}
.branches {
  height: 21px;
  position: relative;
  width: 100%;
}
.arrow-button {
  position: absolute;
  right: 16px;
  top: 0;
}
.arrow-button-down {
  transform: rotate(180deg);
}
.auto-h {
  height: auto;
  min-height: 24px;
}
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  margin-right: 14px;
}
button {
  position: absolute;
  bottom: 17px;
  right: 23px;
  border: none;
  background-color: transparent;
}
@media screen and (max-width: 1439px) {
  .employee-badge {
    width: 349px;
  }
}
@media screen and (max-width: 1359px) {
  .employee-badge {
    width: 393px;
  }
  button {
    bottom: 18px;
    right: 18px;
  }
}
@media screen and (max-width: 1023px) {
  .employee-badge {
    width: 280px;
  }
}
</style>
