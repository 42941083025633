export const getTable = (state) => {
  return state.table;
};
export const getProducts = (state) => {
  return state.products;
};
export const getEatHereProducts = (state) => {
  const products = state.products.filter((product) => product.order_type === 1);
  return products;
};
export const getTakeAwayProducts = (state) => {
  const products = state.products.filter((product) => product.order_type === 3);
  return products;
};
