<template>
  <div class="dropdown-button">
    <button
      class="dp-btn"
      :class="active ? 'dp-btnt active' : 'dp-btn'"
      @click="setButton"
    >
      <img :src="active ? button.iconActive : button.icon" alt="" />
      {{ button.name }}
      <img
        :class="open ? 'chevron turned' : 'chevron'"
        src="@/assets/icons/chevron-down.svg"
        alt=""
      />
    </button>
    <div v-if="open" class="dropdown-list">
      <div v-for="subButton in button.subButtons" :key="subButton.id">
        <sidebar-sub-button :subButton="subButton" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SidebarSubButton from "./SidebarSubButton.vue";
export default {
  name: "SidebarButton",
  components: {
    SidebarSubButton,
  },
  props: {
    button: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentButton: "getCurrentSidebarBranchButton",
      currentDropdownButton: "getCurrentSidebarBranchDropdownButton",
    }),
    active() {
      return this.currentButton.id == this.button.id;
    },
    open() {
      return this.currentDropdownButton.id == this.button.id;
    },
  },
  methods: {
    setButton() {
      this.$store.commit("setCurrentSidebarBranchButton", this.button);
      this.$store.commit("setCurrentSidebarDropdownBranchButton", this.button);
    },
  },
};
</script>
<style scoped>
.dp-btn {
  height: 56px;
  border: none;
  background-color: transparent;
  width: 100%;
  color: #828282;
  display: flex;
  align-items: center;
  position: relative;
}
img {
  margin-left: 34px;
  margin-right: 16px;
}
.dp-element {
  height: 56px;
  width: 100%;
  color: #828282;
  display: flex;
  align-items: center;
  padding-left: 34px;
  border: none;
  background-color: transparent;
}
.open {
  background-color: rgba(2, 200, 116, 0.08);
  color: #02c874;
  font-weight: 700;
  padding-left: 66px;
}
.active {
  background-color: rgba(2, 200, 116, 0.08);
  color: #02c874;
}
.chevron {
  margin: 0 30px 0 auto;
}
.turned {
  transform: rotate(180deg);
}
@media screen and (max-width: 1359px) {
  img {
    margin-left: 16px;
  }
}
@media screen and (max-width: 767px) {
  img {
    margin-left: 34px;
  }
}
</style>
