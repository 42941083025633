<template>
  <button class="add-product">
    <img src="@/assets/icons/add-product-icon.svg" alt="icon" />
    <h5>Agregar producto</h5>
  </button>
</template>
<script>
export default {
  name: "AddProductButton",
};
</script>
<style scoped>
.add-product {
  width: 370px;
  height: 58px;
  background: #fdfdfd;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  border-top: none;
  color: #02c874;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}
h3 {
  color: var(--primary);
}
.add-product img {
  margin-right: 12px;
}
@media screen and (max-width: 1439px) {
  .add-product {
    width: 352px;
  }
}
@media screen and (max-width: 1359px) {
  .add-product {
    width: 325px;
  }
}
@media screen and (max-width: 1023px) {
  .add-product {
    width: 280px;
    margin-right: 0;
  }
}
</style>
