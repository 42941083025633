<template>
  <div class="bill">
    <button class="bill-btn">
      <p>Factura {{ bill.id }}</p>
      <img src="@/assets/icons/chevron-down.svg" alt="" />
    </button>
    <div
      :class="current == bill.id ? 'bill-content bill-open' : 'bill-content'"
    >
      <div class="date">
        <img src="@/assets/icons/calendar.svg" alt="calendar" />
        {{ month }} {{ date.day }}, {{ date.year }}
      </div>
      <div v-for="product in bill.order_products" :key="product.id">
        <div class="bill-product">
          <p>
            {{ product.product_name }}
          </p>
          <p>
            ${{
              new Intl.NumberFormat("es-CO").format(product.product_price.price)
            }}
          </p>
        </div>
        <hr />
      </div>
      <div class="taxes">
        <p><span>Subtotal</span></p>
        <span>${{ new Intl.NumberFormat("es-CO").format(subTotal) }}</span>
      </div>
      <div class="taxes">
        <p>IPO Consumo 8%</p>
        ${{ new Intl.NumberFormat("es-CO").format(ipo) }}
      </div>
      <div class="taxes">
        <p>Propina</p>
        ${{ new Intl.NumberFormat("es-CO").format(0) }}
      </div>
      <hr />
      <div class="total">
        <p><span>TOTAL</span></p>
        ${{ new Intl.NumberFormat("es-CO").format(subTotal) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "history-bill",
  props: {
    bill: {
      type: Object,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      date: {
        day: "",
        month: "",
        year: "",
      },
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      month: "",
    };
  },
  computed: {
    subTotal() {
      let subtotal = 0;
      for (let index = 0; index < this.bill.order_products.length; index++) {
        const element = this.bill.order_products[index];
        subtotal = subtotal + element.product_price.price;
      }
      return subtotal;
    },
    ipo() {
      return this.subTotal * 0.08;
    },
  },
  methods: {
    getDate() {
      this.date.day = this.bill.created_at.slice(8, 10);
      this.date.month = this.bill.created_at.slice(5, 7);
      this.date.year = this.bill.created_at.slice(0, 4);
      this.month = this.months[this.date.month - 1];
    },
  },
  mounted() {
    this.getDate();
  },
};
</script>
<style scoped>
.bill-btn {
  height: 48px;
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  margin-bottom: 8px;
}

.bill-content {
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  overflow-y: auto;
}
.bill-open {
  height: auto;
}

.bill-product {
  display: flex;
  justify-content: space-between;
  padding: 18px 0 18px 10px;
}

hr {
  padding-left: 10px;
  margin: 0;
  opacity: 0.1;
}

.taxes,
.total {
  display: flex;
  justify-content: space-between;
  padding: 18px 0 18px 10px;
}
</style>
