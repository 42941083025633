<template>
  <div class="nelkir-select">
    <select v-if="initialState" id="selecteId" @change="change">
      <option id="avaliable">Disponible</option>
      <option id="unavaliable">Agotado</option>
    </select>
    <select v-else id="selecteId" @change="change">
      <option id="unavaliable">Agotado</option>
      <option id="avaliable">Disponible</option>
    </select>
    <figure>
      <img src="@/assets/icons/chevron-down.svg" alt="" />
    </figure>
  </div>
</template>
<script>
export default {
  name: "NelkirSelect",
  props: {
    initialState: {
      type: Boolean,
      required: true,
    },
    toggle: {
      type: Function,
      required: true,
    },
    a: {
      type: String,
      required: true,
    },
  },
  methods: {
    change() {
      const select = document.querySelector("#selecteId");
      if (select.value === "Disponible") {
        this.toggle(true);
      } else {
        this.toggle(false);
      }
    },
  },
};
</script>
<style scoped>
.nelkir-select {
  position: relative;
  width: 100%;
}
select:focus {
  outline: none;
}
select {
  font-family: "DM Sans", sans-serif !important;
  padding-left: 12px;
  padding-right: 34px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  height: 45px;
  appearance: none;
  width: 100%;
}
figure {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 10px;
  pointer-events: none;
}
</style>
