<template>
  <div
    v-if="loading"
    class="all-height d-flex justify-content-center align-items-center"
  >
    <pulse-loader />
  </div>
  <div v-else class="users">
    <transition name="fade">
      <div v-if="userState == 0">
        <h1>Usuarios</h1>
        <div class="d-flex flex-wrap">
          <div v-for="user in users" :key="user.id">
            <employee-badge :user="user" />
          </div>
          <new-employee @click="toCreate" />
        </div>
      </div>

      <div v-else>
        <create-user :toUsers="toUsers" :fetchGroups="fetchGroups" />
      </div>
    </transition>
  </div>
</template>
<script>
import EmployeeBadge from "@/components/users/EmployeeBadge.vue";
import NewEmployee from "@/components/users/NewEmployee.vue";
import CreateUser from "@/components/users/CreateUser.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  name: "users-view",
  components: {
    EmployeeBadge,
    NewEmployee,
    CreateUser,
    PulseLoader,
  },
  data() {
    return {
      userState: 0,
    };
  },
  computed: {
    users() {
      return this.$store.getters.getUsers;
    },
  },
  methods: {
    toCreate() {
      this.userState = 1;
    },
    toUsers() {
      this.userState = 0;
    },
    fetchGroups() {
      this.$store.dispatch("businessRequest", {
        action: "getGroups",
      });
    },
  },
  mounted() {
    this.fetchGroups();
  },
};
</script>
<style scoped>
.users {
  text-align: start;
}
.fade-enter-active {
  transition: opacity 0.5s ease;
  transition-delay: 0.5s;
}
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
