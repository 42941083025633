<template>
  <div class="option-item">
    <p>{{ option.name }}</p>
    <div class="buttons">
      <button
        @click="editOption(option)"
        data-bs-toggle="modal"
        data-bs-target="#editOptionModal"
      >
        <img src="@/assets/icons/edit-shop.svg" alt="edit-icon" />
      </button>
      <button
        @click="deleteModal"
        data-bs-toggle="modal"
        data-bs-target="#deleteModal"
      >
        <img src="@/assets/icons/trash-red.svg" alt="delete-icon" />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    editOption: {
      type: Function,
      required: true,
    },
    reloadListOptions: {
      type: Function,
      required: true,
    },
  },
  methods: {
    deleteModal() {
      this.$store.commit("setDeleteData", {
        deleteAction: "deleteOption",
        deleteData: this.option.id,
        deleteInfo: "Opción",
      });
    },
  },
};
</script>
<style scoped>
.option-item {
  width: 100%;
  height: 45px;
  margin-top: 16px;
  background: #f9f9f9;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 13px 0 20px;
}
.buttons {
  display: flex;
  align-items: center;
}
button {
  background-color: transparent;
  border: none;
  margin-left: 10px;
}
img {
  height: 20px;
  width: 20px;
}
</style>
