<template>
  <div class="branch-view">
    <div v-if="loading" class="loading-container">
      <fade-loader :height="height" :width="width" />
    </div>
    <nav v-if="!loading">
      <div v-for="tab in tabs" :key="tab.id">
        <button :class="tab.active ? 'active' : ''" @click="toggleTabs(tab.id)">
          {{ tab.name }}
          <div v-if="tab.active" class="bottom-line"></div>
        </button>
      </div>
    </nav>
    <section v-if="!loading">
      <information-tab v-if="currentTab == 0" />
      <schedules-tab v-if="currentTab == 1" />
      <contact-tab v-if="currentTab == 2" />
    </section>
  </div>
</template>
<script>
import InformationTab from "@/components/branchView/branch/branchInformation/InformationTab.vue";
import SchedulesTab from "@/components/branchView/branch/branchSchedules/SchedulesTab.vue";
import ContactTab from "@/components/branchView/branch/branchContact/ContactTab.vue";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "BranchView",
  components: {
    InformationTab,
    SchedulesTab,
    ContactTab,
    FadeLoader,
  },
  data() {
    return {
      tabs: [
        { name: "Información", active: true, id: 0 },
        { name: "Horarios", active: false, id: 1 },
        { name: "Contacto", active: false, id: 2 },
      ],
      currentTab: 0,
      loading: false,
      height: "15px",
      width: "5px",
    };
  },
  computed: {
    ...mapGetters({
      isLogued: "getLogued",
      buttons: "getSidebaBranchButtons",
    }),
  },
  methods: {
    ...mapMutations(["setCurrentSidebarBranchButton"]),
    toggleTabs(id) {
      for (let index = 0; index < this.tabs.length; index++) {
        const element = this.tabs[index];
        if (element.id == id) {
          this.tabs[index].active = true;
          this.currentTab = element.id;
        } else {
          this.tabs[index].active = false;
        }
      }
    },
    // fetchBranchInfo() {
    //   this.loading = true;
    //   this.$store
    //     .dispatch("businessRequest", {
    //       action: "fetchBranchInfo",
    //     })
    //     .then((response) => {
    //       if (response.status == 200) {
    //         this.loading = false;
    //       }
    //     });
    // },
  },
  created() {
    this.setCurrentSidebarBranchButton(this.buttons[0]);
    if (!this.isLogued) {
      this.$router.push("/");
    }
    // this.fetchBranchInfo();
  },
};
</script>
<style scoped>
.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.branch-view {
  width: 100%;
  height: 100%;
}
nav {
  display: flex;
}
nav button {
  position: relative;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #828282;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 42px;
}
.active {
  color: #02c874;
}
.bottom-line {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #02c874;
}
section {
  margin-top: 24px;
}
@media screen and (max-width: 1439px) {
  nav button {
    margin-right: 20px;
  }
}
</style>
