<template>
  <button class="business">
    <img src="@/assets/icons/add-shop.svg" alt="" />
    <div class="text-container">
      <p>{{ text }}</p>
    </div>
  </button>
</template>
<script>
export default {
  name: "NewShop",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.business {
  width: 170px;
  height: 170px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
}
img {
  height: 82px;
  width: 82px;
  margin-top: 40px;
}
.text-container {
  color: #333;
  height: 53px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1439px) {
  .business {
    width: 164px;
    height: 164px;
  }
  img {
    height: 76px;
    width: 76px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 1359px) {
  .business {
    width: 183px;
    height: 183px;
  }
  img {
    margin-top: 42px;
  }
}
@media screen and (max-width: 1023px) {
  .business {
    width: 134px;
    height: 134px;
  }
  img {
    height: 55px;
    width: 55px;
    margin-top: 30px;
  }
}
</style>
