<template>
  <div class="products-prescription">
    <div class="title">
      <img src="@/assets/icons/lock-icon.svg" alt="icon" />
      <h3>Información confidencial</h3>
    </div>
    <p>
      La receta son los ingredientes básicos que son necesarios para fabricar tu
      producto. Esta información es completamente confidencial y ayudará a
      mantener al día la disponibilidad de tu inventario.
    </p>
    <div class="prescription">
      <div class="item">
        <div class="ingredients">
          <div class="ingredient">
            <small>INGREDIENTE</small>
            <p>Nombre ingrediente</p>
          </div>
          <div class="weight">
            <small>PESO</small>
            <p>15gr</p>
          </div>
        </div>
        <div class="buttons">
          <button><img src="@/assets/icons/edit-img.svg" alt="icon" /></button>
          <button><img src="@/assets/icons/delete.svg" alt="icon" /></button>
        </div>
      </div>
      <div class="add-item">
        <img src="@/assets/icons/add-product-icon.svg" alt="icon" />
        <p>Agregar ingrediente</p>
      </div>
    </div>
  </div>
  <teleport to="#modalAddIngredient">
    <modal-add-ingredient />
  </teleport>
</template>
<script>
import ModalAddIngredient from "@/modals/ModalAddIngredient.vue";
export default {
  name: "ProductPrescription",
  components: {
    ModalAddIngredient,
  },
};
</script>
<style scoped>
.title {
  display: flex;
  margin-bottom: 12px;
}
.title h3 {
  margin-left: 18px;
}
.prescription {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 5px;
  margin-top: 24px;
  padding: 12px 24px;
}
.item {
  height: 76px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
}
.ingredients {
  display: flex;
}
.ingredient {
  width: 200px;
}
small {
  color: #4f4f4f;
  font-weight: 700;
}
.ingredient p,
.weight p {
  margin-top: 17px;
}
.add-item {
  height: 50px;
  display: flex;
  align-items: center;
}
.add-item p {
  color: #02c874;
  font-weight: 700;
  margin-left: 10px;
}
.buttons {
  margin-top: 38px;
}
.buttons button {
  background-color: transparent;
  border: none;
  margin-left: 14px;
}
@media screen and (max-width: 1023px) {
  .prescription {
    padding: 16px 12px;
  }
  .item {
    height: 66px;
  }
  .ingredients {
    flex-direction: column;
  }
  .ingredient {
    width: 180px;
  }
  .ingredient small,
  .weight small {
    display: none;
  }
  .ingredient p {
    margin: 0;
  }
  .weight p {
    margin-top: 6px;
  }
  .buttons {
    margin-top: 0;
    display: flex;
  }
  .add-item {
    height: 40px;
    margin-top: 10px;
  }
}
</style>
