<template>
  <div class="product-allergen">
    <h3>Elige los algérgenos que contiene tu producto</h3>
    <div v-for="allergen in allergens" :key="allergen.id">
      <div class="check-box">
        <button @click="toggle(allergen.id - 1)">
          <img :src="allergen.active ? check : nocheck" alt="icon" />
        </button>
        <p v-text="allergen.name"></p>
      </div>
    </div>
  </div>
</template>

<script>
import iconNoCheck from "@/assets/icons/nocheck.svg";
import iconCheck from "@/assets/icons/check.svg";
export default {
  name: "ProductAllergen",
  data() {
    return {
      nocheck: iconNoCheck,
      check: iconCheck,
    };
  },
  computed: {
    allergens() {
      return this.$store.getters.getAllergens;
    },
  },
  methods: {
    toggle(payload) {
      for (let index = 0; index < this.allergens.length; index++) {
        if (index == payload) {
          this.allergens[index].active = !this.allergens[index].active;
          if (this.allergens[index].active) {
            this.$store
              .dispatch("businessRequest", {
                action: "putAllergen",
                data: {
                  allergenId: payload + 1,
                  productId: this.$store.getters.getCurrentProduct.id,
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$store.dispatch("businessRequest", {
                    action: "fetchProduct",
                    data: this.$store.getters.getCurrentProduct.id,
                  });
                  this.$store.dispatch("businessRequest", {
                    action: "fetchProducts",
                  });
                }
              });
          } else {
            this.$store
              .dispatch("businessRequest", {
                action: "deleteAllergen",
                data: {
                  allergenId: payload + 1,
                  productId: this.$store.getters.getCurrentProduct.id,
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$store.dispatch("businessRequest", {
                    action: "fetchProduct",
                    data: this.$store.getters.getCurrentProduct.id,
                  });
                  this.$store.dispatch("businessRequest", {
                    action: "fetchProducts",
                  });
                }
              });
          }
        }
      }
    },
  },
  mounted() {
    this.$store
      .dispatch("businessRequest", {
        action: "fetchAllergen",
      })
      .then((response) => {
        if (response.status == 200) {
          const product = this.$store.getters.getCurrentProduct;
          for (let i = 0; i < product.allergens.length; i++) {
            const currentAllergen = product.allergens[i];
            for (let j = 0; j < this.allergens.length; j++) {
              const allergen = this.allergens[j];
              if (currentAllergen.id == allergen.id) {
                allergen.active = true;
              }
            }
          }
        } else {
          this.$store.commit("setPopUpMessage", "Algo salió mal :(");
        }
      });
  },
};
</script>

<style scoped>
.product-allergen {
  width: 100%;
  height: 532px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 5px;
  padding: 26px 36px;
}
h3 {
  margin-bottom: 12px;
}
.check-box {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.check-box p {
  margin-left: 14px;
}
button {
  border: none;
  background-color: transparent;
  height: 18px;
  display: flex;
  align-items: center;
}
img {
  height: 18px;
  width: 18px;
}
@media screen and (max-width: 1023px) {
  .product-allergen {
    padding: 16px;
    height: 532px;
  }
}
</style>
