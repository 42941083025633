import axios from "axios";
import globalStore from "@/store";
import api from "@/api";

const users = {
  state: {
    currentOffice: "",
    groups: "",
    visibleGroups: "",
    users: "",
    mainUser: null,
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload.data;
    },
    setGroups(state, payload) {
      state.groups = payload;
      state.visibleGroups = JSON.parse(JSON.stringify(state.groups)).splice(
        2,
        3
      );
    },
    setMainUser(state, payload) {
      state.mainUser = payload;
    },
  },
  actions: {
    fetchUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${globalStore.state.domainName}/v1/users`,
            globalStore.state.businessAuth
          )
          .then(
            (response) => {
              commit("setUsers", response);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    createUser(context, payload) {
      return new Promise((resolve, reject) => {
        // usaria esta ruta para ambos save, el de colomboia y de col en los settings que se despliegan en la navbar
        axios
          .post(
            `${api}/r/${globalStore.state.domainName}/v1/users`,
            payload,
            globalStore.state.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    getGroups({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${globalStore.state.domainName}/v1/groups`,
            globalStore.state.businessAuth
          )
          .then(
            (response) => {
              commit("setGroups", response.data);
              dispatch("fetchUsers");
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    getPermissions() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${globalStore.state.domainName}/v1/permissions`,
            globalStore.state.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    fetchMainUser({ rootState, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.domainName}/v1/users/1`,
            globalStore.state.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
              commit("setMainUser", response);
            },
            (error) => {
              resolve(error);
              reject(error);
            }
          );
      });
    },
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
    getCurrentOffice(state) {
      return state.currentOffice;
    },
    getGroups(state) {
      return state.groups;
    },
    getVisibleGroups(state) {
      return state.visibleGroups;
    },
    getLoadingUsers(state) {
      return state.loadingUsers;
    },
    getMainUser(state) {
      return state.mainUser;
    },
  },
};

export default users;
