<template>
  <section class="contact-tab">
    <div class="inputs-list">
      <h6>CONTACTO</h6>
      <div v-for="input in inputs" :key="input.id">
        <div class="input">
          <div class="label">
            <img :src="input.icon" alt="icon" />
            <label for=""
              >{{ input.name }}
              <div
                :class="input.active ? 'handler active' : 'handler'"
                @click="toggleContact(input.id)"
              >
                <div class="dot"></div></div
            ></label>
          </div>
          <input
            type="text"
            :placeholder="input.placeholder"
            v-if="input.id < 3"
          />
          <div v-else class="fw position-relative">
            <input class="input-num1" type="text" disabled placeholder="+57" />
            <img class="flag" src="@/assets/icons/colombia.svg" alt="" />
            <input
              class="input-num2"
              type="tel"
              :placeholder="input.placeholder"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import webIcon from "@/assets/icons/web-icon.svg";
import fbIcon from "@/assets/icons/fb-icon.svg";
import instagramIcon from "@/assets/icons/instagram-icon.svg";
import whatsappIcon from "@/assets/icons/whatsapp-icon.svg";
export default {
  name: "ContactTab",
  data() {
    return {
      inputs: [
        {
          name: "Tu página web",
          link: "",
          placeholder: "www.mipaginaweb.com",
          icon: webIcon,
          active: false,
          id: 0,
        },
        {
          name: "Facebook",
          link: "",
          placeholder: "www.mipaginaweb.com",
          icon: fbIcon,
          active: false,
          id: 1,
        },
        {
          name: "Instagram",
          link: "",
          placeholder: "www.instagram.com/restaurante",
          icon: instagramIcon,
          active: false,
          id: 2,
        },
        {
          name: "Whatsapp",
          link: "",
          placeholder: "3001520000",
          icon: whatsappIcon,
          active: false,
          id: 3,
        },
      ],
    };
  },
  methods: {
    toggleContact(id) {
      for (let index = 0; index < this.inputs.length; index++) {
        const element = this.inputs[index];
        if (element.id == id) {
          this.inputs[index].active = !this.inputs[index].active;
        }
      }
    },
  },
};
</script>
<style scoped>
.inputs-list {
  width: 470px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 16px 26px 30px 18px;
  text-align: start;
}
h6 {
  margin-bottom: 16px;
  color: #828282;
}
.label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.label img {
  margin-right: 10px;
}
label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 700;
}
.inputs {
  display: flex;
  margin-bottom: 18px;
}
.input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  position: relative;
}
input {
  width: 100%;
  margin: 8px 0 18px;
}
.handler {
  width: 34px;
  height: 20px;
  background-color: gray;
  border-radius: 80px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 3px;
  cursor: pointer;
}
.active {
  background-color: #02c874;
  justify-content: flex-end;
  border: none;
}
.dot {
  height: 14px;
  width: 14px;
  background: #fff;
  border-radius: 100%;
}
.input {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.fw .input {
  width: 45%;
}
.input-num1 {
  border-radius: 5px 0 0 5px;
  width: 20%;
  padding-left: 40px;
}
.input-num1:disabled {
  background-color: #fff;
}
.flag {
  position: absolute;
  height: 16px;
  left: 16px;
  top: 22px;
}
.input-num2 {
  border-radius: 0 5px 5px 0;
  width: 80%;
}
.input-num1,
.input-num2 {
  margin-bottom: 0;
}
::placeholder {
  opacity: 0.5;
}
.input-num1::placeholder {
  opacity: 1;
  color: #2f2f2f;
}
@media screen and (max-width: 1439px) {
  .inputs-list {
    width: 446px;
  }
}
@media screen and (max-width: 1023px) {
  .inputs-list {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .inputs-list {
    padding: 16px 10px;
  }
  .input-num1 {
    width: 35%;
  }
  .input-num2 {
    width: 65%;
  }
}
</style>
