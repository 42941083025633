export default () => ({
  orders: [],
  currentOrder: -1,
  currentNewOrder: { id: -1 },
  totalPriceOfCurrentOrder: 0,
  productToOrder: null,
  shopCart: [],
  shopCartProduct: null,
  currentTable: null,
  requestedOrders: [],
  updateComandas: true,
});
