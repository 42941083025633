<template>
  <div class="sidebar">
    <figure class="logo-container">
      <img src="@/assets/icons/logo-nelkir.svg" alt="nelkir-logo" />
    </figure>
    <section class="branch-info">
      <figure>
        <img :src="currentBranch.image" alt="branch-logo" />
      </figure>
      <h2>{{ currentBranch.name }}</h2>
      <div class="pill-container">
        <p>Premium</p>
      </div>
    </section>
    <section class="sidebar-buttons">
      <div v-for="(button, index) in buttons" :key="index">
        <sidebar-button v-if="button.type == 1" :button="button" />

        <dropdown-button v-if="button.type == 2" :button="button" />
      </div>
      <button class="logout" @click="logout">Cerrar sesión</button>
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SidebarButton from "@/components/SidebarBranch/SidebarButton";
import DropdownButton from "@/components/SidebarBranch/DropdownButton";
export default {
  components: {
    DropdownButton,
    SidebarButton,
  },
  props: {
    hideSidebar: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      buttons: "getSidebaBranchButtons",
      currentButton: "getCurrentSidebarBranchButton",
      currentBranch: "getCurrentBranch",
    }),
  },
  methods: {
    toggleButtons(button) {
      this.$store.commit("setCurrentSidebarBranchButton", button);
      if (button.type != 2) {
        this.hideSidebar();
      }
    },
    toggleSubButton() {
      this.hideSidebar();
    },
  },
  mounted() {
    this.$store.commit("setCurrentSidebarBranchButton", this.currentButton);
  },
};
</script>
<style scoped>
.sidebar {
  position: relative;
  width: 227px;
  background: #ffffff;
  box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.05);
  min-height: 100vh;
  overflow: initial;
  padding-bottom: 12px;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  margin: 0;
}
.logo-container img {
  width: 129px;
}
.pill-container {
  width: 80px;
  height: 25px;
  background-color: #02c874;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 11px;
}
.branch-info {
  width: 100%;
  padding-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
figure {
  margin: 0;
}
.branch-info figure img {
  height: 90px;
  width: 90px;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
}
.branch-info h2 {
  margin-top: 18px;
}
.pill-container p {
  color: #fff;
}
.sidebar-buttons {
  margin-top: 40px;
}
.logout {
  display: none;
  width: 100%;
  border: none;
  height: 48px;
  background-color: transparent;
  position: relative;
  color: #828282;
}
@media screen and (max-width: 1439px) {
  .sidebar {
    width: 220px;
  }
}
@media screen and (max-width: 1359px) {
  .sidebar {
    width: 170px;
  }
}
@media screen and (max-width: 960px) {
  .sidebar-buttons {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .sidebar {
    width: 210px;
  }
  .logout {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
