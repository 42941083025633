import axios from "@/businessApi";

export const fetchCountries = async ({ commit, rootState }) => {
  try {
    const response = await axios.get(`/r/${rootState.domainName}/v1/countries`);
    commit("setCountries", response.data);
  } catch (error) {
    console.log(error);
  }
};

export const fetchStates = async ({ commit, rootState }) => {
  try {
    const response = await axios.get(`/r/${rootState.domainName}/v1/states`);
    commit("setStates", response.data);
  } catch (error) {
    console.log(error);
  }
};

export const fetchCities = async ({ commit, rootState }, payload) => {
  try {
    const response = await axios.get(
      `/r/${rootState.domainName}/v1/city-by-state?state=${payload}`
    );
    commit("setCities", response.data);
  } catch (error) {
    console.log(error);
  }
};
