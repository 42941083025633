<template>
  <div
    class="modal fade"
    id="modalDeleteProduct"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Staph Right there!</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>¿Seguro que desea eliminar?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="deleteAction"
          >
            Si, eliminar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalDeleteProduct",
  props: {
    id: {
      type: String,
      required: true,
    },
    update: {
      type: Function,
      required: true,
    },
  },
  methods: {
    deleteAction() {
      this.$store
        .dispatch("businessRequest", {
          action: "deleteProduct",
          data: this.id,
        })
        .then((response) => {
          if (response.status === 204) {
            this.$store.commit("setToastMessage", "Producto eliminado");
            window.$("#toastNotification").toast("show");
            this.update();
          } else {
            this.$store.commit("setToastMessage", "Algo salio mal");
            window.$("#toastNotification").toast("show");
          }
        });
    },
  },
};
</script>
<style scoped></style>
