<template>
  <div class="lot">
    <div class="lot-header">
      <div class="head-info item-a">
        <label for="">Admin</label>
        <p>Paola</p>
      </div>
      <div class="head-info item-b">
        <label for="">Inicio</label>
        <div class="d-flex justify-content-between">
          <p>Feb 23, 2020</p>
          <p>05:12PM</p>
          <p class="h-responsive">$420.000</p>
        </div>
      </div>
      <div class="head-info item-c">
        <label for="">Cierre</label>
        <div class="d-flex justify-content-between">
          <p>Feb 23, 2020</p>
          <p>05:12PM</p>
          <p class="h-responsive">$820.000</p>
        </div>
      </div>
      <p class="item-d">Propinas: $420.000</p>
    </div>
    <h3 class="mb-3">Facturas</h3>
    <div v-for="order in bills" :key="order.id">
      <bill
        :bill="order"
        :current="currentBill"
        @click="setCurrentBill(order.id)"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Bill from "./Bill.vue";
export default {
  name: "history-lot",
  props: {
    bills: {
      type: Array,
      required: true,
    },
  },
  components: {
    Bill,
  },
  data() {
    return {
      currentBill: -1,
    };
  },
  computed: {
    ...mapGetters({
      orders: "getOrders",
    }),
  },
  methods: {
    setCurrentBill(payload) {
      if (this.currentBill == payload) {
        this.currentBill = -1;
      } else {
        this.currentBill = payload;
      }
    },
  },
};
</script>
<style scoped>
.lot {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 100%;
  padding: 24px 34px;
  text-align: start;
  margin-bottom: 20px;
}
.lot-header {
  display: grid;
  grid-template-columns: 150px 335px 335px;
  grid-template-areas:
    "admin start end"
    "perquisite perquisite perquisite";
  column-gap: 30px;
  row-gap: 16px;
  margin-bottom: 26px;
}
.item-a {
  grid-area: admin;
}
.item-b {
  grid-area: start;
}
.item-c {
  grid-area: end;
}
.item-d {
  grid-area: perquisite;
}
label {
  font-weight: 700;
}
@media screen and (max-width: 1359px) {
  .lot-header {
    grid-template-columns: 80px 285px 285px;
  }
}
@media screen and (max-width: 1023px) {
  .lot-header {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "admin perquisite"
      "start end";
  }
}
@media screen and (max-width: 767px) {
  .lot-header {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "admin perquisite"
      "start start"
      "end end";
  }
  .h-responsive {
    display: none;
  }
}
</style>
