export const updateProduct = ({ state }, payload) => {
  state.socket.send(JSON.stringify(payload));
};
export const updateOrders = ({ state }, payload) => {
  state.socket.send(JSON.stringify(payload));
};
export const logoutComensal = ({ state }, payload) => {
  state.socket.send(JSON.stringify(payload));
};
export const deleteOrder = ({ state }, payload) => {
  state.socket.send(JSON.stringify(payload));
};
