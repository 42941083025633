<template>
  <div class="category-button">
    <button
      :class="
        currentCategory.id == category.id
          ? 'category-button__body active'
          : 'category-button__body'
      "
      @click="selectCategory"
    >
      <div class="button-info">
        <img src="@/assets/icons/category-menu-icon-dark.svg" alt="icon" />
        <h5 v-text="category.name"></h5>
      </div>
      <img class="arrow" src="@/assets/icons/chevron-down.svg" alt="icon" />
    </button>
    <transition>
      <div v-if="currentCategory.id == category.id">
        <div v-for="product in products" :key="product.name">
          <product-item @click="selectProduct(product)" :product="product" />
        </div>
        <add-product-button @click="select(4)" />
      </div>
    </transition>
  </div>
</template>
<script>
import AddProductButton from "@/components/menu/categories/AddProductButton.vue";
import ProductItem from "@/components/menu/products/ProductItem.vue";
export default {
  name: "CategoryButton",
  components: {
    AddProductButton,
    ProductItem,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
    setCurrentCategory: {
      type: Function,
      required: true,
    },
    currentCategory: {
      type: Object,
      required: true,
    },
  },
  computed: {
    products() {
      const products = this.$store.getters.getProducts.filter(
        (product) => product.category == this.category.id
      );
      return products;
    },
  },
  methods: {
    selectCategory() {
      this.select(2);
      this.setCurrentCategory(this.category);
    },
    selectProduct(payload) {
      this.$store.commit("setCurrentProduct", payload);
      this.select(3);
    },
  },
};
</script>
<style scoped>
.category-button {
  margin-bottom: 16px;
}
.category-button__body {
  width: 370px;
  height: 58px;
  background: #fdfdfd;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px 5px 0px 0px;
  color: #02c874;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
}
.active {
  background-color: #f7f7f7;
  border-bottom: 2px solid #e2e2e2;
}
.button-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
h2 {
  margin: 0;
}
.category-button__body img {
  margin-right: 18px;
}
.arrow {
  justify-self: flex-end;
}
.fade-enter-active,
.fade-leave-active {
  transition: height 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  height: 0;
}
@media screen and (max-width: 1439px) {
  .category-button__body {
    width: 352px;
  }
}
@media screen and (max-width: 1359px) {
  .category-button__body {
    width: 325px;
  }
}
@media screen and (max-width: 1023px) {
  .category-button__body {
    width: 280px;
    margin-right: 0;
  }
}
</style>
