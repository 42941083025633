export const getTotalOrders = (state) => {
  return state.total;
};

export const getTotalCard = (state) => {
  return state.totalCard;
};

export const getTotalTransfer = (state) => {
  return state.totalTransfer;
};
export const getTotalCash = (state) => {
  return state.totalCash;
};
export const getTodayOrders = (state) => {
  return state.todayOrders;
};
export const getTodayTip = (state) => {
  return state.todayTip;
};
export const getTotalDebit = (state) => {
  return state.totalDebit;
};
export const getTotalCredit = (state) => {
  return state.totalCredit;
};
export const getTotalBillCalls = (state) => {
  return state.totalBillCalls;
};
export const getTotalAnalytics = (state) => {
  return state.totalAnalytics;
};
export const getTotalWaiterCalls = (state) => {
  return state.totalWaiterCalls;
};
export const getTotalSessions = (state) => {
  return state.totalSessions;
};
export const getMostClicked = (state) => {
  return state.mostClicked;
};
export const getLeastClicked = (state) => {
  return state.leastClicked;
};
export const getSubscribersData = (state) => {
  return state.subscribers;
};
export const getEventDetail = (state) => {
  return state.eventDetail;
};
export const getComparisonTotalBillCalls = (state) => {
  return state.comparisionTotalBillCalls;
};

export const getComparisonTotalWaiterCalls = (state) => {
  return state.comparisionTotalWaiterCalls;
};
export const getComparisonTotalSessions = (state) => {
  return state.comparisionTotalSessions;
};
export const getGoogleAnalitycs = (state) => {
  return state.googleAnalitycs;
};
