export const getOrders = (state) => {
  return state.orders;
};
// prettier-ignore
export const getOrdersByStatus = (state) => (...payload) => {
  return state.orders.filter((order) => payload.includes(order.order_status));
};

export const getCurrentOrder = (state) => {
  const currentOrder = state.orders.find(
    (order) => order.id === state.currentOrder
  );

  if (currentOrder) {
    return currentOrder;
  }

  return state.currentOrder;
};

export const getRequestedOrders = (state) => {
  return state.requestedOrders;
};

export const getUpdateComandas = (state) => {
  return state.updateComandas;
};
