import store from "@/store";

export default {
  name: "lite-branches",
  component: () =>
    import(
      /* webpackChunkName: "BranchLayout" */ "../../liteDashboard/layouts/NavBarUS.vue"
    ),

  beforeEnter() {
    if (!store.state.login.isLogued) {
      return { name: "Login" };
    }
    return null;
  },
  children: [
    {
      path: "",
      name: "branches-lite",
      component: () =>
        import(/* webpackChunkName: "Branches" */ "../views/Branches.vue"),
    },
    {
      path: "settings",
      name: "settings-lite",
      component: () =>
        import(/* webpackChunkName: "BranchInfo" */ "../views/Settings.vue"),
    },
  ],
};
