<template>
  <div class="order-info">
    <header>
      <h4>INFORMACIÓN DEL PEDIDO</h4>
      <div class="edit">
        <img src="@/assets/icons/icon-edit.svg" alt="icon-edit" />
        <p>Editar</p>
      </div>
    </header>
    <div class="order-status">
      <h4 class="n-order">Orden #{{ order.id }}</h4>
      <h4>
        Método de pago:
        <p class="warn">Pendiente</p>
      </h4>
    </div>
    <div class="top-table">
      <p class="product">PRODUCTO</p>
      <p class="details">OPCIONES Y DETALLES</p>
      <p class="price">PRECIO</p>
      <p class="actions">ACCIONES</p>
    </div>
    <div class="order-table">
      <div class="eat-here">
        <h4>PARA COMER AQUÍ</h4>
        <div v-for="product in order.order_products" :key="product.id">
          <order-food :food="product" />
        </div>
      </div>
    </div>
    <footer>
      <div class="item-a">
        <small>PROPINA</small>
        <p>PENDIENTE</p>
      </div>
      <div class="item-b">
        <img
          class="me-3"
          src="@/assets/icons/print-icon.svg"
          alt="print-icon"
        />
        <p>Imprimir comanda</p>
      </div>
      <div class="item-c">
        <small>TOTAL</small>
        <h2>${{ new Intl.NumberFormat("es-CO").format(totalPrice) }}</h2>
      </div>
      <div v-if="order.order_status == 1" class="buttons">
        <button class="secondary-button" @click="rejectOrder">Rechazar</button>
        <button class="primary-button" @click="toKitchen">Aceptar</button>
      </div>
      <div v-if="order.order_status == 2" class="buttons">
        <button class="secondary-button" @click="delivered">
          Marcar como entregado
        </button>
        <button
          class="primary-button"
          data-bs-toggle="modal"
          data-bs-target="#modalBill"
        >
          Generar Factura
        </button>
      </div>
      <div v-if="order.order_status == 3" class="buttons">
        <button class="secondary-button" @click="delivered">
          Cerrar orden
        </button>
        <button
          class="primary-button"
          data-bs-toggle="modal"
          data-bs-target="#modalBillingOrder"
        >
          Pagar
        </button>
      </div>
    </footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import OrderFood from "./OrderFood.vue";
export default {
  name: "OrdenInfo",
  props: {
    fetchOrders: {
      type: Function,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    OrderFood,
  },
  computed: {
    ...mapGetters({
      //order: "getCurrentNewOrder",
      // totalPrice: "getTotalPriceOfCurrentOrder"
    }),
    totalPrice() {
      let orderPrice = 0;
      for (let index = 0; index < this.order.order_products.length; index++) {
        const product = this.order.order_products[index];
        let totalPrice =
          product.product_price.price * (1 + product.product_price.iva);
        for (
          let index = 0;
          index < product.order_option_election.length;
          index++
        ) {
          const element = product.order_option_election[index].price;
          totalPrice = totalPrice + element;
        }
        for (let index = 0; index < product.order_addition.length; index++) {
          const element = product.order_addition[index].price;
          totalPrice = totalPrice + element;
        }
        orderPrice = orderPrice + totalPrice * product.quantity;
      }
      return orderPrice;
    },
  },
  methods: {
    toKitchen() {
      this.$store
        .dispatch("businessRequest", {
          action: "toKitchen",
          data: {
            id: this.order.id,
            order_status: 2,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            setTimeout(() => {
              this.fetchOrders();
              this.$store.commit("setCurrentNewOrder", { id: -1 });
            }, 1000);
          }
        });
    },
    delivered() {
      this.$store
        .dispatch("businessRequest", {
          action: "toKitchen",
          data: {
            id: this.order.id,
            order_status: 3,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.fetchOrders();
            this.$store.commit("setCurrentNewOrder", { id: -1 });
          }
        });
    },
    rejectOrder() {
      this.$store
        .dispatch("businessRequest", {
          action: "toKitchen",
          data: {
            id: this.order.id,
            order_status: 6,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.fetchOrders();
            this.$store.commit("setCurrentNewOrder", { id: -1 });
          }
        });
    },
  },
};
</script>
<style scoped>
.order-info {
  height: calc(100vh - 216px);
  width: 100%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 16px 25px 14px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h4 {
  color: #828282;
  display: flex;
}
.edit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-status {
  display: flex;
}
.n-order {
  margin-right: 62px;
}
.warn {
  margin-left: 8px;
  color: #cb3734;
}
.order-table {
  height: 425px;
  overflow: auto;
  position: relative;
}
.see-more {
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  background-color: #ffffff91;
}
.top-table {
  display: grid;
  grid-template-columns: auto 200px 100px 120px;
  column-gap: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f2f2f2;
}
.head-table {
  display: flex;
  padding-bottom: 12px;
  border-bottom: 1px solid #f2f2f2;
}
.top-table p {
  font-size: 14px;
  color: #828282;
}
.price {
  text-align: end;
}
.actions {
  text-align: end;
}
footer {
  padding-top: 14px;
  border-top: 1px solid #f2f2f2;
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: auto auto;
  row-gap: 8px;
  grid-template-areas:
    "top-a top-b"
    "bottom-a bottom-b";
}
.item-a {
  grid-area: top-a;
}
.item-b {
  grid-area: top-b;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.item-c {
  grid-area: bottom-a;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.buttons {
  grid-area: bottom-b;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.primary-button {
  padding: 10px 32px;
}
.secondary-button {
  background-color: transparent;
  border: none;
  padding-right: 30px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
@media screen and (max-width: 1359px) {
  .top-table {
    grid-template-columns: auto;
  }
  .details,
  .price,
  .actions {
    display: none;
  }
  h2 {
    font-size: 18px;
  }
  .buttons {
    margin-top: 15px;
  }
}
@media screen and (max-width: 1023px) {
  .order-info {
    width: 100%;
    padding: 10px 10px 14px;
  }
  .edit p {
    display: none;
  }
  .order-status {
    flex-direction: column;
  }
  .n-order {
    margin-bottom: 6px;
  }
  footer {
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "top-a top-b"
      "mid-a mid-a"
      "bottom-a bottom-a";
  }
  .item-b {
    grid-area: mid-a;
    justify-content: center;
    margin: 28px 0;
  }
  .item-c {
    grid-area: top-b;
    align-items: flex-end;
  }
  .buttons {
    justify-content: center;
    grid-area: bottom-a;
  }
}
</style>
