<template>
  <div id="BranchDashboard" class="branch-dashboard">
    <header-client
      class="header"
      :businessInfo="true"
      :shortcutBranches="isAdmin"
      :showSidebar="showSidebar"
    />
    <sidebar-branch
      :class="sidebar ? 'sidebar-layout show-sidebar' : 'sidebar-layout'"
      :hideSidebar="hideSidebar"
    />
    <section v-if="loading == false" class="view">
      <router-view />
    </section>
    <div class="load-panel" v-if="loading">
      <fade-loader />
    </div>
  </div>
</template>
<script>
import HeaderClient from "@/components/HeaderClient.vue";
import SidebarBranch from "@/components/SidebarBranch.vue";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { mapGetters } from "vuex";

export default {
  name: "BranchDashboard",
  components: {
    HeaderClient,
    SidebarBranch,
    FadeLoader,
  },
  data() {
    return {
      sidebar: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: "getAdmin",
    }),
  },
  methods: {
    showSidebar() {
      this.sidebar = true;
    },
    hideSidebar() {
      this.sidebar = false;
    },
    fetchBranchInfo() {
      this.loading = true;
      this.$store
        .dispatch("businessRequest", {
          action: "fetchBranchInfo",
        })
        .then((response) => {
          if (response.status == 200) {
            this.loading = false;
          }
        });
    },
    fetchTables() {
      this.$store.dispatch("businessRequest", {
        action: "fetchTables",
      });
    },
  },
  created() {
    this.fetchBranchInfo();
    this.fetchTables();
  },
};
</script>
<style scoped>
.branch-dashboard {
  padding: 76px 0 0 227px;
  background-color: #fbfbfb;
  overflow: auto;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
}
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  transition: 0.5s;
  background-color: #fff;
}
.show-sidebar {
  left: 0;
}
.view {
  padding: 20px;
  height: 100%;
  width: 100%;
  height: 100%;
}
.load-panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1439px) {
  .branch-dashboard {
    padding-left: 240px;
  }
}
@media screen and (max-width: 1359px) {
  .branch-dashboard {
    padding-left: 190px;
  }
}
@media screen and (max-width: 1023px) {
  .branch-dashboard {
    padding-left: 20px;
  }
  .sidebar {
    left: -210px;
  }
  .show-sidebar {
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .view {
    padding: 20px 0;
  }
}
</style>
