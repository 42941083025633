<template>
  <div class="home-wrapper">
    <div class="home-container">
      <h1 style="font-size: 1.25rem">
        Welcome back, {{ getBusinesses[0].name }}!
      </h1>
      <img src="@/assets/images/homeimg.png" alt="Image" />
      <p style="font-size: 1rem">
        We are building a new home page for you;
        <a @click="gotoAnalitycs()" class="a-home">click here</a>
        to visit the new analytics page
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { format } from "date-fns";

export default {
  name: "home-vue",
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("fetchBranchInfo");
    this.fetchBusinesses();
    // this.totalBillCallsCount();
  },

  computed: {
    ...mapGetters("orders", ["getOrdersByStatus"]),
    ...mapGetters("statics", [
      "getTotalOrders",
      "getTotalCard",
      "getTotalTransfer",
      "getTodayOrders",
      "getTotalCash",
      "getTodayTip",
      "getTotalSessions",
      "getTotalWaiterCalls",
      "getTotalBillCalls",
      "getMostClicked",
      "getLeastClicked",
      "getComparisonTotalBillCalls",
      "getComparisonTotalWaiterCalls",
      "getComparisonTotalSessions",
    ]),
    ...mapGetters(["getCurrentBranchInfo"]),
    ...mapGetters(["getBusinesses"]),

    ordersByStatus() {
      return this.getOrdersByStatus(5);
    },
    mattedStartDate() {
      return format(this.startDate, "MMM d yyyy"); // Formato "Mes día año", por ejemplo, "Mar 16 2024"
    },
    formattedComparisionDate() {
      return format(this.startDateComparision, "MMM d yyyy"); // Formato "Mes día año", por ejemplo, "Mar 16 2024"
    },
  },
  methods: {
    ...mapActions("orders", ["fetchOrders"]),
    ...mapActions("statics", [
      "fetchTotalOrders",
      "fetchTotalCard",
      "fetchTotaltransfer",
      "fetchTodayOrders",
      "fetchTotalCash",
      "fetchTotalTip",
      "fetchTotalBillCalls",
      "fetchTotalWaiterCalls",
      "fetchTotalSessions",
      "fetchMostClicked",
      "fetchLeastClicked",
    ]),
    ...mapActions(["fetchBranches"]),

    gotoAnalitycs() {
      this.$router.push({ name: "branch-graps-lite" });
    },

    async fetchBusinesses() {
      let response = await this.$store.dispatch("appRequest", {
        action: "fetchBusinesses",
      });
      if (response.status == 200) {
        if (response.data[0].location === "col") {
          this.lenguaje = "es-ES";
          this.show = true;
        } else if (response.data[0].location === "us") {
          this.lenguaje = "en-US";
          this.show = false;
        }
        // this.setFormattedDate();
      }
    },
  },

  created() {
    // this is a comment to test if merge in branch works
  },
};
</script>

<style lang="scss">
.home-wrapper {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 80dvh; /* Usa el 100% del alto de la ventana */
}
.home-container {
  // padding-top: 41px;
}
.a-home {
  text-decoration: underline;
  color: $primary;
}
.expansion-event .q-expansion-item__container {
  z-index: 99;
  background-color: #fff;
  border-top: 0px;
}
.q-tab--active .q-tab__indicator .tabs-home {
  right: 10px;
  bottom: 12px;
  left: 11px;
}

.menu-title-container {
  text-align: left;
  margin-top: 4px;
  margin-bottom: 19px;
}
.menu-title {
  margin: 0;
}

@media screen and (max-width: 883px) {
  .home-wrapper {
    height: 90dvh; /* Usa el 100% del alto de la ventana */
  }
}
</style>
