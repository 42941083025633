const loading = {
  state: {
    message: "",
    details: "",
  },
  mutations: {
    setLoading(state, payload) {
      state.message = payload.message;
      state.details = payload.details;
    },
  },
  actions: {},
  getters: {
    getLoadingState(state) {
      return {
        message: state.message,
        details: state.details,
      };
    },
  },
};

export default loading;
