export const setSocket = (state, payload) => {
  state.socket = payload;
};
export const setConnection = (state, payload) => {
  const index = state.connections.indexOf(
    state.connections.find((connection) => connection.order == payload.order)
  );
  if (index != -1) {
    state.connections[index] = payload;
  } else {
    state.connections.push(payload);
  }
};
