<template>
  <div
    class="modal fade"
    id="modalCreateBranch"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button class="button-close" @click="closeModal">
          <img src="@/assets/icons/close-modal.svg" alt="" />
        </button>
        <h1>Crear nueva sede</h1>
        <div v-if="containgImg" class="office-img">
          <img :src="image" alt="office-img" />
        </div>
        <button
          v-else
          id="custom-btn"
          class="upload-img"
          @click="isOpen = true"
        >
          <img src="@/assets/images/upload-file.png" alt="" />
          <p>Escoge una foto de tu sede</p>
        </button>
        <div class="input">
          <label for="">Nombre Sede</label>
          <input type="text" v-model="name" />
        </div>
        <div class="buttons">
          <n-button :style="1" @click="closeModal">Cancelar</n-button>
          <n-button @click="createBranch" :disabled="disableButton"
            >Crear</n-button
          >
        </div>
      </div>
    </div>
    <teleport to="body">
      <div v-if="isOpen">
        <modal-crop-img @close="isOpen = false" :aspectRatio="4 / 1.5" />
      </div>
    </teleport>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalCropImg from "@/modals/ModalCropImg.vue";
import NButton from "@/components/tools/buttons/NelkirButton.vue";
export default {
  name: "ModalCreateBranch",
  components: {
    NButton,
    ModalCropImg,
  },
  props: {
    reloadList: {
      type: Function,
      required: true,
    },
  },
  data() {
    return this.initialState();
  },
  computed: {
    ...mapGetters({
      image: "getCurrentImg",
    }),
    containgImg() {
      return this.image !== null;
    },
    disableButton() {
      return this.name.length < 2 || this.loading;
    },
  },
  methods: {
    initialState() {
      return {
        name: "",
        loading: false,
        isOpen: false,
      };
    },
    reset() {
      Object.assign(this.$data, this.initialState());
      this.$store.commit("setCurrentImg", null);
    },
    getInfo() {
      const branch = {
        name: this.name,
        image: this.image,
      };
      return branch;
    },
    createBranch() {
      this.loading = true;
      this.$store
        .dispatch("businessRequest", {
          action: "createBranch",
          data: this.getInfo(),
        })
        .then((response) => {
          if (response.status == 201) {
            this.$store.commit("setPopUpMessage", "Nueva sucursal agregada");
            this.reloadList();
            window.$("#modalCreateBranch").modal("hide");
            this.reset();
            this.loading = false;
          } else {
            this.$store.commit("setPopUpMessage", "Algo salio mal :(");
          }
        });
    },
    closeModal() {
      window.$("#modalCreateBranch").modal("hide");
      this.reset();
    },
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 370px;
}
.office-img {
  margin-top: 15px;
  height: 150px;
  width: 315px;
  overflow: hidden;
}
.office-img img {
  width: 100%;
  height: 100%;
}
.modal-content {
  width: 370px;
  height: 425px;
  padding-left: 27px;
  padding-right: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.button-close {
  position: absolute;
  background-color: transparent;
  border: none;
  top: 29px;
  right: 34px;
}
h1 {
  margin-top: 17px;
}
.upload-img {
  margin-top: 15px;
  height: 150px;
  width: 315px;
  background-color: #f9f9f9;
  border: 1px dashed #bdbdbd;
}
.upload-img p {
  margin-top: 26px;
}
.input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  width: 315px;
}
input {
  width: 100%;
}
label {
  font-weight: 700;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
.buttons button {
  width: 112px;
  height: 45px;
}
.secondary-button {
  background-color: transparent;
  border: none;
}
@media screen and (max-width: 1023px) {
  .modal-body {
    height: 405px;
    width: 280px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .office-img,
  .upload-img {
    width: 250px;
    height: 150px;
  }
  .input {
    width: 250px;
  }
  .button-close {
    top: 25px;
    right: 17px;
  }
  h1 {
    margin-top: 10px;
  }
}
</style>
