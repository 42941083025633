import { createStore } from "vuex";
import branches from "./modules/branches";
import businesses from "./modules/businesses";
import categories from "./modules/categories/index";
import cropperImg from "./modules/cropperImg";

import currencies from "./modules/currencies";
import deleteVerification from "./modules/deleteVerification";
import loading from "./modules/loading";
import locationStore from "./modules/location/index";
import login from "./modules/login";
import modals from "./modules/tutorialModals";
// import orders from "./modules/orders";
import payments from "./modules/payments";
import popup from "./modules/popup";
import products from "./modules/products";
// import register from "./modules/register";

import branchStore from "./modules/branch";
import branchInfoStore from "@/modules/branch/store/branchInfo";
import businessStore from "./modules/business";
import currentOrderStore from "@/modules/branch/store/currentOrder";
import currentProductStore from "../modules/branch/store/currentProduct";
import ordersStore from "@/modules/branch/store/orders";
import registerStore from "./modules/register/index.js";
import createPersistedState from "vuex-persistedstate";
import tables from "./modules/tables";
import users from "./modules/users";
import staticsStore from "../modules/branch/store/statics";
import notificationsStore from "../modules/branch/store/notifications";
import socketStore from "../modules/branch/store/socket";

export default createStore({
  state: {
    auth: "",
    authFd: "",
    accessToken: "",
    refreshToken: "",
    businesAccessToken: "",
    businessAuth: "",
    businessAuthFd: "",
    businessRefreshToken: "",
    business: "",
    domainName: "",
    currentBranch: "",
    currentBusiness: "",
    action: "",
    data: "",
    error: 401,
    ok: 200,
  },
  mutations: {
    setAction(state, payload) {
      state.action = payload.action;
      state.data = payload.data;
    },
    setCurrentBranch(state, payload) {
      state.currentBranch = payload;
    },
    setAuthTokens(state, payload) {
      state.refreshToken = payload.data.refresh;
      state.accessToken = payload.data.access;
      state.auth = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${payload.data.access}`,
        },
      };
      state.authFd = {
        headers: {
          "content-type":
            "multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL",
          Authorization: `Bearer ${payload.data.access}`,
        },
      };
      localStorage.setItem("authTokens", JSON.stringify(payload.data));
    },
    setBusinessTokens(state, payload) {
      state.businessRefreshToken = payload.refresh;
      state.businesAccessToken = payload.access;
      state.businessAuth = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${payload.access}`,
        },
      };
      state.businessAuthFd = {
        headers: {
          "content-type":
            "multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL",
          Authorization: `Bearer ${payload.access}`,
        },
      };
      localStorage.setItem("businessTokens", JSON.stringify(payload));
    },
    setDomainName(state, payload) {
      const name = payload.name;
      state.domainName = payload.domain_name;
      state.currentBusiness = name.charAt().toUpperCase() + name.slice(1);
      state.business = payload;
      state.business.name = state.currentBusiness;
    },
  },
  actions: {
    clearPersistedState() {
      window.localStorage.clear("vuex");
    },
    appRequest({ commit, dispatch, state }, payload) {
      commit("setAction", payload);
      return new Promise((resolve) => {
        dispatch(payload.action, payload.data).then((response) => {
          if (response.status === state.error) {
            dispatch("refreshToken").then((response2) => {
              if (response2.status === state.ok) {
                dispatch(payload.action, payload.data).then((response3) => {
                  resolve(response3);
                });
              }
            });
          } else {
            resolve(response);
          }
        });
      });
    },
    businessRequest({ commit, dispatch, state }, payload) {
      commit("setAction", payload);
      // if (payload.module) {
      // return new Promise((resolve) => {
      //   dispatch(`${payload.module}/${payload.action}`, payload.data).then(
      //     (response) => {
      //       if (response.status === state.error) {
      //         dispatch("refreshBusinessToken").then((response2) => {
      //           if (response2.status === state.ok) {
      //             dispatch(
      //               `${payload.module}/${payload.action}`,
      //               payload.data
      //             ).then((response3) => {
      //               resolve(response3);
      //             });
      //           }
      //         });
      //       } else {
      //         resolve(response);
      //       }
      //     }
      //   );
      // });
      // } else {
      return new Promise((resolve) => {
        dispatch(payload.action, payload.data).then((response) => {
          if (response.status === state.error) {
            dispatch("refreshBusinessToken").then((response2) => {
              if (response2.status === state.ok) {
                dispatch(payload.action, payload.data).then((response3) => {
                  resolve(response3);
                });
              }
            });
          } else {
            resolve(response);
          }
        });
      });
      // }
    },
    // testAction() {
    //   console.log("Funciona");
    // },
  },
  getters: {
    getCurrentBusiness(state) {
      return state.currentBusiness;
    },
    getDomainName(state) {
      return state.domainName;
    },
    getCurrentBranch(state) {
      return state.currentBranch;
    },
    getBusiness(state) {
      return state.business;
    },
  },
  modules: {
    login,
    // register,
    businesses,
    branches,
    currencies,
    deleteVerification,
    modals,
    users,
    categories,
    products,
    popup,
    cropperImg,
    loading,
    // location,
    payments,
    // orders,
    tables,

    branch: branchStore,
    branchInfo: branchInfoStore,
    currentOrder: currentOrderStore,
    currentProduct: currentProductStore,
    orders: ordersStore,
    location: locationStore,
    register: registerStore,
    business: businessStore,
    statics: staticsStore,
    notifications: notificationsStore,
    socket: socketStore,
  },
  plugins: [createPersistedState({ storage: window.localStorage })],
});
