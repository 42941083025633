import api from "@/api";
import axios from "axios";

const currencies = {
  state: {
    currencies: "",
  },
  mutations: {
    setCurrencies(state, payload) {
      state.currencies = payload.data;
    },
  },
  actions: {
    fetchCurrencies({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/r/${rootState.domainName}/v1/currencies`,
            rootState.businessAuth
          )
          .then(
            (response) => {
              commit("setCurrencies", response);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
  },
  getters: {
    getCurrencies(state) {
      return state.currencies;
    },
  },
};

export default currencies;
