import api from "@/api";
import axios from "axios";

export const register = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post(`${api}/r/app/auth/register`, payload).then(
      (response) => {
        resolve(response);
        commit("login", response, { root: true });
      },
      (error) => {
        resolve(error.response);
        reject(error);
      }
    );
  });
};
export const checkEmail = async (context, payload) => {
  // return new Promise((resolve, reject) => {
  //   axios.post(`${api}/r/app/auth/email`, { email: payload }).then(
  //     (response) => {
  //       resolve(response);
  //     },
  //     (error) => {
  //       resolve(error.response);
  //       reject(error);
  //     }
  //   );
  // });
  const response = await axios.post(`${api}/r/app/auth/email`, {
    email: payload,
  });
  return response;
};
export const checkPhone = (context, payload) => {
  return new Promise((resolve, reject) => {
    axios.post(`${api}/r/app/auth/sms_sender`, { phone_number: payload }).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        resolve(error.response);
        reject(error);
      }
    );
  });
};
export const verifyCode = (context, payload) => {
  return new Promise((resolve, reject) => {
    axios.post(`${api}/r/app/auth/sms_validator`, payload).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        resolve(error.response);
        reject(error);
      }
    );
  });
};
