const uploadImg = {
  state: {
    currentImg: null,
  },
  mutations: {
    setCurrentImg(state, payload) {
      state.currentImg = payload;
    },
  },
  actions: {},
  getters: {
    getCurrentImg(state) {
      return state.currentImg;
    },
  },
};
export default uploadImg;
