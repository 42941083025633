import axios from "axios";
import api from "@/api";
import jwt_decode from "jwt-decode";

const login = {
  state: {
    errorLoginResponse: "",
    business_url: "",
    isLogued: false,
    isAdmin: null,
    decoded: "",
    auth: "",
  },
  mutations: {
    login(state, payload) {
      state.isLogued = true;
      state.decoded = jwt_decode(payload.data.access);
      state.isAdmin = state.decoded.rol === 2;
    },
    errorLogin(state, payload) {
      state.errorResponse = payload;
    },
    refreshLogin(state) {
      state.errorLoginResponse = "";
    },
    logout(state) {
      state.isLogued = false;
    },
  },
  actions: {
    login(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${api}/r/app/auth/login`, payload).then(
          (response) => {
            resolve(response);
            context.commit("login", response);
            context.commit("setAuthTokens", response);
          },
          (error) => {
            resolve(error.response);
            reject(error);
          }
        );
      });
    },
    loginEmployee({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${api}/r/${state.business_url}/auth/login`, payload).then(
          (response) => {
            resolve(response);
            commit("login", response);
          },
          (error) => {
            resolve(error.response);
            reject(error);
          }
        );
      });
    },
    checkBusiness(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${api}/login/business`, { business_name: payload }).then(
          (response) => {
            context.commit("setBusinessUrl", response.data.business_name);
            resolve(response);
          },
          (error) => {
            resolve(error.response);
            reject(error);
          }
        );
      });
    },
    checkToken({ state, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `http://${state.business_url}:8000/v1/additions`,
            {},
            rootState.auth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    refreshToken({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/r/app/auth/login/refresh`, {
            refresh: rootState.refreshToken,
          })
          .then(
            (response) => {
              commit("login", response);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
    logout({ rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${api}/auth/logout`,
            {
              refresh_token: rootState.refreshToken,
              business_refresh_token: rootState.businessRefreshToken,
              domain: rootState.domainName,
            },
            rootState.auth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
  },
  getters: {
    getLoginError(state) {
      return state.errorLoginResponse;
    },
    getLogued(state) {
      return state.isLogued;
    },
    getUserId(state) {
      return state.decoded.user_id;
    },
    getUsername(state) {
      return state.decoded.username;
    },
    getAdmin(state) {
      return state.isAdmin;
    },
  },
};

export default login;
