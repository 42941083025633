export const setNewNotification = (state, payload) => {
  state.notifications.push(payload);
};
export const setCleanNotifications = (state) => {
  state.notifications = [];
};
export const setCheckedState = (state) => {
  for (let index = 0; index < state.notifications.length; index++) {
    state.notifications[index].checked = true;
  }
};
export const deleteNotification = (state, payload) => {
  state.notifications = state.notifications.filter(
    (notification) => notification != payload
  );
};
export const setOrderData = (state, payload) => {
  state.orderData = payload;
};
