<template>
  <div class="branches">
    <h2>{{ $t("pages.sedes.locations") }}</h2>
    <p>
      {{ $t("pages.sedes.branchesInfo") }}
    </p>
    <div class="branches-list">
      <div v-for="branch in branches" :key="branch.id">
        <BranchItem
          :shop="branch"
          :description="false"
          :shopDetails="toBranch"
        />
      </div>
      <button
        v-if="branches.length < 1"
        class="add-shop-button"
        @click="toggleModalCreateBranch"
      >
        <img src="@/assets/icons/icon-shop.svg" alt="add-shop-icon" />
        <p>Crear sucursal</p>
      </button>
    </div>
    <ModalCreateBranch
      :reloadList="fetchBranches"
      v-model="showModalCreateBranch"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";
export default {
  name: "branches-view",
  components: {
    BranchItem: defineAsyncComponent(() =>
      import("../components/BranchItem.vue")
    ),
    ModalCreateBranch: defineAsyncComponent(() =>
      import("../modals/ModalCreateBranch.vue")
    ),
  },
  data() {
    return {
      showModalCreateBranch: false,
    };
  },
  computed: {
    ...mapGetters({
      branches: "getBranches",
      isAdmin: "getAdmin",
      isLogued: "getLogued",
      business: "getDomainName",
      currentBranch: "getCurrentBranch",
    }),
  },
  methods: {
    toggleModalCreateBranch() {
      this.showModalCreateBranch = !this.showModalCreateBranch;
    },
    fetchBranches() {
      this.$store.dispatch("businessRequest", {
        action: "fetchBranches",
      });
    },
    fetchUserBranches() {
      this.$store.dispatch("businessRequest", {
        action: "fetchUserBranches",
      });
    },
    toBranch(payload) {
      this.$store.commit("setCurrentBranch", payload);
      this.$router.push({
        name: "branch-info",
        params: { business: this.business, branchId: payload.id },
      });
    },
  },
  mounted() {
    if (this.isAdmin) {
      this.fetchBranches();
    } else {
      this.fetchUserBranches();
    }
  },
  created() {
    if (!this.isLogued) {
      this.$router.push("/");
    }
  },
};
</script>
<style scoped>
.branches {
  text-align: start;
}
h2 {
  margin-bottom: 8px;
}
.branches-list {
  display: flex;
  flex-wrap: wrap;
}
.add-shop-button {
  margin-top: 26px;
  height: 130px;
  width: 324px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.2);
  border-radius: 10px;
  padding: 25px 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-shop-button img {
  margin-right: 20px;
}
@media screen and (max-width: 1439px) {
  .add-shop-button {
    width: 304px;
    margin-top: 18px;
  }
}
@media screen and (max-width: 1359px) {
  .add-shop-button {
    width: 308px;
  }
}
@media screen and (max-width: 767px) {
  .add-shop-button {
    width: 100%;
  }
}
</style>
