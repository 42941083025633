import store from "@/store";

export default {
  name: "branch",
  component: () =>
    import(
      /* webpackChunkName: "BranchLayout" */ "../layouts/BranchLayout.vue"
    ),

  beforeEnter() {
    if (!store.state.login.isLogued) {
      return { name: "Login" };
    }
    return null;
  },
  children: [
    {
      path: "",
      name: "home",
      component: () =>
        import(
          /* webpackChunkName: "BranchResume" */ "../../dashboardGlobal/modules/home/views/Home.vue"
        ),
    },
    {
      path: "settings",
      name: "branch-info",
      component: () =>
        import(/* webpackChunkName: "BranchInfo" */ "../views/Branch.vue"),
    },
    {
      path: "checkbox",
      name: "branch-checkbox",
      component: () =>
        import(
          /* webpackChunkName: "BranchCheckbox" */ "../views/Checkbox.vue"
        ),
    },
    {
      path: "orders",
      name: "branch-orders",
      component: () =>
        import(/* webpackChunkName: "BranchOrders" */ "../views/Orders.vue"),
    },
    {
      path: "comandas",
      name: "comandas",
      component: () => import("../modules/orders/views/ComandasView.vue"),
    },
    {
      path: "bar",
      name: "bar",
      component: () => import("../modules/orders/views/Bar.vue"),
      props: {
        orderStatus: 2,
        productType: 2,
      },
    },
    {
      path: "toDeliver",
      name: "toDeliver",
      component: () => import("../modules/orders/views/ToDeliver.vue"),
    },
    {
      path: "toDeliverBar",
      name: "toDeliverBar",
      component: () => import("../modules/orders/views/ToDeliverBar.vue"),
    },
    {
      path: "inventory",
      name: "branch-inventory",
      component: () =>
        import(
          /* webpackChunkName: "BranchInventory" */ "../views/Inventory.vue"
        ),
    },
    {
      path: "qr-tables",
      name: "branch-tables",
      component: () =>
        import(/* webpackChunkName: "BranchTables" */ "../views/Tables.vue"),
    },
    {
      path: "new-order",
      name: "new-order",
      component: () => import("../modules/menu/views/MenuView.vue"),
    },
    {
      path: "",
      redirect: { name: "branch-info" },
    },
  ],
};
